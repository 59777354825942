@mixin mobileSpace () {
    width: calc(100% - 32px);
}

@mixin animate($animation) {
    animation: $animation .75s 0s ease ;
    -webkit-animation:  $animation .75s 0s ease ;
}

@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    };
    @keyframes #{$name} {
        @content;
    };
};

a.NextEpButton:link, a.NextEpButton:visited, a.NextEpButton:hover {
    color: black;
    text-decoration: none;
}
a.PaymentButton:link, a.PaymentButton:visited, a.PaymentButton:hover {
    color: black;
    text-decoration: none;
    position: relative;
}

.EpisodeHomeArea{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    height: 100vh;
    overflow-y: scroll;

    img {
        -webkit-touch-callout: none;
        -webkit-user-select: none;  /* Chrome all / Safari all */
        -moz-user-select: none;     /* Firefox all */
        -ms-user-select: none;      /* IE 10+ */
        -o-user-select: none;
        user-select: none;
    };
    
    .FloatingScrollUp{
        position: fixed;
        right: calc(50% - 410px);
        bottom: 100px;
        cursor: pointer;
        
        @media (max-width: 700px) {
            display: none;
        }
        @media (max-width: 820px){
            right: calc(50% - 345px);
        }
    }

    .Header{
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc( 100% - 1.2rem );
        border-bottom: 1px solid rgb(241,241,241);
        border-top: 1px solid rgb(241,241,241);
        position: fixed;
        top: 0;
        overflow: hidden;
        box-sizing: border-box;
        background-color: rgb(255,255,255);
        z-index: 2;
        
        transition: 400ms;

        @media (max-width: 700px) {
            max-height: 45px;
            width: 100%;
        };

        .HeaderInner{
            max-width: 820px;
            width: 100%;

            @media (min-width: 701px){
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                justify-content: center;
                align-items: center;
            };
            @media (max-width: 700px){
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 16px;
            }

            .LogoAndPreviousEpisode{
                display: flex;
                align-items: center;

                @media(max-width: 700px) {
                    display: none;
                }
            };

            .CurrentEpisode{
                display: flex;
                justify-content: center;

                @media(max-width: 700px) {
                    display: none;
                }
            };

            .MobileEpisodeMenu{
                display: flex;
                align-items: center;
                @media(min-width: 701px){
                    display: none;
                };

                .MoveToTitleHomeButton{
                    margin-right: 26px;
                    cursor: pointer;
                };

                .ActiveArrow{
                    cursor: pointer;
                };

                .PassiveArrow{
                    pointer-events: none;
                };
            }

            .ButtonBox{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                height: 24px;

                .ShareButton{
                    font-size: 1.5rem;
                    cursor: pointer;
                    margin-right: 20px;
                }
            };
        }
    };

    .NextEpButton {
        
        --height: 80px;
        --padding: 16px;

        position: relative;
        box-sizing: border-box;
        background-color: rgba(233,233,233, 1);
        border-radius: 16px;
        width: calc(100% - var(--padding) * 2);
        max-width: 690px;
        margin: 16px auto 0 auto;
        height: calc(var(--height));
        display: flex;
        flex-direction: row;
        justify-content: stretch;
        align-items: center;
        gap: 16px;
        cursor: pointer;
        transition: 100ms;
        user-select: none;
        margin-top: 16px;
        padding-right: 16px;

        &:active {
            background-color: rgba(233,233,233, 0.7);
        }

        .Thumbnail {
            position: relative;
            height: var(--height);
            flex-grow: 0;
            border-top-left-radius: 16px;
            border-bottom-left-radius: 16px;
            overflow: hidden;
            
            img {
                min-width: 100%;
                min-height: 100%;

                max-width: 70px;
                
                object-fit: cover;
                object-position: center;
            }
        }

        .TextBox {
            display: flex;
            flex-direction: column;
            gap: 8px;
            flex-grow: 0;

            .Title {
                color: rgb(25,24,27);
                font-size: 1.0rem;
                font-weight: 600;
                line-height: 1.25rem;
                letter-spacing: 0.01rem;
            }
            .Subtitle {
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.25rem;
            }
        }

        .Padding {
            flex-grow: 1;
        }

        .Next {
            color: rgb(121,120,130)
        }

    }
    
    a.PaymentButton {
        margin-top: 30px;
        font-size: 0.875rem;
        span {
            color: rgb(137, 85, 246);
            padding-bottom: 0.125rem;
            border-bottom: 1px rgb(137, 85, 246) solid;
        }
    }

    .Nudge {
        position: absolute;
        left: 10px; top: 40px;
        height: 20px;
        line-height: 20px;
        width: 140px;
        padding: 4px 12px;
        border-radius: 40px;
        background-color: white;
        box-shadow: 0px 0px 0px 1px rgb(25,24,27);
        animation: float 2s ease-in-out infinite;
        text-align: center;

        .Arrow {
            position: absolute;
            top: -20px;
            left: 70px;

            border: 10px rgba(0,0,0,0) solid;
            border-bottom: 10px rgb(25,24,27) solid;
        }
        .Arrow2 {
            position: absolute;
            top: -18px;
            left: 70px;

            border: 10px rgba(0,0,0,0) solid;
            border-bottom: 10px white solid;
        }
        
    
        @keyframes float {
            0% {
                transform: translatey(0px);
            }
            50% {
                transform: translatey(-8px);
            }
            100% {
                transform: translatey(0px);
            }
        }
    }

    .CommentHeader{
        height: 45px;
        width: 100%;
        border-bottom: 1px solid rgb(241,241,241);
        background-color: rgb(255,255,255);
        margin-bottom: 25px;
        display: flex;
        justify-content: center;
        box-sizing: border-box;

        @media(min-width: 701px) {
            display: none;
        }

        .CommentHeaderInner{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            align-items: center;
            width: calc(100% - 32px);
            height: 100%;
            
            .SortBar{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                font-size: 0.9rem;
                color: rgb(117,117,117);

                .DividingSortStandardLine{
                    margin: 0 10px;
                };

                .SortStandard{
                    cursor: pointer;
                };

                .Focused{
                    font-weight: bold;
                    color: rgb(33,33,33);
                };
            };
        }

        .CommentHeaderInner:nth-child(1){
            justify-content: flex-start;
        }
    }

    .HiddenHeader{
        @media(max-width: 700px) {
            display: none;
        }
    }

    .EpisodeHomeBody{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .HomeBodyInner{
            width: 100%;
            max-width: 820px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .SlideContainer{
                width: 100%;
                max-width: 690px;

                img.Contents {
                    vertical-align: middle;
                    width: 100%; height: 100%; object-fit: contain;
                }
    
                .StampBox{
                    display: flex;
                    justify-content: center;
                    margin-top: 80px;
    
                    .Stamp{
                        @include keyframes(fade-in) {
                            0% {
                            opacity: 0;
                            };
                            10% {
                            opacity: 0.5;
                            transform-origin: 50% 50%;
                            transform: rotate(45deg) scale(5);
                            transition: all 0.5s cubic-bezier(0.6, 0.04, 0.98, 0.335);
                            };
                            100% {
                            opacity: 1;
                            transform: rotate(0deg) scale(1);
                            };
                        }
    
                        @include animate(fade-in);
                        border-radius: 5px;
                        position: relative;
                        -webkit-animation-fill-mode:forwards;
                        animation-fill-mode:forwards;
                    };
                };
    
                .FavoriteCount{
                    text-align: center;
                    color: rgb(148,148,148);
                    font-size: 1.3rem;
                    font-weight: bold;
                };
            };

            .hiddenContainer{
                @media (max-width: 700px){
                    display: none;
                }
            }
        }


        .AuthorBox{
            margin-top: 64px;
            display: flex;
            align-items: center;
            width: 100%;
            padding: 0;

            @media (max-width: 700px){
                @include mobileSpace();
            }

            .ImageBox{
                width: 65px;
                height: 65px;
                box-sizing: border-box;
            };

            .NameBox{
                display: flex;
                align-items: center;
                cursor: pointer;

                .Name{
                    font-size: 1.1rem;
                    margin: 0 16px;
                    font-weight: bold;
                };
            };
        };

        .HiddenBox{
            @media (max-width: 700px){
                display: none;
            }
        }

        .DividingBoxLine{
            margin: 20px 0 30px 0;
            border-bottom: 1px solid rgb(241,241,241);
            width: 100%;
        }

        .CommentBoxLabel{
            font-size: 1rem;
            font-weight: bold;
            color: rgb(51,51,51);
            margin-bottom: 16px;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

        }

        .DisplayModeBox{
            width: 100%;
            position: relative;
            @media (max-width: 700px){
                @include mobileSpace();
            }

            .TextField{
                display: flex;
                width: 100%;
                padding: 12px 16px;
                height: 100%;
                border: 1px solid rgb(196,196,196);
                border-radius: 5px;
                box-sizing: border-box;

                .PlaceHolder{
                    width: 85%;
                    color: rgb(196,196,196);
                    cursor: text;
                }
            }

            .EnrollButton{
                width: 15%;
                position: absolute;
                right: 0;
                top: 0;
                border-radius: 5px;
                position: absolute;
                height: 100%;
            }
        }

        .WriteModeBox{
            display: flex;
            flex-direction: column;
            width: 100%;

            @media (max-width: 700px) {
                width: calc(100% - 32px);
            };

            .TextField{
                width: 100%;
                border-radius: 5px;
                border: 1px solid rgb(196,196,196);
                padding: 10px 16px;
                box-sizing: border-box;
                position: relative;

                .AuthorName{
                    margin-bottom: 6px;
                    font-weight: bold;
                    color: rgb(51,51,51);
                };
            };

            .ButtonField{
                margin-top: 6px;
                display: flex;
                justify-content: space-between;
                width: 100%;
                position: relative;

                .RandomCommentButton{
                    display: inline-block;
                    margin-bottom: 6px;
                    padding: 6px 8px;
                    cursor: pointer;
                    color: rgb(0,176,240);
                };

                .SpreadCommentButton{
                    display: flex;
                    color: rgb(0,176,240);
                    word-break: keep-all;
                    cursor: 'pointer';
                    
                    @media(max-width: 700px) {
                        width: calc(100% - 16px);
                    };
                };
            };

            .TextLength{
                color: rgb(196,196,196);

                .RandomCommentButton{
                    border: 1px solid rgb(196, 196, 196);
                    color: rgb(0,176,240);
                    margin-right: 6px;
                    &:last-child{
                        margin-right: 0;
                    };
                    @media(max-width: 700px) {
                        width: calc(100% - 16px);
                    };
                };
            };
            
            .SuggestedComments{
                margin-top: 10;

                .Comment{
                    display: inline-block;
                    border: 1px solid rgb(196,196,196);
                    padding: 6px 10px;
                    margin: 0px 8px 7px 0px;
                    cursor: pointer;
                    transition: 0.6s;

                    &:hover{
                        background-color: rgb(217, 245, 255);
                    }
                };
            };

            .HoveredSuggestion {
                position: fixed;
                background-color: white;
                box-shadow: 0px 5px 10px rgba(45,45,45,0.5);
                padding: 12px 16px;
                border-radius: 4px;
                
                
                > * {
                    cursor: pointer;
                    border-radius: 5px;
                    padding: 4px 12px;

                    &.Focused {
                        background-color: rgba(60, 70, 81, 0.12);
                    }
                }
            }
        };

        .CommentContainer{
            margin: 25px 0 100px 0;
            width: 100%;

            @media (max-width: 700px){
                @include mobileSpace();
                margin: 20px 0 30px 0;
            };

            .SortBar{
                display: flex;
                justify-content: flex-start;
                font-size: 0.9rem;
                color: rgb(117,117,117);

                .SortStandard{
                    cursor: pointer;
                };

                .Focused{
                    font-weight: bold;
                    color: rgb(33,33,33);
                };
            };


            .DividingSortStandardLine{
                margin: 0 26px;
            };

            .AllCommentOpenButton{
                margin-top: 12px;
                font-size: 0.9rem;
                text-align: center;
                cursor: pointer;
            };
        };

        .ReportButton{
            margin-top: 36;
            font-size: 0.9rem;
            color: rgb(117,117,117);
            cursor: pointer;

            @media (max-width: 700px){
                margin-right: 16px;
            }
        };

        .EmptyCommentContainer{
            margin: 10px 0 20px 0;
            white-space: pre-line;
            text-align: center;
        }

        .MuiInput-underline:before{
            border-color: white;
        }
        .MuiInput-underline:after{
            border-color: white;
        }
        .MuiInput-underline:hover:not(.Mui-disabled):before{
            border-color: white;
        }

    };

    .AdArea {

        .AdContent {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;
            flex-wrap: wrap;
            gap: 1rem;

            word-break: keep-all;

            .Detail {
                width: 340px;
                display: flex;
                flex-direction: column;
                gap: 1rem;

                .Title {
                    font-size: 1.5rem;
                    font-weight: bold;
                }

                .Subtitle {
                    font-size: 1.0rem;
                    color: rgba(122,122,122,1);
                }
            }
        }
    }

    .EpisodeFooter  {
        display: flex;
        justify-content: center;
        width: calc( 100% - 1.2rem );
        border-bottom: 1px solid rgb(241,241,241);
        border-top: 1px solid rgb(241,241,241);
        position: fixed;
        bottom: 0;
        background-color: rgb(255,255,255);
        overflow: hidden;
        box-sizing: border-box;

        transition: 400ms;

        @media (max-width: 700px) {
            width: 100%;
            max-height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .FooterInner{
            max-width: 360px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 100%;

            .PreviousButton{
                display: flex;
            }
    
            .NextButton{
                display: flex;
                align-items: center;
                cursor: pointer;
            }

            .ActivePrevious, .ActiveNext{
                cursor: pointer;
            }

            .PassivePrevious, .PassiveNext{
                pointer-events: none;
                color: rgb(196, 196, 196);
            }
        };
    };

    .HiddenFooter{
        @media(max-width: 700px){
            display: none;
        }
    }
};





