@import './Variables.scss';

@keyframes fadeOut {
    0% {
      opacity: 1;
    }
    60% {
        opacity: 1;
    }
    100% {
      opacity: 0;
    }
}

.CarouselCardArea{
    width: 100%;
    height: 100%;
    display: flex;
    transition: $transitionTime;
    border: 1px solid rgb(225, 226, 228);
    box-sizing: border-box;
    border-radius: 5px;

    .Carousel{
        position: relative;
        width: $cell-width; height: $cell-width;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;

        .swiper {
            position: relative;
            width: 100%;
            height: 100%;

            .swiper-slide{
                background: rgb(255,255,255);
                display: flex; align-items: center;
            };
            .WebtoonInfoHover{
                opacity: 0;
                transition: ease-in-out $transitionTime;
                z-index: 5;
                width: 100%; 
                box-sizing: border-box;
                position: absolute; top: 0;
                background: linear-gradient(180deg, rgba(20, 20, 21, 0.99) 0%, rgba(20,20,21,0.9) 40%, rgba(20,20,21,0.6) 70%,  rgba(20, 20, 21, 0) 100%);
                padding: 20px;
                border-radius: 5px 5px 0 0;

                .AuthorName{
                    color: rgba(171, 175, 180, 1);
                    font-size: 0.75rem;
                }
                .TitleRow{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: rgba(255, 255, 255, 1);
                    margin: 8px 0;
                    .Title{
                        cursor: pointer;
                    };
                    .TitleFavorite{
                        position: relative;
                        cursor: pointer;
                    }
                }
                .WebtoonInfo{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: rgba(221, 225, 241, 1);

                    .EpisodeInfo{
                        font-size: 0.875rem;
                    };
                    .CountInfo{
                        display: flex;
                        align-items: center;
                        font-size: 0.75rem;
                        .FavoriteCountIcon{
                            width: 18px; height: 18px;
                            margin-right: 4px;
                        };
                        .ViewCountIcon{
                            width: 18px; height: 18px;
                            margin: 0 4px 0 16px;
                        };
                    };
                };
            };

        };
        .swiper:hover{            
            .WebtoonInfoHover{
                display: block; 
                opacity: 1;
                z-index: 5;
            };
        };

        .ArrowBox{
            opacity: 0; transition: $transitionTime;
            position: absolute;
            top: 50%; z-index: 1;
            padding: 30px 8px;
            cursor: pointer;
            .Arrow{
                width: 30px; height: 30px;
                background-color: rgba(244, 244, 245, 0.7);
                border-radius: 50%;
            };
        };
        
        &:hover{
            .ArrowBox{
                opacity: 1;
            };
        };
        .ArrowBox.Hidden{
            opacity: 0;
        };
        .ArrowBox.Prev{
            left: 0px;
            transform: translate(-4px, -50%);
            filter: drop-shadow(-1px -1px 20px rgba(51, 51, 51, 0.18));
        };
        .ArrowBox.Next{
            right: 0px;
            transform: translate(4px, -50%);
            filter: drop-shadow(1px 1px 20px rgba(51, 51, 51, 0.18));
        };
                
        .Pagination{
            position: absolute;
            left: 50%; bottom: -20px;
            opacity: 0;
            transition: $transitionTime;

            .swiper-pagination-bullet-active-main{
                background-color: rgba(0,128,233,1);
            };
        };
        .HoverBackground{
            display: none;
            height: 30px;
            transform: translate(-50%, 20px);
            background-color: transparent;
        };
        &:hover{
            .HoverBackground{
                display: block;
            };
            .Pagination{
                opacity: 1;
            };
        }
    };

    .CarouselSet{
        position: relative;
        width: $cell-width + $col-gap; height: $cell-width;
        padding: 24px 24px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        background-color: rgb(255,255,255);
        border-radius: 0 5px 5px 0;

        .TitleBox{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 8px 0;
            font-size: 1.125rem;
            position: relative;
            font-weight: 700;
            line-height: 1.5rem;
            letter-spacing: 0.02025rem;
            cursor: pointer;

            .FavoriteIconBox{
                position: relative;
            };
        };
        .WebtoonInfoBox{
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: rgba(149, 154, 159, 1);
            font-weight: 500;
            line-height: 1rem;
            letter-spacing: 0.014625rem;
            
            .EpisodeInfo{
                font-size: 0.875rem;
            };
            .CountInfo{
                display: flex;
                align-items: center;
                font-size: 0.75rem;
                .FavoriteCountIcon{
                    width: 1rem; height: 1rem;
                    margin-right: 4px;
                };
                .ViewCountIcon{
                    width: 1rem; height: 1rem;
                    margin: 0 4px 0 16px;
                };
            };
        };
        .DescriptionBox{
            margin-top: 18px;
            color: rgba(110, 109, 122, 1);
            font-size: 0.875rem;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-weight: 500px;
            line-height: 1.125rem;
            min-height: 2.25rem;
            letter-spacing: 0.0135rem;

            margin-top: 16px;
        };
        .CarouselSetDevideLine{
            width: 100%;
            border-top: 1px solid rgba(217, 217, 217, 1);
            margin: 15px 0;
        };
        .AuthorBox{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 0.75rem;
            margin-bottom: 12px;

            .ProfileInfo{
                display: flex;
                align-items: center;

                .ProfileImage{
                    display: flex;
                    position: relative;
                };
                .TextInfo{
                    height: 36px;
                    display: grid;
                    grid-template-rows: 1fr 1fr;
                    align-items: center;
                    font-weight: 700;
                    line-height: 1rem;
                    letter-spacing: 0.014625rem;
                    
                    .NameText{
                        font-weight: bold;
                        cursor: pointer;
                    };
                    .SubscriptionCount{
                        font-weight: 500;
                        color: rgba(149, 154, 159, 1);
                    };
                };
            };
            
            .FollowBox{
                position: relative;
                font-weight: 600;
                line-height: 1rem;
                letter-spacing: 0.014625rem;
                
                .Following{
                    font-weight: 500;
                    color: rgba(149, 154, 159, 1);
                    cursor: pointer;
                };
                .Follow{
                    color: rgb(0,176,240);
                    cursor: pointer;
                };
            };
        };
        .OtherTitleLabel{
            margin-bottom: 8px;
            font-size: 0.75rem;
            color: rgba(53,53,53,1);
            font-weight: 500;
            line-height: 1rem;
            letter-spacing: 0.014625rem;
        };
        .OtherTitleSlider{
            display: flex;
            position: relative;
            
            .OtherTitleSetArrow{
                position: absolute;
                top: 50%; z-index: 1;
                padding: 30px 8px;
                cursor: pointer;

                .Arrow{
                    width: 30px; height: 30px;
                    border: 2px solid rgba(244, 244, 245, 1);
                    border-radius: 50%;
                    box-sizing: border-box;
                    background-color: white;
                };
            };
            .OtherTitleSetArrow.Hidden{
                display: none;
            };
            .OtherTitleSetArrow.Prev{
                left: 0px;
                transform: translate(-50%, -50%);
                filter: drop-shadow(-1px -1px 20px rgba(51, 51, 51, 0.18));
            };
            .OtherTitleSetArrow.Next{
                right: 0px;
                transform: translate(50%, -50%);
                filter: drop-shadow(1px 1px 20px rgba(51, 51, 51, 0.18));
            };
        };
    };
};

.CarouselCardArea:hover{
    transform: translate(0, -16px);
    filter: drop-shadow(1px 1px 20px rgba(51, 51, 51, 0.18));
};

.CarouselCardArea.Autoplaying{
    transform: translate(0, -16px);
    filter: drop-shadow(1px 1px 20px rgba(51, 51, 51, 0.18));

    .Carousel{
        .ArrowBox, .Pagination{
            opacity: 1;
        };
        .ArrowBox.Hidden{
            opacity: 0;
        };
        .HoverBackground{
            display: block;
        };
    };
};