$MARGINUNIT: 16px;

.ApplyingAnalysisMainArea{
    max-width: 600px;
    margin: 0 auto;
    word-break: keep-all;

    .ApplyingAnalysisBody{
        padding-top: $MARGINUNIT * 3;

        .Section{
            padding: 0 $MARGINUNIT * 2;

            .SectionTitle{
                font-weight: bold;
                margin-bottom: $MARGINUNIT;
            };

            .SectionText{
                font-size: 0.9rem;
                margin-bottom: $MARGINUNIT;
                word-break:keep-all;
                line-height: 24px;
            };

            .CheckRow{
                display: flex;
                justify-content: stretch;
                align-items: flex-start;
                font-size: 0.8rem;
                margin-bottom: 3px;

                .CheckPoints{
                    padding: 0px;
                    margin-right: 3px;
                }

                .Plane{
                    pointer-events: none;
                }
            }
        };


    };
    
    .ButtonSection{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: $MARGINUNIT * 1.5;
        margin-bottom: $MARGINUNIT;

        .SectionButton{
            border: 1px solid rgb(0,176,240);
            display: flex;
            justify-content: stretch;
            align-items: center;
            margin-bottom: $MARGINUNIT * 1.5;
            width: 200px;
            height: 30px;
            color: rgb(0,176,240);
        };
    };

    .ApplyingInfo {
        width: 100%;
        max-width: 600px;
        padding-bottom: $MARGINUNIT * 2;
        font-size: 14px;
        
        tr {
            td:first-child {
                width: 20px;
                text-align: center;
            }

            td:nth-child(2) {
                width: 60px;
                font-weight: bold;
                background-color: rgb(249,249,249);
                padding: $MARGINUNIT / 2;
            }
        }
    }

    .ApplyingAnalysisFooter{
        margin-top: $MARGINUNIT;
        margin-bottom: $MARGINUNIT;
        max-width: 600px;
        width: calc(100% - 64px);
        margin: 0 auto;
        padding-bottom: $MARGINUNIT;
        left: 0;
        right: 0;
    };
};