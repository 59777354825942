

.CircleTemplateContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-weight: 500;

    @media (min-width: 701px) {
        background-color: rgb(250,250,250);
        display: flex;
        justify-content: flex-start;
        width: 360px;
    };

    .TitleName{
        font-size: 1.5rem;
        margin: 35px 0 25px 0;
        max-width: 296px;
        width: 100%;
        font-weight: bold;
        text-align: center;
    };

    .TitleThumbnail{
        position: relative;
        min-height: 330px;
        width: 330px;
    };

    .StatisticsOfTitle{
        display: flex;
        justify-content: center;
        margin-top: 16px;
        margin-bottom: 36px;
        font-size: 0.75rem;

        .StatisticsCell{
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 0.8rem;
        };

        .DevidingLine{
            display: flex;
            justify-content: center;
            align-items: flex-end;
            padding: 0 20px;
        };
    };
    
    .TitleThumbnail{
        box-sizing: border-box;
        width: 330px;
        height: 330px;
        position: relative;
        border: 1px solid rgb(3,3,3);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;

        .TextBoxOnCircle{
            position: absolute;
            left: calc(50% - 15px);

            .RotatedTextBar{
                position: absolute;
                
                .AuthorLetter{
                    padding: 0 7px;
                }
                
                .AuthorWhiteLetter{
                    @media (max-width: 700px) { 
                        background-color: white !important;
                    }
                }

                .LetterOfKeyword{
                    padding: 0 8px;
                    transform: rotate(180deg);
                }

                .WhiteLetterOfKeyword{
                    @media (max-width: 700px) {
                        background-color: white !important;
                    };
                };
            };
        };
    };
};

.WhiteCircleTemplate{
    
    @media (max-width: 700px) {
        background-color: white !important;
    };
};