$SIGNATURE_COLOR: rgb(0,176,240);
@mixin customflex( $direction: row, $justify: stretch, $align: stretch ){
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: $align;
}


.ContestEndArea{
    @include customflex(column, center, space-evenly);
    max-width: 842px;
    margin: auto;
    vertical-align: top;
    
    @media (max-width: 960px) {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        align-items: center;
        padding: 0px;
    };

    @media (max-width: 701px) {
        width: 100%;
    };

    .AnnounceText{
        font-size: 1.25rem;
        font-weight: 700;
        text-align: center;
        line-height: 40px;
        color: rgb(51,51,51);
        background-color: rgb(251,251,251);
    };

    .InnerArea{
        vertical-align: top;
        margin: 0px;
        padding: 0 30px 100px 30px;
        box-sizing: border-box;
        background-color: rgb(251,251,251);
        width: 100%;
        max-width: 820px;

        .SearchSumbittedWebtoons{
            
            .AppliedEmailVerifying{
                
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                padding: 1px 1px 1px 10px;
                border: 1px solid rgba(0, 0, 0, 0.3);
                border-radius: 4px;
                position: relative;
                box-sizing: border-box;
                height: 40px;
                width: 100%;
                margin-top: 5px;
                background-color: rgb(255,255,255);

                .MuiInput-underline:before{
                    border-color: white;
                };
                .MuiInput-underline:after{
                    border-color: white;
                };
                .MuiInput-underline:hover:not(.Mui-disabled):before{
                    border-color: white;
                };

                &:hover{
                    border: 1px solid rgb(51,51,51);
                };

                &:focus-within {
                    padding: 0 0 0 9px;
                    border: 2px solid rgb(0,176,240);
                    box-sizing: border-box;
                };

                .VerifyingButton{
                    width: 15%;
                    border: 0px;
                    white-space: nowrap;
                    height: 40px;
                    box-sizing: border-box;
                };
                
                .disabled{
                    background-color: #9e9e9e;
                };
            };
            
            .VerifiedOtp{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                padding: 1px 1px 1px 10px;
                border: 1px solid rgba(0, 0, 0, 0.3);
                border-radius: 4px;
                position: relative;
                box-sizing: border-box;
                height: 40px;
                margin-top: 5px;
                background-color: rgb(255,255,255);

                .MuiInput-underline:before{
                    border-color: white;
                }
                .MuiInput-underline:after{
                    border-color: white;
                }
                .MuiInput-underline:hover:not(.Mui-disabled):before{
                    border-color: white;
                };

                &:hover{
                    border: 1px solid rgb(51,51,51);
                };

                &:focus-within {
                    padding: 0 0 0 9px;
                    border: 2px solid rgb(0,176,240);
                    box-sizing: border-box;
                };

                .VerifiedOtpButton{
                    width: 15%;
                    border: 0px;
                    white-space: nowrap;
                    height: 40px;
                    box-sizing: border-box;
                };
            };
        }

        .SubmitButton{
            font-family: inherit;
        };
    };
};
