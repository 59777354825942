$SIGNATURE_COLOR: rgb(0,176,240);


body[data-scroll]:not([data-scroll="0"]) {
    .MenuBar{
        background-color: white;
    }
}

.MenuBar{
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    position: sticky;
    top:0;
    width: 100%;
    box-sizing: border-box;
    transition: 200ms;
    z-index: 2;

    .SubmitHeader{
        padding: 10px 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
        background: rgb(255, 255, 255);
        box-sizing: border-box;

        &.ColorOn{
            background: radial-gradient(circle, rgba(217, 245, 255, 1), rgba(217, 245, 255, 1) 70%, rgb(217, 245, 255) 65%);
        }

        .HeadInner{
            width: 100%;
            box-sizing: border-box;

            .BarContentArea{
                margin: 0px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

        
                    img{
                        height: 50px;

                        @media(max-width: 820px){
                            height: 30px;
                        }
                    }
                .Informations, .InformationMenu{
                    display: flex;
                    flex-direction: row;
                    justify-content: stretch;
                    align-content: center;
                    white-space: nowrap;
                    
                }
                .Informations{

                    @media(max-width : 820px){
                        display: none;
                    }

                    .Information {
                        margin: 0 5px;
                    }

                    .Information:hover{
                        cursor: pointer;
                    }
                }

                .InformationMenu{

                    @media(min-width: 821px){
                        display: none;
                    }

                    .Information{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        padding: 10px;
                        box-sizing: border-box;
                    
                    }
                }
            }
        }

        .HashMover{
            text-decoration: none;
            color: rgb(39, 35, 35);
            font-size: 1rem;
        
        }
        
        .HashMover:hover{
            color: $SIGNATURE_COLOR;
            cursor: pointer;
        }
    }

    .NewsBarHeader{
        transition: 200ms;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: transparent;
        position: relative;
        box-sizing: border-box;
        height: 50px;

        .NewsBarInner{
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: stretch;
            align-items: center;
            font-weight: bold;
            cursor: pointer;

            @media (max-width: 820px){
                max-width: 80%
            }

            .NewsBarTitle{
                margin: 0 30px;
                color: rgb(255, 108, 108);
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;

                @media (max-width: 820px){
                    margin: 0 5px;
                }
            }
        }
    }
}

#simple-menu {
    
    .HashMover{
        text-decoration: none;
        color: rgb(39, 35, 35);
        font-size: 1rem;
    
    }
    
    .HashMover:hover{
        color: $SIGNATURE_COLOR;
        cursor: pointer;
    }
}

html[data-scroll="0"] .NewsBarHeader {
    background-color: rgba(0,0,0,0);
}

html:not([data-scroll="0"]) .NewsBarHeader {
    background-color: rgba(255,255,255,1);
}