$SIGNATURE_COLOR: rgb(0, 176, 240);
$SIGNATURE_BG_COLOR: rgba(0, 176, 240, 0.2);


.QuestionBox {
    width: 100%;
    
    .Title {
        margin-bottom: 10px;

        .RedAsterisk{
            color: red;
        }
    }

    .Subtitle {
        font-size: 0.8rem;
        text-align: left;
    }

    .OptionText{
        margin-top: 4.8%;
        
        @media( max-width: 720px ){
            margin-top: 0%;
            width: 10%;
            word-break: break-all;
            text-align: center;
        }
    }
}