.WithLabel{
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 16px;
    
    box-sizing: border-box;
    width: 100%;
    padding: 0 10px;

    .Label{
        width: 156px;

        color: var(--light-text-blue-tone-normal, rgba(25, 24, 27, 1));
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.16px;
        white-space: pre-line;
        word-break: keep-all;

        @media(max-width: 720px){
            font-size: 0.9375rem;
            font-weight: 400;
            letter-spacing: 0.15px;

            padding-left: 4px;
            padding-bottom: 4px;
        }
    }

    @media(max-width: 720px){
        display: flex;
        flex-direction: column;
        gap: 4px;

        padding-left: 0px;
        padding-right: 0px;
    }
}