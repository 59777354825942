
.SubmitEmailArea{
    font-weight: bold;
    font-family: "GmarketSans";

    .SeperateLine{
         border-bottom: 2px solid rgb(51,51,51);
         position: relative;
         
            .BackgroundOfTitle{
                background-color: rgb(255,255,255);
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);

                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 2rem;
                font-weight: bolder;
                color: rgb(51,51,51);
                padding: 0 10px;

                @media (max-width: 700px) {
                    font-size: 1.5rem;
                };
            };
    };

    .SubTitle{
        margin-top: 30px;
        margin-bottom: 50px;
        text-align: center;
        
        @media (max-width: 700px) {
            font-size: 0.9rem;
        };
    };
 
    .Label{
        width: 80%;
        margin: auto;
    }
     
    .EmailComponent{
        display: flex;
        align-items: center;
        border: 2px solid rgb(51,51,51);
        border-radius: 4px;
        position: relative;

        box-sizing: border-box;
        width: 80%;
        margin: auto;

        .MuiInput-underline:before{
            border-color: white;
        }
        .MuiInput-underline:after{
            border-color: white;
        }
        .MuiInput-underline:hover:not(.Mui-disabled):before{
            border-color: white;
        }
    };
}