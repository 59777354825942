
.UniversityMainArea{
    max-width: 820px;
    margin: auto;

    .ImageArea{
        position: relative;

        .ContestImages{
            vertical-align: middle;
            object-fit: cover;
            width: 100%;
        };

        .LinkButton{
            font-size: 1rem;
            font-family: inherit;
            width: 50%;
            @media (max-width: 700px) {
                font-size: 0.875rem;
                width: 60%;
                padding: 2px 10px;
            }
        };

        .HideOnMobile {
            @media (max-width: 700px) {
                display: none;
            }
        }
    
        .HideOnPC {
            @media (min-width: 701px) {
                display: none;
            }
        }
        
        .OperationPolicy{
            position: absolute;
            bottom: 20px;
            width: 100%;
            display: flex;
            justify-content: center;

            Button{
                color: rgb(255,255,255);
                border: 1.5px solid rgb(255,255,255);
                padding: 5px 50px;
                font-size: 1.1rem;
                cursor: pointer;
            };
        };
    };
};