$NavigationHeight: 47px;
$WidthPadding: 16px;
$Ratio: 1.25;
$OverlayAdditionalArea: 16px;
$TabletStandardWidth: 470px;
$TabletHeaderAndFooterHeight: 90px;

body:has(.HomeMobileArea) {
    position: fixed;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
}

.HomeMobileArea{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    background-color: rgba(25, 24, 27, 1);

    max-height: calc((var(--vh, 1vh) * 100) - #{$NavigationHeight});
    overflow: hidden;

    --vertical-margin: 24px;

    @media (max-height: 700px) {
        --vertical-margin: 24px;
    };
    @media (min-height: 700px) {
        --vertical-margin: 34px;
    };

    .CarouselContainer{
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: center;
        width: 100%;

        padding-top: var(--vertical-margin);
        background-color: rgba(25, 24, 27, 1);
    };

    .RelatedMusic{
        position: fixed;
        right: 16px;
        bottom: 76.67px;
        z-index: 2;

        display: flex;
        gap: 20px;
        align-items: center;

        .Notification{
            position: relative;
            opacity: 0;
            transition: 0.5s;

            &.On{
                opacity: 1;
            }

            .Message{
                position: absolute;
                top: 12px;
                left: 14px;

                color: rgba(255, 255, 255, 1);
                font-size: 0.8125rem;
                font-weight: 600;
                line-height: 16px;
                word-wrap: break-word;
            }
        }

        .RelativeParent{
            position: relative;
        }
    }

    .TopScreen{
        width: 100%; top: 0; left: 0; z-index: 1;
        position: fixed; background-color: rgba(51,51,51,0.5);
        height: calc(var(--vertical-margin) + #{$OverlayAdditionalArea});

        background: linear-gradient(180deg, #19181B 0%, rgba(25, 24, 27, 0) 98%);
    };
    .BottomScreen{
        width: 100%; bottom: $NavigationHeight; left: 0; z-index: 1;
        position: fixed; background-color: rgba(51,51,51,0.5);
        height: calc(var(--vertical-margin) + #{$OverlayAdditionalArea});

        background: linear-gradient(180deg, rgba(25, 24, 27, 0) 0%, #19181B 98%);
    };
};
