// Route/Analysis/Components/Styles/ApplicationForm.scss 사용
.ApplicationForm{
    .Form{
        .BasicInfo{
            .EmailInputBox {
                display: flex;
                flex-direction: column;
                width: 100%;
            }
            .SchoolInputBox{
                display: flex;
                flex-direction: column;
                gap: 8px;

                .CheckOutWithGuide{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .TextCheckBox {
                        padding: 0 8px;
                        height: 24px;
                        background-color: unset !important;
                        border: none;
                        span {
                            margin: 0 4px;
                        }
                    }

                    .Required{
                        color: rgba(250, 149, 56, 1);

                        line-height: 16px;
                        font-size: 0.8125rem;
                        font-weight: 500;
                    }
                }
            }
        }
        
        .AdCutInfo{
            .InfoSubtitle{
                margin-top: 12px;
                padding-left: 10px;
                color:  rgba(121, 120, 130, 1);
                font-size: 0.9375rem;
                font-style: normal;
                font-weight: 500;
                line-height: 24px; /* 160% */
                white-space: pre-line;
            }
        }
    }
}