
.AuthorReviews{
    
    .TitleText{
        margin: 32px 0;
    }

    .ReviewRow {
        width: 100%;
        display: flex;
        flex-direction: row;
        
        box-sizing: border-box;

        &:nth-child(odd) {
            flex-direction: row-reverse;  
        }

        .Review {
            position: relative;

            padding: 40px 36px 52px 36px;
            margin-bottom: 60px;
            width: 100%;
            max-width: 398px;
            background-color: white;
            border-radius: 20px;

            word-break: keep-all;
            box-shadow: 10px 10px 30px 5px rgba(12, 22, 39, 0.04), 0px 4px 4px 0px rgba(12, 22, 39, 0.02);
            
            .AuthorColumn{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;

                .Writer{
                    font-size: 1rem;
                    margin-bottom: 36px;
                    line-height: 1.5rem;

                    .MainText{
                        font-size: 1rem;
                        font-weight: 600;
                        margin-bottom: 4px;
                    }

                    :last-child {
                        line-height: 1rem;
                    }
                }
                .StarBox{

                    .Stars{
                        width: 103px;
                        height: 20px;
                        object-fit: contain;

                    }
                }
            }

            .CommentColumn{


                .Content{
                    font-size: 1.25rem;
                    line-height: 1.75rem;

                    .EmphasizeText{
                        font-weight: bold;
                    }
                }
                
            
                &:after {
                    display: block;
                    position: absolute;
                    bottom: -10px;
                    left: 360px;
                    transform: rotate(120deg);

                    content: "";
                    width: 40px;
                    height: 40px;
                    background-color: white;

                    @media (max-width:720px) {
                        display: none;
                    }
                }
            }


        }
    

        @media (max-width:720px) {
            justify-content: center;
        }
    }
} 