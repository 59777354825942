.VignetteAdBackground {
    position: fixed;
    top:0;left:0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: 2;
    background-color: rgba(25,24,27, 0.7);

    display: flex;
    justify-content: center;
    align-items: center;

    .CenterColumn {
        width: calc(100% - 32px);
        max-width: 500px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        
        .CloseButton {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 30px; height: 30px; border-radius: 30px; line-height: 30px;
            background-color: rgba(255,255,255,0.2); color: white;
        }

        .VignetteHeader {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 12px;

            .Punchline {
                font-size: 0.75rem;
                color: white;
            }

            .ButtonToPurchase {
                background-color: rgb(246, 246, 249);
                padding: 10px 40px;
                border-radius: 4px;

                font-size: 0.875rem;

                cursor: pointer;
                &:hover { filter: brightness(1.1); }
                &:active { filter: brightness(1.2); }
            }
        }
        
        .VignetteAd {
            width: 100%;
            height: 600px;
            position: relative;

            background-color: rgb(214,204,204);
    
            border-radius: 12px;
        }

        .VignetteFooter {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 12px;

            .Punchline {
                font-size: 1rem;
                color: white;
            }

            .ButtonToSkip {
                background-color: rgb(61, 106, 255);
                color: white;
                padding: 6px 16px; height: 40px;
                border-radius: 60px;

                font-size: 1rem;
                line-height: 40px;

                cursor: pointer;
                &:hover { filter: brightness(1.1); }
                &:active { filter: brightness(1.2); }
            }
        }
    }

}