
$SIGNATURE_COLOR: rgb(0,176,240);
@mixin customflex( $direction: row, $justify: stretch, $align: stretch ){
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: $align;
}

.DragAndDropForPortfolio{
    .DragAndDropContainer{
        @include customflex(row, stretch, center);
        width: 100%;
        height: 100%;
        border-radius: 4px;
        padding: 0px;
        overflow-x: auto;
        overflow-y: hidden;
        position: relative;
        min-height: 80px;

        @media (max-width: 700px) {
            grid-template-columns: 80px 80px;
        }
        @media (min-width: 700px) {
            grid-template-columns: 150px 150px 150px;
        }

        &::-webkit-scrollbar {
            all: initial;
            height: .8rem;
            width: .8rem;
        }
        
        &::-webkit-scrollbar-thumb {
            background-color: rgb(150,150,150);
            border-radius: 1rem;
        }

        .PlaceholderText{
            font-size: 0.9rem;
            color: rgb(200,200,200);
            white-space: pre-line;
            height: calc(100% - 20px);
            width: calc(100% - 20px);
            margin: 0 10px;
        }

        .AddArea{
            @media (hover: hover){
                @include customflex(row, center, center);
                width: 100%;
                height: 100%;
                min-height: 80px;
                pointer-events: none;
                background-color: rgb(232, 245, 254);
            }
        }


        .On{
            background-color: $SIGNATURE_COLOR;
        }

        .FileFrame{
            white-space: nowrap;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            position: relative;
            height: 100%;
            padding: 8px;

            .FilePreview{
                width: 76px;
                height: 76px;
                border: 2px dashed rgba(1,1,1,0.23);
                //margin: 0 4px;
            }
        }

        .Hide{
            display: none;
            pointer-events: none;
        }
        .AddRight{
            border-right: 3px solid $SIGNATURE_COLOR;
        }
        .AddLeft{
            border-left: 3px solid $SIGNATURE_COLOR;
        }
        .VacantSpace{
            border-left: 2px solid $SIGNATURE_COLOR;
        }
        .VacantLast{
            border-right: 2px solid $SIGNATURE_COLOR;
        }
        .OnLoading{
            pointer-events: none;
            opacity: 0.4;
        }
    }

    .CoveredContainer{
        display: block;
    }

    .DragAndDropContainerDisabled{
        pointer-events: none;
    }
}

.Dragging .DragAndDropForPortfolio{
    height: 110px;
    div {display: none;}
}