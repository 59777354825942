.PreventingScrollInThemeDecoration{
    overflow: hidden;

    @media (min-width: 701px) {
        overflow: auto;
    }
}
.ScrollableThemeDecoration{
    overflow: auto;
}

.ThemeDecorationArea{
    line-height: 24px;

    .Header{
        display: flex;
        justify-content: center;
        align-content: center;
        width: 100%;

        @media (max-width: 700px) {
            flex-direction: row;
        };

        @media (min-width: 701px) {
            flex-direction: column;
        };

        .PC{
            width: 100%;
            box-sizing: border-box;
            border-bottom: 1px solid rgb(241,241,241);
            height: 66px;
            

            @media (max-width: 700px) {
                display: none;
            };
        };

        .Mobile{
            width: 100%;
            max-width: 400px;
            display: flex;
            justify-content: center;
            padding-top: 14px;
            
            .MenuBar{
                max-width: 328px;
                width: 100%;
                display: flex;
                justify-content: space-between;
            };

            @media (min-width: 701px) {
                display: none;
            };
        };
        
        .Section{
            height: 50px;
            margin-bottom: 10px;

            font-size: 1.1rem;
            font-weight: bold;
            display: flex;
            justify-content: center;
            border-bottom: 1px solid rgb(241,241,241);
            padding: 0 20px;

            @media (max-width: 700px) {
                display: none;
            };

            .Container{
                max-width: 820px;
                width: 100%;
                display: flex;
                justify-content: space-between;

                .Title{
                    display: flex;
                    justify-content: 'center';
                    align-items: center;
                }

                .ButtonGroup{
                    display: flex;
                    align-items: center;

                    Button{
                        border-radius: 20px;
                        width: 110px;
                        height: 35px;
                    }

                    .Initialization{
                        border: 1px solid rgb(3,3,3);
                        margin-right: 16px;
                    }

                    .Save{
                        background-color: rgb(3,3,3);
                        color: rgb(255,255,255);
                    }
                }
            }
        };
    };

    .BodyContainer{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        
        width: 100%;
        
        @media (min-width: 701px) {
            flex-direction: row;
            align-items: flex-start;
            height: calc(100% - 126px);
        };


        
        .Decorations{
            display: flex;
            @media (max-width: 700px) {
                display: none;
            }

            .TemplateType{
                width: 203px;
                border: 1px solid rgb(241,241,241);
                height: calc(100vh - 126px);
                padding: 30px 25px;
                box-sizing: border-box;

                @media (min-height: 701px) {
                    margin-right: 16px;
                }

                .Title{
                    font-size: 1rem;
                    font-weight: bold;
                    text-align: center;
                }

                .TypeSelection{

                    .TypeBox{
                        width: 100%;
                        justify-content: center;
                        margin-top: 40px;
                        cursor: pointer;

                        .TemplatePreview{
                            width: 153px;
                            height: 190px;
                            margin: 0 auto;
                            background-color: rgba(0,176,240,0.5);
                        }
                    }
                }
            }

            .ThemeColor{
                width: 185px;
                border: 1px solid rgb(241,241,241);
                padding: 30px 25px;
                box-sizing: border-box;
                
                .Title{
                    font-size: 1rem;
                    font-weight: bold;
                    text-align: center;
                    margin-bottom: 45;
                }
                
                .ColorSelectBox{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .ForcusedOutline, .Outline{
                        margin: 8px 0px;
                        cursor: pointer;
                    }

                    .ForcusedOutline{
                        border: 1px solid rgb(15,15,15);
                        padding: 4px;
                        border-radius: 50%;
                    }

                    .Outline{
                        padding: 5px;
                        border-radius: 50%;
                    }
                }
            }
        }

        .MobileSlideOpener{
            box-shadow: 4px 4px 30px rgba(3, 3, 3, 0.2);
            position: fixed;
            bottom: 60px;
            background: rgb(255,255,255);
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            border-radius: 20px 20px 0 0;
            z-index: 1;
            @media (min-width: 701px) {
                display: none;
            }

            .TabBox{
                display: flex;
                justify-content: center;
                margin-bottom: 5px;

                .FocusedTabName, .TabName{
                    width: 160px;
                    padding: 6px 12px;
                    font-size: 1rem;
                    font-weight: bold;
                    display: flex;
                    justify-content: center;
                    margin-bottom: 1px;
                }

                .FocusedTabName{
                    color: rgb(3,3,3);

                    span{
                        padding-bottom: 2px;
                        border-bottom: 2px solid rgb(3,3,3)
                    }
                };

                .TabName{
                    color: rgb(196, 196, 196);
                };
            };
        };


        .MobileDecorations{

            @media (max-width: 700px) {
                background-color: rgb(233,233,233);
                width: 100vw;
                z-index: 1;
            }
            @media (min-width: 701px) {
                display: none;
            }

            .DecorationSlide{
                width: 100%;
                margin: 0 auto;
                max-width: 1000px;
                background-color: rgb(255,255,255);
                position: relative;
                
                .SelectedTab, .Tab{
                    font-size: 1rem;
                    font-weight: bold;
                    width: 160px;
                };

                .SelectedTab{
                    color: rgb(3,3,3);

                    span{
                        padding-bottom: 5px;
                        border-bottom: 2px solid rgb(3,3,3)
                    }
                };

                .Tab{
                    color: rgb(196,196,196);
                };
                
                .TypeSelection{
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    max-width: 400px;
                    margin: 0 auto;
                    margin-top: 20px;
                    justify-items: center;
                    padding-bottom: 120px;

                    .TypeBox{
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        cursor: pointer;
                        margin: 0 20px;

                        .TemplateExample{
                            width: 144px;
                            height: 164px;
                        };
                    };
                };

                .ColorSelection{
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    max-width: 200px;
                    margin: 0 auto;
                    padding: 20px 0px;
                    padding-bottom: 120px;

                    .FocusedOutline, .Outline{
                        width: 64px;
                        height: 64px;
                        cursor: pointer;
                        margin: 0 20px;
                        box-sizing: border-box;

                        .Color{
                            width: 54px;
                            height: 54px;
                            border-radius: 50%;
                        }
                    }

                    .FocusedOutline{
                        border: 1px solid rgb(15,15,15);
                        padding: 4px;
                        border-radius: 50%;
                    };
    
                    .Outline{
                        padding: 5px;
                        border-radius: 50%;
                    };
                }


                .ButtonGroup{
                    position: fixed;
                    bottom: 75px; width: 100%;
                    
                    margin: 0 auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 10px;

                    Button{
                        border-radius: 20px;
                        width: 138px;
                        height: 42px;
                    };

                    .Initialization{
                        border: 1px solid rgb(3,3,3);
                        margin-right: 16px;
                        background-color: rgb(255,255,255);
                    };

                    .Save{
                        background-color: rgb(3,3,3);
                        color: rgb(255,255,255);
                    };
                };
            };
        };

        .Closed{
            display: none;
        };
    };


}