.MultiUploadFilenameDisplayedArea{
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;

    .SingleUploadControlBox{
        display: flex;
        justify-content: space-between;

        button{
            padding: 8px 10px;
            border-radius: 5px;
        }

        .SingleUploadeButton{
            min-width: 0;

            padding: 8px 10px;
            border-radius: 5px;
            border: 1px solid rgb(213, 213, 216);
    
            background-color: rgb(255,255,255);
            color: rgb(25, 23, 28);

            font-size: 0.9375rem;
            font-family: Pretendard;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0.13px;
            word-wrap: break-word;

            cursor: pointer;

            &.Disabled{
                border: 1px solid rgb(237, 236, 238);
                background-color: rgb(246, 246, 249);
                cursor: auto;
                pointer-events: none;
            }
        }
    }
    .MultiUploadFilenameBody{
        display: flex;
        flex-direction: column;
        position: relative;

        .MultiUploadContainer{
            border: 1px dashed rgb(213,213,213);
            position: relative;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            gap: 8px;

        }
    }
    .NumberOfFiles{
        display: flex;
        justify-content: flex-end;
        color: rgb(121, 120, 130);
        font-size: 	0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.13px;
    }
}