@import './Header.scss';
$SIGNATURE_COLOR: rgb(0,176,240);

.Portfolio {
    position: relative;

    .PortfolioBG {
        position: absolute;
        width: 100%;
        @media (min-width: 701px) {
            display: none;
        }
    }

    .BG {
        position: absolute;
    }
    .PorfolioContainer {
        position: relative;

        @media (max-width: 700px) {
            overflow-x: hidden;
        }
        @media (min-width: 701px) {
            background-color: rgba(244,244,244,1);
            max-width: 800px;
            margin-left: auto; margin-right: auto;
            padding-left: 40px; padding-right: 40px;
        }

        .MobileSectionHeader {
            @media (min-width: 701px) {
                display: none;
            }
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            width: 100%;
            height: 60px;

            .Selection {
                position: relative;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                border-radius: 20px;
                width: 74px;
                height: 30px;

                font-weight: bold;
                font-size: 14px;
                color: black;

                &.Selected {
                    background-color: rgba(51,51,51,1);

                    color: white;
                }
            }
        }

        .MainBody {
            position: relative;
            display: flex;
            transition: 200ms;

            &.PC {
                @media (max-width: 700px) {
                    display: none;
                }
            }

            &.Mobile {
                @media (min-width: 701px) {
                    display: none;
                }
            }
            
            @media (max-width: 700px) {
                flex-direction: row;
            }

            @media (min-width: 701px) {
                flex-direction: row-reverse;
                justify-content: space-around;
                align-items: flex-start;
            }

            .PCEditButtonBox {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                
                padding-top: 20px;
                padding-bottom: 20px;
                padding-left: 30px;
                padding-right: 30px;

                .Button {
                    border-radius: 20px;
                    
                    height: 20px;
                    min-width: 40px;
                    margin-right: 10px;
                    padding-top: 5px; padding-bottom: 5px;
                    padding-left: 10px; padding-right: 10px;
                    font-weight: bolder;

                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;
                    align-items: center;

                    &.Selected {
                        background-color: black;
                        color: white;
                    }
                    
                    &:active {
                        background-color: rgba(196,196,196, 1);
                    }
                }
            }
        }

        .PCHeader {
            @media (max-width: 700px) {
                display: none;
            }
            height: 60px;

            position: relative;
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-around;
            align-items: flex-start;
            font-size: 14px;
            line-height: 24px;

            .Headline {
                width: 100%;
            }

            .ButtonContainer {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: row-reverse;
                justify-content: flex-start;
                align-items: center;

                .OutlineButton, .FilledButton {
                    user-select: none;
                    transition: 200ms;
                    border-radius: 20px;
                    border: 1px $SIGNATURE_COLOR solid;
                    margin-right: 20px;
                    
                    height: 30px;
                    width: 120px;

                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;
                    align-items: center;
                }

                .OutlineButton {
                    background-color: $SIGNATURE_COLOR;
                    color: white;
                    
                    &:active {
                        background-color: rgba(0,88,120, 1);
                    }
                }

                .FilledButton {
                    background-color: white;
                    color: $SIGNATURE_COLOR;
                    
                    &:active {
                        background-color: rgba(196,196,196, 1);
                    }
                }
            }
        }
    }
}

.Button {
    user-select: none;
    transition: 200ms;
    cursor: pointer;

    &:active {
        background-color: rgba(196,196,196, 1);
    }
}

.HideFloatingButtonBox {
    .FloatingButtonBox {
        display: none;
    }
}