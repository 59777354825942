
.BasicDropdownContainer{
    outline: 1px solid rgb(236, 237, 243);
    border: 0;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 8px 16px;
    position: relative;

    &:hover{
        outline: 1px solid rgba(61, 106, 255, 1);
    }
    &.Focused{
        outline: 2px solid rgba(61, 106, 255, 1);
    }
    
    .DropdownBox{
        display: grid;
        grid-template-columns: 1fr auto;
        font-size: 0.9175rem;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 160% */
        letter-spacing: 0.15px;
        color: rgb(51, 47, 91);
        align-items: center;
        height: 32px;
        box-sizing: border-box;
        overflow-x: auto;
        overflow-y: hidden;

        .DropdownRow{
            display: flex;
            gap: 5px;
        }

        .SelectedBox{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 4px 4px 4px 8px;
            gap: 3px;

            border-radius: 8px;
            border: 1px solid rgb(236, 237, 243);

            .SelectedItems{
                color: rgb(51, 47, 91);
                font-size: 0.8175rem;
                white-space: nowrap;
            }
            .DeleteTagButton{
                cursor: pointer;
                padding-left: 3px;
                font-size: 0.8rem;
            }
        }

        .DropdownButton{
            filter: invert(1);
            transition: 0.2s;
            &.Open{
                transform: rotate(-90deg);
            }
            &.Close{
                transform: rotate(90deg);
            }
        }
    }
    .DropdownContent{
        position: absolute;
        top: calc(100% + 10px); left: 0;
        width: 100%;
        outline: 1px solid rgb(236, 237, 243);
        border: 0;
        border-radius: 5px;
        box-sizing: border-box;
        padding: 16px;
        background-color: rgb(255,255,255);
        box-shadow: 0 0 5px rgba(0,0,0,0.1);
        transition: 0.5s;
        overflow: hidden;

        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 8px;
        column-gap: 10px;
        flex-wrap: wrap;

        max-height: 250px;
        overflow: auto;

        &.Open{
            opacity: 1;
        }
        &.Close{
            opacity: 0;
            z-index: -1;
        }

        .DropdownItemBox{
            display: flex;
            width: 100%;
            
            .DropdownItem{
                outline: 1px solid rgb(236, 237, 243);
                padding: 12px;
                border-radius: 5px;
                cursor: pointer;
                color: rgba(25, 24, 27, 1);
                font-family: Pretendard;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 142.857% */
    
                &.Row{
                    width: 100%;
                }
                &.Item{
                    width: auto;
                }
                &.Selected{
                    background-color: rgba(246, 246, 249, 1);
                }
            }
        }
    }
}