@import './Variables.scss';

body[data-scroll="0"] .ScrollToTopButton, body:not([data-scroll]) .ScrollToTopButton{
    opacity: 0; transition: 1s;
};

.HomeFeedArea{
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    .HomeFeedInner{
        display: grid; 
        grid-template-columns: repeat(auto-fill, $cell-width);
        grid-row-gap: $row-gap; grid-column-gap: $col-gap;
        max-width: calc( #{$cell-width} * 4 + #{$row-gap} * 3 );
        justify-content: center; justify-items: center;
        
        margin-left: auto; margin-right: auto;
        .FeedItem{
            display: flex;
        };
        .FeedItem.doubleSet{
            grid-column-end: span 2;
        };
        .FeedItem.single{
            grid-column-end: span 1;
        };
    };
    
    @media (max-width: calc(#{$cell-width} * 4 + #{$row-gap} * 3)) { // col = 3
        .HomeFeedInner {
            width: calc( #{$cell-width} * 3 + #{$row-gap} * 2 )
        };
    };
    @media (max-width: calc(#{$cell-width} * 3 + #{$row-gap} * 2)) { // col = 2
        .HomeFeedInner {
            width: calc( #{$cell-width} * 2 + #{$row-gap} * 1 )
        };
    };

    .ScrollToTopButton{
        position: fixed;
        bottom: 121px; right: 64px;
        width: 50px; height: 50px;
        border-radius: 50%;
        border: 1px solid #E1E2E4;
        background: rgba(255,255,255,0.6);
        display: flex; justify-content: center; align-items: center;
        cursor: pointer; z-index: 1; opacity: 1; transition: 5s;
    }
};
