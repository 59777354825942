.RectSlide {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    overflow: hidden;

    .slick-slider {
        position: absolute;
        width: 100%;
        height: 100%;
        top:0; left: 0;

        .slick-list {
            position: absolute;
            width: 100%;
            height: 100%;
            top:0; left: 0;

            .slick-track {
                display: flex;
                height: 100%;

                .slick-slide div{
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .slick-prev {
            position: absolute;
            left:0; top: calc(50% - 12px);
            height: 24px; width: 24px;
            z-index: 1;
        }

        .slick-next {
            position: absolute;
            right:0; top: calc(50% - 12px);
            height: 24px; width: 24px;
            z-index: 1;
        }

    }
}