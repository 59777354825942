$SIGNATURE_COLOR: rgb(0, 176, 240);

body {
    scroll-behavior: smooth !important;

    &::-webkit-scrollbar {
        display: initial;
        height: 0.8rem;
        width: 0.8rem;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: rgb(155,155,155);
        border-radius: 1rem;
    }
}

.LandingPage{
    position: relative;
    height: 100%;

    .FloatingHash{
        position: fixed;
        width: 90px;
        height: 90px;
        bottom: 20px;
        right: 20px;
        z-index: 1;
        padding: 0;
        text-align: center;
        border-radius: 120px;
        line-height: 1.2rem;

        word-break: keep-all;
        font-size: 1rem;

    }

}


