.MultiUploadFilenameDisplayedArea{
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;

    .MultiUploadControlBox{
        display: flex;
        justify-content: space-between;
        
        .MultiUploadeButton, .PreviewImages{
            min-width: 0;
            padding: 8px 10px;
            border-radius: 5px;
            
            background-color: rgb(255,255,255);

            font-size: 0.9375rem;
            font-family: Pretendard;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0.13px;
            word-wrap: break-word;

            cursor: pointer;

            &.Disabled{
                cursor: auto;
                pointer-events: none;
            }
        } 

        .MultiUploadeButton{
            border: 1px solid rgb(213, 213, 216);
    
            color: rgb(25, 24, 27);
            &.Disabled{
                background-color: rgb(246, 246, 249);
            }
        }
        .PreviewImages{
            border: 0; padding: 0;
    
            color: rgba(61, 106, 255, 1);
            &.Disabled{
                color: rgba(220, 220, 220, 1);
            }
        }
    }
    .MultiUploadFilenameBody{
        display: flex;
        flex-direction: column;
        position: relative;

        .ProgressBackgroundBar{
            position: absolute; bottom: 0; left: 0;
            width: 100%; height: 2px;
            background-color: rgba(0,176,240,0.3);
            border-radius: 5px;

            .ProgressActiveBar{
                position: absolute; left: 0; top: 0;
                height: 2px; transition: 1s;
                border-radius: 5px;
                background-color: rgb(0,176,240);
            }
        }
        .MultiUploadFilenameContainer{
            position: relative;

            display: flex;
            flex-direction: column;
            gap: 8px;
            
            border: 1px dashed rgb(213,213,213);
            border-radius: 5px;
            
            overflow-y: auto;

            &.Solid {
                border-style: solid;
            }
            .UploadFilenameFilesArea{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                box-sizing: border-box;
            }
            .UploadFilenameContainer{
                width: 100%;
                
                display: flex;
                align-items: center;
                
                position: relative;
                box-sizing: border-box;

                height: 34px;

                padding: 8px 16px;

                object-fit: cover;
                transition: 3s;
                border-radius: 5px;
                opacity: 1;

                
                &.Header{
                    background-color: rgba(246, 246, 249, 1);
                    flex-direction: row;
                    justify-content: flex-start;

                    padding: 12px 24px 12px 16px;
                    height: 42px;
                }
                &.Header.Loading::after{
                    content: '';
                    position: absolute;
                    top: 0; left: 0; width: 100%; height: 100%;
                    background-color: rgba(220, 220, 220, 0.2);
                }
                &.Loading{
                    background-color: rgba(246, 246, 249, 1);
                    .DeleteFileIcon{
                        pointer-events: none;
                    }
                    .FilenameText{
                        color: rgba(220, 220, 220, 1);
                    }
                }
            }

            .FilenameRow{
                display: grid;
                grid-template-columns: auto 1fr;
                align-items: center;


                .DeleteFileIcon{
                    margin-right: 6px;
                    padding: 8px;
                    
                    box-sizing: border-box;
                    color: rgba(121, 120, 130, 1);
                    
                    cursor: pointer;

                    img{
                        width: 12px; height: 12px;
                    }
                }

                .FilenameText{
                    color: rgb(25, 24, 27);
                    font-size: 	0.9375rem;
                    font-weight: 400;
                    line-height: 18px;
                    word-wrap: break-word;

                    overflow-x: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    cursor: pointer;

                    &.Disabled{
                        cursor: auto;
                    }
                }
            }
        }
    }
    .NumberOfFiles{
        display: flex;
        justify-content: flex-end;
        color: rgb(121, 120, 130);
        font-size: 	0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.13px;
    }
}