$SIGNATURE_COLOR: rgb(0,176,240);

.ToggleWithLabel {
    position: relative;
    &.ON{
        background-color: rgb(51,51,51);
        color: white;
    }
    &.OFF{
        background-color: rgba(192,192,192,1);
        color: white;
    }

    user-select: none;

    &.SMALL {
        height: 16px; width: 16px;
        border-radius: 8px;
        padding-left:8px;
        padding-right:8px;
        
        .Label {
            display: none;
            height: 16px;
            font-size: 14px;
        }
        .Togglewheel {
            width: 12.8px;
            height: 12.8px;
        }

        &.ON{
            .Label{ left:4px; top: 0;}
            .Togglewheel{ left: calc(100% - 16px + 1.6px); top: 1.6px; }
        }

        &.OFF{
            .Label{ right:4px; top: 0; }
            .Togglewheel{ left:1.6px; top: 1.6px; }
        }

    }
    
    &.MEDIUM {
        height: 16px; width: 32px;
        border-radius: 8px;
        padding-left:8px;
        padding-right:8px;
        
        .Label {
            height: 16px;
            width: 28px;
            text-align: center;
            line-height: 16px;
            font-size: 10px;
        }
        .Togglewheel {
            width: 12.8px;
            height: 12.8px;
        }

        &.ON{
            .Label{ left:4px; top: 0;}
            .Togglewheel{ left: calc(100% - 16px + 1.6px); top: 1.6px; }
        }

        &.OFF{
            .Label{ right:4px; top: 0; }
            .Togglewheel{ left:1.6px; top: 1.6px; }
        }

    }
    
    &.LARGE {
        height: 40px; width: 80px;
        border-radius: 20px;
        padding-left:20px;
        padding-right:20px;

        .Togglewheel{
            width: 32px;
            height: 32px;
        }
    }

    .Label {
        position: absolute;
    }

    .Togglewheel {
        position: absolute;
        background-color: white;
        border-radius: 100%;

        transition: 200ms;
    }
}
