.Header{
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;

    @media (max-width: 700px) {
        flex-direction: row;
    };

    @media (min-width: 701px) {
        flex-direction: column;
    };

    .PC{
        width: 100%;
        box-sizing: border-box;
        border-bottom: 1px solid rgb(241,241,241);
        height: 66px;
        

        @media (max-width: 700px) {
            display: none;
        };
    };

    .Mobile{
        width: 100%;
        max-width: 400px;
        margin-left: calc( 100% / 2 - 200px );
        margin-right: calc( 100% / 2 - 200px );
        display: flex;
        justify-content: center;
        padding-top: 14px;
        
        .MenuBar{
            max-width: 328px;
            width: 100%;
            display: flex;
            justify-content: space-between;
        };

        @media (min-width: 701px) {
            display: none;
        };
    };
    
    .Section{
        height: 50px;
        margin-bottom: 10px;

        font-size: 1.1rem;
        font-weight: bold;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid rgb(241,241,241);
        padding: 0 20px;

        @media (max-width: 700px) {
            display: none;
        };

        .Container{
            max-width: 820px;
            width: 100%;
            display: flex;
            justify-content: space-between;

            .Title{
                display: flex;
                justify-content: 'center';
                align-items: center;
            }

            .ButtonGroup{
                display: flex;
                align-items: center;

                Button{
                    border-radius: 20px;
                    width: 110px;
                    height: 35px;
                }

                .Initialization{
                    border: 1px solid rgb(3,3,3);
                    margin-right: 16px;
                }

                .Save{
                    background-color: rgb(3,3,3);
                    color: rgb(255,255,255);
                }
            }
        }
    };
};
