.ParentDisabled {
    position: absolute;
    left: 0; top: 0;
    background-color: rgba(255,255,255,0);
    transition: 400ms;
    z-index: -1;
    width: 100%; height: 100%;

    &.Show {
        background-color: rgba(244,244,244,0);
        z-index: 9;
    }
}