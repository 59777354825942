
.HashtagArea{
    display: flex;
    flex-direction: column;
    gap: 32px;
    font-family: Pretendard;

    .HashtagInner{
        display: flex;
        flex-direction: column;
        gap: 8px;

        .HashtagContainer{
            display: flex;
            flex-wrap: wrap;
            border-radius: 5px;
            border: 1px solid rgba(236,237,243,1);
            padding: 8px 16px;
            box-sizing: border-box;
            gap: 8px;

            min-height: 52px;
        
            &.FullWidth{
                width: 100%;
            }
        
            .HashtagBox{
                display: flex;
                justify-content: space-between;
                align-items: center;
        
                padding: 8px;
                border: 1px solid rgb(236, 237, 243);
                border-radius: 8px;
                color: #333333;
                box-sizing: border-box;
        
                .HashtagItem{
                    font-size:0.8125rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px; /* 123.077% */
                    letter-spacing: 0.13px;
                    color: rgba(25, 24, 27, 1);                 
                }
        
                .DeleteTagButton{
                    cursor: pointer;
                    margin-left: 3px;
                    font-size: 0.8rem;
                }
            }
        
            input{
                border: 0;
                background: transparent;
                font-family: inherit;
        
                &::placeholder{
                    font-size: 0.9125rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px; /* 160% */
                    letter-spacing: 0.15px;
                }
                &:focus{
                    outline: 0;
                }
            }
            input::placeholder{
                font-size: 0.9125rem;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 160% */
                letter-spacing: 0.15px;
                color: rgba(121,120,130,1);
            }

        }

        &.Adding{
            .HashtagContainer{

                &:hover{
                    outline: 1px solid rgba(61, 106, 255, 1);
                }
                &:focus-within{
                    outline: 2px solid rgba(61, 106, 255, 1);
                }
            }
        }

        &.Selecting{
            .HashtagContainer{
                border: 0; padding: 0;
                min-height: 0;
                .HashtagItem{
                    cursor: pointer;
                    font-size:0.8125rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px; /* 123.077% */
                    letter-spacing: 0.13px;
                    color: rgba(121, 120, 130, 1);

                    padding: 8px;
                    border: 1px solid rgb(236, 237, 243);
                    border-radius: 8px;
                    box-sizing: border-box;

                    &.Representative{
                        border: 1px solid rgba(61, 106, 255, 1);
                        color: rgba(25, 24, 27, 1);
                    }
                    &.Disabled{
                        color: rgba(138, 138, 138, 1);
                        background-color: rgba(213, 213, 216, 1);
                    }
                }
            }
        }

        .ExampleButton{
            display: flex;
            justify-content: flex-end;
            font-size: 0.8125rem;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 123.077% */
            letter-spacing: 0.13px;
            color: rgba(61, 106, 255, 1);
            cursor: pointer;
        }

        .BottomSupplementLine{
            display: flex;
            justify-content: space-between;
            font-size: 0.8125rem;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 123.077% */
            letter-spacing: 0.13px;

            .SupplementText{
                color: rgba(189, 189, 194, 1);
            }
            .TagRestriction{
                color: rgba(121, 120, 130, 1);
            }
        }
    }

}