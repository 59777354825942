@import './HomeMobile.scss';


.CardArea{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - #{$WidthPadding} * 2);
    max-width: $TabletStandardWidth;
    height: calc((100vw - #{$WidthPadding}) * #{$Ratio});
    position: relative;
    color: #F7F7F8;

    margin-top: calc( ( (var(--vh, 1vh) * 100) - #{$NavigationHeight} - var(--vertical-margin) * 2 - (100vw - #{$WidthPadding}) * #{$Ratio} ) / 2 );


    @media (min-width: $TabletStandardWidth) {
        height: calc((#{$TabletStandardWidth} + #{$TabletHeaderAndFooterHeight}));

        margin-top: calc( ( (var(--vh, 1vh) * 100) - #{$NavigationHeight} - var(--vertical-margin) * 2 - (#{$TabletStandardWidth} + #{$TabletHeaderAndFooterHeight}) ) / 2 );
        
    };

    .AuthorInfo{
        display: flex;
        gap: 24px;
        align-items: center;

        .AuthorProfile{
            display: flex;
            gap: 8px;
            align-items: center;

            color: white;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0.21px;
            word-wrap: break-word
        };

        .FollowButton{
            display: flex;
            gap: 4px;
            align-items: center;
            border-radius: 4px;
            padding: 6px 12px;
            background-color: rgba(14, 169, 246, 1);
            color: rgba(255, 255, 255, 1);
            font-size: 0.75rem;
            font-weight: 600;
            line-height: 12px;
            letter-spacing: 0.17px;
            word-wrap: break-word;
            opacity: 0;
            transition: 0.5s;

            &.Subscribed{
                background-color: rgba(121, 120, 130, 1);
            };

            &.Focused{
                opacity: 1;
            };
        };
    };
    .ImageContainer{
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: calc(100vw - #{$WidthPadding} * 2);

        .mySwiper{
            .swiper-wrapper{
                width: 100%;
                .swiper-slide{
                    width: 100%;
                    height: calc(100vw - #{$WidthPadding} * 2);
                    display: flex;
                    align-items: center;
                    
                    .swiper-slide-Image{
                        width: 100%;
                        height: calc(100vw - 32px);
                        object-fit: cover;
                        border-radius: 5px;
                        border: 1px solid rgba(67, 63, 70, 1);
                        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                        box-sizing: border-box;
                    };
                };

                @media(min-width: $TabletStandardWidth) {
                    .swiper-slide{
                        max-height: $TabletStandardWidth;
                        .swiper-slide-Image{
                            max-height: $TabletStandardWidth;
                            object-fit: cover;
                        };
                    };
                };
            };
        };

        .InteractionBar{
            position: absolute;
            right: 12px;
            bottom: 16px;
            display: flex;
            flex-direction: column;
            gap: 18px;
            z-index: 1;

            color: rgba(255, 255, 255, 1);
            text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.50);
            filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.50));
            font-size: 0.75rem;
            font-weight: 600;
            line-height: 12px;
            word-wrap: break-word;

            .Interaction{
                display: flex;
                flex-direction: column;
                gap: 2px;
                align-items: center;
            };
        };
        
        @media (min-width: $TabletStandardWidth) {
            height: $TabletStandardWidth;


        };
    };
    .WebtoonInfo{
        display: flex;
        flex-direction: column;
        gap: 4px;

        .Title{
            display: flex;
            justify-content: space-between;
            color: rgba(255, 255, 255, 1);
            font-size: 1.125rem;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.32px;
            word-wrap: break-word;
        }

        .OtherInfo{
            display: flex;
            gap: 8px;
            align-items: center;
            color: rgba(150, 139, 156, 1);
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0.20px;
            word-wrap: break-word;

            .ViewCount{
                display: flex;
                align-items: center;
                gap: 4px;
            };
        };
    };

    &::before{
        position: absolute;
        top: 0;
        height: 40px;
        width: 100%;
        background: rgba(51,51,51,1);
    }
    &::after{
        height: 40px;
        width: 100%;
        background: rgba(51,51,51,1);
    }
};