$SIGNATURE_COLOR: rgb(0,176,240);

.OverviewArea{
    line-height: 24px;

    .SchoolCompetitionStateBar{
        display: flex;
        width: 100%;
        margin: 20px auto 16px;
        max-width: calc(912px + 20px);
        padding: 8px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        background: rgba(242, 249, 255, 1);
        box-sizing: border-box;
        
        @media (max-width: 700px) {
            margin: 0;
        }
        
        .TrafficBall{
            width: 12px;
            height: 12px;
            border-radius: 50%;
        }
        .Text{
            color: rgba(60, 70, 81, 1);
            font-family: Pretendard;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 142.857% */
        }
    }

    .Header{
        display: flex;
        justify-content: center;
        align-content: center;
        width: 100%;

        @media (max-width: 700px) {
            flex-direction: row;
        }
        @media (min-width: 701px) {
            flex-direction: column;
        }

        .PC{
            width: 100%;
            box-sizing: border-box;
            border-bottom: 1px solid rgb(241,241,241);
            height: 66px;

            @media (max-width: 700px) {
                display: none;
            }
        }

        .Mobile{
            width: 100%;
            max-width: 400px;
            display: flex;
            justify-content: center;
            padding-top: 14px;
            
            .MenuBar{
                max-width: 328px;
                width: 100%;
                display: flex;
                justify-content: space-between;
            }

            @media (min-width: 701px) {
                display: none;
            }
        }

        .Section{
            height: 60px;

            @media (max-width: 700px) {
                display: none;
            }
        }
    }
    
    .PCHeader {
        @media (max-width: 700px) {
            display: none;
        }

        position: relative;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-around;
        align-items: center;
        font-size: 14px;
        line-height: 24px;

        width: 100%;
        max-width: 830px;
        margin: auto;

        .Headline {
            width: 100%;
            display: block;
            font-size: 1.17em;
            margin-left: 0;
            margin-right: 0;
            font-weight: bold;
        }

        .ButtonContainer {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-start;
            align-items: center;

            .OutlineButton, .FilledButton {
                user-select: none;
                transition: 200ms;
                border-radius: 20px;
                border: 1px $SIGNATURE_COLOR solid;
                margin-left: 20px;
                
                height: 30px;
                width: 120px;

                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
            }

            .OutlineButton {
                background-color: white;
                
                &:active {
                    background-color: rgba(196,196,196, 1);
                }
            }

            .FilledButton {
                background-color: $SIGNATURE_COLOR;
                color: white;

                
                &:active {
                    background-color: rgba(0,88,120, 1);
                }
            }
        }
    }
    

    .ThemeEditForPC {
        height: 100%;
        width: 100%;
        max-width: 400px;
        
        @media (max-width: 700px) {
            display: none;
        };
    };

    .ThemeEditForMobile, .ThemeEditSlideup{
        @media (min-width: 701px) {
            display: none;
        }
    }

    .BodyContainer{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
        max-width: 1024px;
        margin: 16px auto 0;
        
        width: 100%;
        @media (min-width: 701px) {
            flex-direction: row;
            align-items: flex-start;
            height: calc(100% - 126px);
        };

        .TitleInfoContainer {
            height: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;

            @media (max-width: 700px) {
                width: 100%;
                background-color: white !important;
            };

            @media (min-width: 701px) {
                padding-left: 50px; padding-right: 50px;
                width: 50%; box-sizing: border-box;
                min-height: 900px;
            };
                    
            .PCShareButton{
                position: absolute;
                right: 20px;
                top: 20px;
                cursor: pointer;
                @media (max-width: 700px) {
                    display: none;
                }
            };
        };

        .ActionsContainer {
            width: 100%;

            .TitleInfomation{
        
                .LinkToTitle{
                    font-weight: bold;
                    font-size: 0.9rem;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                };
        
                .Description{
                    font-size: 0.875rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                };

                .GenreKeywordTags{
                    margin-top: 5px;
                    line-height: 27px;

                    .Tag{
                        font-size: 0.875rem;
                        padding: 4px 6px;
                        background-color: rgba(238,238,238,0.5);
                        white-space: pre;
                        margin-right: 6px;
                        cursor: pointer;
                        border-radius: 3px;
                    };
                };
            };

            .Actions {
                max-width: 350px;
                margin: auto;

                .AuthorArea{
                    margin-top: 25px;
            
                    .AuthorInner{
                        margin-bottom: 18px;
                        font-weight: 600;
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        box-sizing: border-box;
            
                        @media (min-width: 701px) {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                        }
            
                        
                        .ButtonBox{
                            display: flex;
                            align-items: center;
                            margin: auto;
            
                            Button{
                                border: 1px solid rgb(3,3,3);
                                width: 156px;
                                height: 36px;
                                border-radius: 20px;
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                font-size: 0.8rem;
                                font-weight: 600;
                                margin-bottom: 18px;
            
                                @media (min-width: 701px) {
                                    width: 100%;
                                }
            
                                .Icon{
                                    display: flex;
                                    padding-right: 11px;
                                    padding-left: 18px;
                                    
                                    @media (min-width: 701px) {
                                        padding-left: 33%;
                                    }
                                };
                            };
                            
                            @media (min-width: 701px) {
                                width: 100%;
                            }
                        }
            
                        @media (max-width: 700px) {
                            .ButtonBox:nth-child(odd){
                                justify-content: left;
                            }
                            .ButtonBox:nth-child(2n){
                                justify-content: right;
                            }
                        }
            
                    };
                };
                
                .ReaderArea{
            
                    .ReaderButtonBox{
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 18px;
                        margin-top: 25px;
                        font-weight: 600;
            
                        .MuiButton-label{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        };
            
                        .Icon{
                            display: flex;
                            padding-right: 11px;
                            padding-left: 18px;
                        };
                        
                        Button{
                            border: 1px solid rgb(3,3,3);
                            width: 156px;
                            height: 36px;
                            border-radius: 20px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 0.9rem;
                            font-weight: 600;
                        };
                    };
                };
            }
        }

        .WhiteContainer{
            @media(max-width: 700px) {
                background-color: rgb(255,255,256) !important;
            }
        }
        
        .EpisodeArea{
            max-width: 400px;
            width: 100%;
            box-sizing: border-box;

            @media (min-width: 701px){
                width: calc(50% - 20px);
            };

            .EpisodeHeader{
                max-width: 400px;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-top: 1px solid rgb(241,241,241);
                border-bottom: 1px solid rgb(241,241,241);

                .MenuBar{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    font-size: 0.75rem;
                    color: rgb(117,117,117);
                    padding: 6px 8px;
                    box-sizing: border-box;

                    .SortingButtons{
                        display: flex;

                        .Focusing{
                            color: rgb(80,80,80);
                            font-weight: bold;
                        };
                        .Basic{
                            color: lightgray;
                            cursor: pointer;
                        };

                        .DividingLine{
                            margin: 0 10px;
                            color: rgb(217,217,217);
                        };
                    };
                };
            };
            
            .EpisodeBody{
                max-width: 400px;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
    
                .EpisodeList{
                    width: 100%;
    
                    .EpisodeTable{
                        margin-bottom: 75px;
            
                        .Free, .NotFree{
                            display: flex;
                            align-items: center;

                            .EditButtonBox{
                                display: flex;
                                flex-direction: column;
                                align-items: center;

                                .EditButton{
                                    width: 36px;
                                    height: 36px;
                                    border-radius: 8px;
                                    padding: 0;
                                    min-width: 36px;
                                }
                            }
                        }

                        .StandardRow{
                            padding: 0 10px;
                            display: flex;
                            border-bottom: 2px solid lightgray;
                            padding-bottom: 5px;
            
                            .TableTitle{
                                font-size: 1.2rem;
                                padding: 0 5px 5px 0;
                            };
                            .Focusing{
                                color: rgb(80,80,80);
                                cursor: pointer;
                            };
                            .Basic{
                                color: lightgray;
                                cursor: pointer;
                            };
                        };
            
                        .EpisodeRow{
                            border-bottom: 1px solid lightgray;
                            padding: 5px 0;
                            display: flex;
                            cursor: pointer;
                            box-sizing: border-box;
                            width: 100%;
            
                            .TextCell{
                                margin-left: 16px;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                text-align: left;
                                flex: 1;
                                width: calc(100% - 126px - 16px);
                                
            
                                .EpisodeTitle{
                                    white-space: nowrap;
                                    font-size: 1rem;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                };
                                .CreatedTime{
                                    color: rgb(180,180,180);
                                    font-size: 0.8rem;
                                };
                            };
                        };
                        
                        .EpisodeRow:last-child{
                            border-bottom: 0
                        };
                    };
                };
            };
        };
    

        .ToastBox{
            position: fixed;
            background-origin: 10px;
            width: 360px;
            left: calc(50% - 180px);

            .UpdateToast{
                padding: 6px 24px;
                border-radius: 20px;
                font-size: 0.9rem;
                background-color: rgb(196,196,196);
                text-align: center;
            };
        };

        .NotFoundArea{
            padding-top: 126px;
        };
    };
};

.TitleHomeFavoriteTitle{
    background-color: rgba(1,1,1,0.8) !important;
    padding: 12px 10px !important;
    margin: 6px 0 !important;

    .MuiTooltip-arrow{
        color: rgba(1,1,1,0.8);
    };
};

