
.NoticeDetail{

    .DetailContainer{
        display: flex;
        justify-content: center;
        width: calc(100% - 20px);
        margin: auto;

        .DetailInner{
            width: 100%;
            max-width: 700px;
            margin: 40px 0;
            
            .Title{
                margin-bottom: 16px;
                
                color: rgba(60, 70, 81, 1);
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 600;
                line-height: 32px;
            }

            .NoticeDetailButtons{
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: stretch;
                gap: 10px;

                .DetailButton{
                    border-radius: 5px;
                    min-width: 23px;
                    min-height: 16px;
                    padding: 8px 10px;
                    
                    font-size: 0.8125rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;
                    letter-spacing: 0.13px;
                    box-shadow: none;
                    font-family: inherit;

                    &.Edit{
                        border: 1px solid rgba(237, 236, 238, 1);
                        background: rgba(255, 255, 255, 1);

                        color: rgba(25, 24, 27, 1);
                    }

                    &.Delete{
                        background: rgba(235, 81, 71, 1);

                        color: rgba(255, 255, 255, 1);
                    }
                }

                .DetailButton:last-child{
                    margin-right: 0;
                }
            }

            .PostTitle{
                margin-top: 8px;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px 0px 14px 8px;
                border-bottom: 1px solid rgba(237, 236, 238, 1);

                color: rgba(25, 24, 27, 1);
                font-size: 0.9375rem;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0.15px;
                font-family: inherit;
            }

            .PostDate{
                display: flex;
                padding: 8px 12px 8px 0px;
                justify-content: flex-end;
                align-items: center;
                align-self: stretch;

                color: rgba(158, 157, 164, 1);
                font-size: 0.8125rem;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.13px;
            }

            .CurrentPostDescription{
                padding-bottom: 15px;
                border-bottom: 1px solid rgba(237, 236, 238, 1);
            }

            .DetailFooter{
                display: flex;
                padding: 20px 10px 40px 10px;
                justify-content: center;
                align-items: center;
                align-self: stretch;

                .DetailButton{
                    padding: 12px 24px;
                    min-width: 49px;
                    min-height: 20px;

                    border-radius: 8px;
                    background: rgba(51, 51, 53, 1);

                    color: rgba(255, 255, 255, 1);
                    font-family: inherit;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 0.14px;
                    box-shadow: none;
                }
            }

            .ScrollToTopButton{
                position: fixed;
                bottom: 121px;
                right: 64px;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                border: 1px solid rgba(225, 226, 228, 1);
                background: rgba(255, 255, 255, 0.6);

                display: flex;
                justify-content: center;
                align-items: center;
                
                cursor: pointer;
                z-index: 1;
                opacity: 1;

                svg{
                    width: 28px;
                    height: 28px;
                }

                @media (max-width: 700px){
                    bottom: 87px;
                    right: 10px;
                    width: 24px;
                    height: 24px;

                    svg{
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
    }
    .InsertImages{
        vertical-align: bottom;
    }
}