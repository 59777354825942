
.WebtoonViralsArea{
    width: calc(100% - 100px);
    padding: 30px 50px;
    background-color: rgb(255,255,255);
    margin: 30px 0;
    border-radius: 10px;

    @media(max-width: 700px) {
        width: calc(100% - 32px);
        padding: 16px;
        margin: 10px 0;
    }
    
    .Title{
        font-size: 1.25rem;
        font-weight: bold;
        margin-bottom: 32px;

        .SearchText{
            color: rgb(0,176,240);
        }
    }
    
    .SubTitle{
        margin-bottom: 20px;

        .AccumulatedCounts{
            color: rgb(0,176,240);
            font-weight: bold;
            margin-left: 8px;
        }
    }

    .ViralBox{
        padding: 24px;
        background-color: rgb(246, 246, 246);
        margin-bottom: 18px ;
        border-radius: 6px;
        cursor: pointer;

        @media (max-width) {
            padding: 20px;
        }

        .TitleBox{
            display: flex;
            flex-direction: column;
            justify-content: stretch;
            margin-bottom: 16px;

            .ViralTitle{
                color: rgb(12, 67, 183);
                white-space: nowrap;
                overflow-x: hidden;
                text-overflow: ellipsis;
                width: 100%;
                margin-bottom: 6px;
            }

            .Source{
                font-size: 0.75rem;
                color: rgb(117, 117, 117);

                .CreatedTime{
                    margin-left: 16px;
                }
            }
        }

        .DescriptionsBox{
            font-size: 0.875rem;
            line-height: 24px;

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    .MoreBlogButtonBox{
        display: flex;
        justify-content: center;

        Button{
            border-radius: 10px;
            width: 100%;
            max-width: 300px;
            margin-top: 15px;
        }
    }
}