

.NoticeList{

    .ListContainer{
        display: flex;
        justify-content: center;
        width: calc(100% - 20px);
        margin: auto;

        .ListInner{
            width: 100%;
            max-width: 700px;
            margin: 40px 0;

            .Title{
                margin-bottom: 16px;
                position: relative;

                color: rgba(60, 70, 81, 1);
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 600;
                line-height: 32px;

                .MaximumPostSelector{
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: center;
                    position: absolute;
                    right: 0;
                    bottom: 5px;

                    .MuiInput-underline:before{
                        border-bottom: 0px;
                    }
                    
                    .MuiInput-input{
                        font-size: 0.875rem;
                        padding: 0px 24px 0px 0px;
                    }
                }
            }

            .ButtonArea{
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                margin: 0 0 8px 0;

                .PostButton{
                    padding: 8px 10px;
                    border-radius: 5px;
                    border: 1px solid rgba(237, 236, 238, 1);
                    background: rgba(255, 255, 255, 1);
                    min-width: 34px;
                    min-height: 16px;

                    color: rgba(25, 24, 27, 1);
                    font-size: 0.8125rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;
                    letter-spacing: 0.13px;
                    word-break: keep-all;
                    box-shadow: none;
                    font-family: inherit;
                }
            }

            .PostTable{
                width: 100%;
                border-collapse: collapse;

                .PostTableBody{
                    td:first-child{
                        padding-left: 20px;
                    }

                    .StandardRow{
                        height: 40px;
                        border-top: 1px solid rgba(237, 236, 238, 1);
                        border-bottom: 1px solid rgba(237, 236, 238, 1);
                        background: rgba(242, 242, 247, 0.4);

                        color: rgba(158, 157, 164, 1);
                        font-size: 0.8125rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px;
                        letter-spacing: 0.13px;
        
                        .PostType{
                            text-align: center;
                            width: 50px;
                        }
                        
                        .PostTitle{
                            text-align: left;
                        }
        
                        .PostCreatedTime{
                            text-align: center;
                            width: 100px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }

                    .PostRow{
                        height: 45px;
                        border-bottom: 1px solid rgba(237, 236, 238, 1);
                        cursor: pointer;

                        .PostType{
                            padding: 0 5px;
                            text-align: center;
                            width: 50px;
                            white-space: nowrap;
                        }
                        
                        .PostTitle div{
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            max-width: calc(100vw - 32px - 40px - 80px);

                            color: rgba(25, 24, 27, 1);
                            font-size: 0.8125rem;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 20px;
                            letter-spacing: 0.13px;
                        }
        
                        .PostCreatedTime{
                            padding: 0 5px;
                            text-align: center;
                            width: 80px;
                            white-space: nowrap;

                            color: rgba(158, 157, 164, 1);
                            font-size: 0.8125rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 20px;
                            letter-spacing: 0.13px;
                        }
                    }

                    .PostRow:hover{
                        background-color: rgba(0,176,240,0.1);
                    }

                    .Significant{
                        background-color: rgba(255,255,0,0.2);
                    }
                }
            }

            .ListPagination{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 40px 0;
                
            }

            .SearchArea{
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: flex-end;
                padding: 0 0 20px 10px;
                
                .SearchRow{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: flex-end;
                    background: rgb(255,255,255);
                    border: 1px solid rgb(223,225,229);
    
                    .SearchPost{
                        padding: 0 5px;

                        ::before{
                            border-color: rgba(255,255,255,0);
                        }
                        ::after{
                            border-color: rgba(255,255,255,0);
                        }
                    }
                }

                .SearchIcon{
                    background-color: rgb(0,176,240);
                    width: 34px;
                    height: 34px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    border-radius: 0;

                    svg{
                        color: rgb(255,255,255);
                    }
                }
            }

            .ScrollToTopButton{
                position: fixed;
                bottom: 121px;
                right: 64px;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                border: 1px solid rgba(225, 226, 228, 1);
                background: rgba(255, 255, 255, 0.6);

                display: flex;
                justify-content: center;
                align-items: center;
                
                cursor: pointer;
                z-index: 1;
                opacity: 1;

                svg{
                    width: 28px;
                    height: 28px;
                }

                @media (max-width: 700px){
                    bottom: 87px;
                    right: 10px;
                    width: 24px;
                    height: 24px;

                    svg{
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
    }

    .InsertImages{
        vertical-align: bottom;
    }
}