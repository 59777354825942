$SIGNATURE_COLOR: rgb(0, 176, 240);

.ContentsPaymentArea{

    .ContentesPaymentInner{
        max-width: 600px;
        margin: 0 auto;
        width: calc(100% - 32px);
        line-height: 150%;
    
        .LabelRow {
            display: flex;
            flex-direction: row;
            justify-content: stretch;
            align-items: center;

            .Image {
                width: 80px;
                height: 80px;
                
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                border-radius: 8px;
                overflow: hidden;

                margin-right: 16px;
            }
        }
        .RadioLabel {
            height: 80px;
            width: 100%;
            -webkit-line-clamp: 3;
            word-break: keep-all;

            .ProductTitle {
                font-size: 1.125rem;
                font-weight: 600;
                margin-bottom: 8px;
            }
        }
        
        .DividingLine{
            margin: 10px 0;
            border-top: 1px solid rgb(51,51,51);
            width: 100%;
        }

        .OrderedProductList{
            padding: 30px 0;

            .Title{
                font-weight: bold;
            }

            .Text{
                font-size: 0.875rem;
            }
        }

        .EpisodeList {
            position: relative;
            max-height: 500px;
            transition: 200ms;
            overflow-x: hidden;
            overflow-y: scroll;


            &.Fold {
                max-height: 0px;
                overflow-y: hidden;

                .FoldCover {
                    position: absolute;
                    top: 0; left: 0; width: 100%; height: 100%;

                    background: linear-gradient(0deg, rgba(0,0,0,0.4), transparent);
                }
            }
            
            .EpisodeRow {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                margin: 8px 0;
                gap: 8px;

                img {
                    max-width: 100px;
                    max-height: 100px;
                }

                .Date {
                    font-size: 0.8rem;
                }
            }
        }

        .FoldEpisodeListButton {
            background-color: white;
            border-color: rgb(255,255,255) rgba(0,0,0,0) rgba(0,0,0,0) rgba(0,0,0,0);
            border-radius: 0;
        }

        .OrderedServicePrices{
            padding: 30px 0;

            .Title{
                font-weight: bold;
            };

            .Text{
                font-size: 0.875rem;
                line-height: 150%;
            };

            .PriceRow{
                display: flex;
                justify-content: space-between;
                
                &:last-child{
                    margin-top: 10px;
                    font-weight: bold;
                };

                .Discounts{
                    position: relative;

                    .DiscountPercent{
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        transform: translate(-120%, 0);
                        color: red;
                        font-size: 0.75rem;
                        white-space: nowrap;
                    }
                }
            };

            .InnerDividingLine{
                margin: 6px 0;
                border-top: 1px solid rgb(241,241,241);
            };
        };

        .CheckAndConsentOfOrder{
            padding: 30px 0;

            .Title{

                text-align: right;
                padding-right: 8px;
                
                .MuiFormControlLabel-label{
                    font-weight: bold;
                    font-family: inherit;
                };
            };

            .DetailsOfTerms{
                cursor: pointer;

                &:hover{
                    color: rgb(51,51,51);
                    font-weight: bold;
                }
            };
        };

        .ButtonBox{
            margin: 20px 0 100px 0;
            
            .PayButton{
                font-weight: bold;
                padding: 8px 0;
                color: rgb(255,255,255);
            }
        }
    };
};