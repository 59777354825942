
.CollapseCell {
    position: relative;
    background-color: white;
    width: calc(100% - 2px - 28px);
    border: 1px rgba(196,196,196, 1) solid;
    border-radius: 20px;
    padding: 14px;
    margin-bottom: 20px;


    .CollapseHeader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .CollapseTitle {
            width: 100%;
        }
        
        svg {
            color: rgba(51,51,51,1);
        }
    }

    .CollapseBody {
        position: relative;
        padding-top: 10px;
        transition: 200ms;
        overflow-y: hidden;

    }

    &.Over {
        background-color: rgb(241,241,241);
    }

    .DragCover {
        display: none;
        position: absolute; left: 0; top:0;
        width: 100%;
        height: 100%;

        &.Nondragging {
            display: block;
        }
    }

}