
.TrendLandingArea{
    width: 100%;
    height: 100%;
    min-height: calc( 100vh - 66px);
    
    .IntroductionContainer{
        background: linear-gradient(98.01deg, rgba(166, 231, 255, 0.65) 11.11%, rgba(208, 242, 255, 0.7) 36.96%, rgba(207, 234, 255, 0.7) 55.68%, rgba(206, 223, 255, 0.7) 72.26%, rgba(219, 227, 255, 0.7) 85.25%);

        .TrendAnalyticsInner{
            max-width: 800px;
            width: 100%;
            margin: 0 auto;
            padding: 60px 0 40px;

            .WebtoonTodayLogoBox{
                display: flex;
                justify-content: center;
                margin-bottom: 24px;
            }
            
            .Title{
                font-size: 2.625rem;
                text-align: center;
                font-weight: bold;
                margin-bottom: 56px;
            }

            .AlignBox{
                display: flex;
                width: 100%;
                box-sizing: border-box;

                .SpeechBubble{
                    padding: 16px 32px;
                    background-color: white;
                    margin-bottom: 30px;
                    border-radius: 10px;
                    border: 1px solid rgb(51,51,51);
                    position: relative;
                    font-size: 1.25rem;

                    @media( max-width: 700px ) {
                        padding: 12px 24px;
                        font-size: 1rem;
                    }

                    .Tail, .TailInner{
                        position: absolute;
                        content: "";  
                        transform: translate(0, +100%);
                        bottom: 0;
                    }
                }
            }

            .AlignBox:nth-child(odd){
                justify-content: flex-start;
                padding-left: 25px;
                @media (max-width: 700px) {
                    padding-left: 10px;
                }

                .SpeechBubble{
                    .Tail, .TailInner{
                        left: 35px;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                    }

                    .Tail{
                        border-top: 20px solid black;
                    }

                    .TailInner{
                        border-top: 20px solid white;
                        transform: translate(0, calc(100% - 1px));
                    }
                }
            }

            .AlignBox:nth-child(even){
                justify-content: flex-end;
                padding-right: 25px;
                @media (max-width: 700px) {
                    padding-right: 10px;
                }

                .SpeechBubble{
                    .Tail, .TailInner{
                        right: 35px;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                    }

                    .Tail{
                        border-top: 20px solid black;
                    }

                    .TailInner{
                        border-top: 20px solid white;
                        transform: translate(0, calc(100% - 1px));
                    }
                }
            }

            .ServiceIntroductionBox{
                font-size: 1.25rem;
                white-space: pre-line;
                line-height: 40px;
                text-align: center;
            }

        }
    }

    .BodyContainer{
        margin-bottom: 100px;
        
        .InvitingCodeBox{
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 400px;
            margin: 0 auto;
            padding: 30px 0;

            @media (max-width: 700px) {
                align-items: center;
                width: calc(100% - 32px);
            }

            .InvitingLabel{
                width: 100%;
                font-weight: bold;
            }
            
            .LoginGuidelines{
                white-space: pre-line;
                line-height: 32px;
                text-align: center;
            }
        }

        .LoginCheckBox{
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 600px;
            margin: 0 auto;
            padding: 30px 0;

            @media (max-width: 700px) {
                align-items: center;
                width: calc(100% - 32px);
            }

            .LoginGuideLines{
                white-space: pre-line;
                line-height: 40px;
                text-align: center;
                margin-bottom: 40px;
            }
        }
    }

}