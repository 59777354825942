
.ManuscriptBox{
    padding: 10px 0;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,0.23);
    position: relative;
    cursor: pointer;
    width: 100%;
    margin-bottom: 5px;
    
    .ScriptBoxInner{
        padding: 0 10px;
        display: flex;
        justify-content: stretch;
        align-items: center;
        flex-direction: column;    
        
        .Rows{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .RowBox{
                display: flex;

                .Files{
                    display: flex;
                    flex-direction: column;
                    width: 85%;
                    justify-content: center;

                    .ProductRow{
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
    
                        .NameCell{
                            display: flex;
                            width: 65%;
    
                            .FileName{
                                white-space: nowrap;
                                text-overflow: ellipsis;

                            };
                        };
                    };
                };

                .PreviewButton{
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    flex: 1;

                    Button{
                        color: rgb(0,176,240);
                        cursor: pointer;
                    };
                };
            };

            .AddImage{
                text-align: center;
                margin-top: 10px;
            };
        };
    }

    .Center{
        justify-content: center;
    }
    
    .ManuscriptsDropZone.hover{
        display: flex;
        justify-content: center;
        align-items: center;

        position: absolute;
        left: -2px; top: -2px;
        width: 100%; height: 100%;
        z-index: 99999;
        background-color: rgb(255,255,255);
        border: 2px dashed rgb(0,176,240);
        border-radius: 5px;
    }

    .ManuscriptsDropZone{
        display: none;
    }
}