

.EmptyResultArea{

    .EmptyResultMessage{
        padding: 24px 40px;
        background-color: rgb(233, 234, 237);
        margin: 30px 0;
        border-radius: 10px;

        span{
            font-weight: bold;
        }

        @media(max-width: 700px) {
            padding: 16px;
            margin: 10px 0;
        }
    }
    
    .EmptyResultChartContainer{
        padding: 30px 50px;
        background-color: rgb(255,255,255);
        margin: 30px 0;
        border-radius: 10px;

        @media(max-width: 700px) {
            width: calc(100% - 32px);
            padding: 16px;
            margin: 10px 0;
        }
        
        .ContainerTitle{
            font-size: 1.25rem;
            font-weight: bold;
            margin-bottom: 15px;
        }

        .PlatformTitle{
            font-size: 1.25rem;
            font-weight: bold;
            margin-bottom: 32px;
        }

        .SimilarTopicItems{
            display: flex;
            margin-bottom: 10px;
            font-size: 0.81rem;
    
            .Topic{
                padding: 8px 12px;
                border-radius: 32px;
                background-color: rgb(248, 248, 248);
                margin: 0 6px 0 0;
                cursor: pointer;
                text-decoration: none;
                color: inherit;
            }
        }
    }
}