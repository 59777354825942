.PostTitleArea{
    display: flex;
    justify-content: center;
    background-color: rgb(242, 243, 247);

    .PostTitleInner{
        max-width: 720px;
        width: 100%;
        padding-bottom: 60px;
        box-sizing: border-box;
        user-select: none;
        min-height: calc(100vh - 60px);

        @media (max-width: 720px) {
            max-width: 375px;
            width: 100%;
            min-height: 0;

            padding: 0px 16px 40px 20px;
        }

        &.Disabled{
            .Form{
                pointer-events: none;
            }
        }

        .Form{
            display: flex;
            flex-direction: column;
            gap: 24px;
            position: relative;

            padding-top: 20px;
            
            .SectionHeader{
                color: rgba(25, 24, 27, 1);
                font-size: 1rem;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0.16px;
                display: flex;
                flex-direction: column;
                gap: 12px;

                @media (max-width: 720px){
                    gap: 16px;
                }

                .HeaderLine{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
 
                    font-size: 1.625rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 30px; /* 115.385% */

                    .NecessarySection, .OptionalSection{
                        padding: 6px 10px;
                        font-size: 0.8125rem;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 16px; /* 123.077% */
                        letter-spacing: 0.13px;
                        border-radius: 5px;
                    }
                    .NecessarySection{
                        color:rgba(137, 85, 246, 1);
                        background: linear-gradient(0deg, rgba(137, 85, 246, 0.10) 0%, rgba(137, 85, 246, 0.10) 100%), #FFF;
                    }
                    .OptionalSection{
                        color: rgba(121, 120, 130, 1);
                        background: linear-gradient(0deg, rgba(121, 120, 130, 0.10) 0%, rgba(121, 120, 130, 0.10) 100%), #FFF;
                    }
                }

                .SubtitleLine{
                    color: rgba(121, 120, 130, 1);
                    font-size: 0.9375rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px; /* 160% */
                }
            }

            .BasicInfo, .AdCutsAndThumbnailInfo, .AdditionalInfo{
                background-color: rgb(255,255,255);
                padding: 40px 30px;
                display: flex;
                flex-direction: column;
                gap: 32px;
                border-radius: 5px;
                
                .InfoLine{
                    width: 100%;
                    height: 1px;
                    background-color: rgba(237, 236, 238, 1);
                    border: 0;
                    margin: 0;
                }

                @media (max-width: 720px) {
                    padding: 32px 10px;
                }
            }
            
            .HorizontalLine{
                width: 100%;
                height: 1px;
                background-color: rgba(213, 213, 216, 1);
                border: 0;
            }
        }

        .SubmitLine{
            display: flex;
            justify-content: center;
            position: sticky;
            bottom: 0px;
            margin-top: 14px;
            padding: 10px 0;
            background-color: rgb(242, 243, 247);

            @media (max-width: 720px) {
                position: relative;
                margin-bottom: 47px;
            }

            .SubmitButton{
                display: inline-flex;
                gap: 4px;
                justify-content: center;
                align-items: center;
                background-color: rgba(61, 106, 255, 1);
                border-radius: 40px;
                padding: 12px 46px;
                cursor: pointer;
                position: relative;

                font-size: 1rem;
                color: rgb(255,255,255);

                &:active::after{
                    border-radius: 10px;
                    content: '';
                    position: absolute; top: 0; left: 0;
                    width: 100%; height: 100%;
                    background-color: rgba(255,255,255, 0.12);
                }
            }
        }

        .SubmitEditingLine{
            display: flex;
            justify-content: space-around;
            position: sticky;
            bottom: 0px;
            margin-top: 14px;
            padding: 10px 0;
            background-color: rgb(242, 243, 247);

            @media (max-width: 720px) {
                position: relative;
                margin-bottom: 47px;
            }

            .EditingButton{
                display: inline-flex;
                gap: 4px;
                justify-content: center;
                align-items: center;
                border-radius: 40px;
                padding: 12px 10%;
                cursor: pointer;
                position: relative;

                font-size: 1rem;

                &:active::after{
                    border-radius: 10px;
                    content: '';
                    position: absolute; top: 0; left: 0;
                    width: 100%; height: 100%;
                    background-color: rgba(255,255,255, 0.12);
                }
                &.Delete{
                    border: 1px solid red;
                    color: red;
                }
                &.Cancel{
                    border: 1px solid rgb(51,51,51);
                    color: rgb(51,51,51);
                }
                &.Modify{
                    background-color: rgba(61, 106, 255, 1);
                    color: rgb(255,255,255);
                }
            }
        }

        
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        pointer-events: none;
    }
}