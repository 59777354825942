
.RadioBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 16px;
    border-radius: 5px;
    
    background-color: rgba(255, 255, 255, 1);
    outline: 1px solid rgba(246, 246, 249, 1);
    outline-offset: -1px;
    
    cursor: pointer;
    
    &.Checked{
        outline: 0px;
        background-color: rgba(246, 246, 249, 1);
    }

    .RadioLabel{
        font-size: 0.938rem;
        font-style: normal;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .RadioButton{
        display: inline-block;
        appearance: none;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        border: 1px solid rgba(121, 120, 130, 1);
        background-color: rgba(246, 246, 249, 1);
        cursor: pointer;

        &:checked{
            border: 5px solid rgba(56, 105, 250, 1);
        }
        &:disabled{
            cursor: auto;
            border: 1px solid rgba(121, 120, 130, 0.3);
            background-color: rgba(246, 246, 249, 0.3);
        }
    }
}