@import './QuestionBox.scss';

.MultipleChoice {

    table {
        width: 100%;
        table-layout: fixed;
        text-align: center;
    }

    .SubjectiveButton {
        margin-bottom: 10px;
    }

    .HorizontalRadioGroup {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: stretch;
        flex-wrap: nowrap;
        flex-shrink: 1;
        text-align: center;
        vertical-align: bottom;

        .RadioCell{
            
            @media(max-width: 720px){
                width: 5%;
            }
        }
        .OptionText{
            margin-top: 4.8%;
            
            @media( max-width: 720px ){
                margin-top: 0%;
                width: 10%;
                word-break: break-all;
                text-align: center;
            }
        }

        label {
            margin: 0;

            span {
                padding: 2px;
            }
        }
    }
}