.PortfolioView {
    width: 100%;
    max-width: 400px;
    padding-top: 100px;
    margin-left: auto; margin-right: auto;
    position: relative;

    .ShareButton {
        position: absolute;
        top: - 10px;
        right: 0px;
        color: rgb(151,151,151);
        border: 1px solid rgb(151,151,151);
        padding: 7px 8px 7px 6px;
        border-radius: 50%;
        cursor: pointer;
    }

    .ProfileArea {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        div { margin-bottom: 15px }

        .Image {
            width: 110px;
            height: 110px;
            overflow: hidden;
            border-radius: 55px;

            img {
                width: 100%; height: 100%;
                object-fit: cover;
            }
        }

        .Title {
            font-size: 14px;
            font-weight: bold;
        }

        .Subtitle {
            font-size: 13px;
        }     
    }

    .ContentsArea {
        margin-top: 20px;
        word-break: keep-all;

        .ContentsCell {
            background-color: white;
            user-select: none;
            transition: 200ms;
            &:active {
                background-color: rgba(51,51,51,0.5);
            }

            box-shadow: 4px 4px 30px rgba(0,0,0,0.1);

            .Image {
                all: initial;
            }

            .Link.Icon {
                padding: 10px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: stretch;

                .Image {
                    width: 60px; height: 60px;
                    flex-shrink: 0;
                    flex-grow: 0;
                    border-radius: 20px;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;

                        &.OtherImage{
                            display: none;
                        }
                    }
                    
                }

                .Text {
                    width: 100%;
                    padding-left: 10px; padding-right: 10px; padding-top: 0; padding-bottom: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center; align-items: flex-start;
                    .TextInner {
                        min-height: 24px;
                        line-height: 24px;
                        text-align: left;
                    }
                }
            }
            .Link.Card {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                .Image {
                    position: relative;
                    height: 0;
                    width: 100%; padding-top: 100%;
                    flex-shrink: 0;
                    flex-grow: 0;
                    border-top-left-radius: 20px;
                    border-top-right-radius: 20px;
                    overflow: hidden;

                    img {
                        position: absolute;
                        top: 0; left: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;

                        &.OtherImage{
                            display: none;
                        }
                    }
                    
                }

                .Text {
                    width: calc(100% - 20px);
                    padding: 10px;
                    min-height: 24px;
                    line-height: 24px;
                    text-align: center;
                }
            }

            .Youtube {
                position: relative;
                overflow: hidden;
                width: 100%; padding-top: calc(100% / 16 * 9);
                border-radius: 20px;

                iframe {
                    position: absolute;
                    left: 0; top: 0; width: 100%; height: 100%;
                }
            }

        }
        .Gallery.Board {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            background-color: rgba(0,0,0,0);
                
            .GridCell {
                position: relative;
                width: calc(100% / 2 - 5px);
                height: 0;
                padding-bottom: calc(100% / 2 - 5px);
                border-radius: 20px;
                margin-bottom: 10px;
                overflow: hidden;

                img {
                    position: absolute;
                    top: 0; left: 0;
                    width: 100%; height: 100%;
                    object-fit: cover;

                }
            }
        
        }

        .Gallery.Slide {
            border-radius: 20px;
            
            img {
                width: 100%; height: 100%;
                object-fit: cover;
            }

            &.Oneimage {
                width: 100%; height: 100%;
                object-fit: cover;
                vertical-align: middle;
            }
        }

        .Text {
            width: 100%;
            padding-top: 20px;
            padding-bottom: 20px;
            line-height: 24px;
            font-size: 14px;

            &.LEFT {
                text-align: left;
            }
            &.CENTER {
                text-align: center;
            }
            &.RIGHT {
                text-align: right;
            }
        }
    }
}