$BASIC_SPACE: 8px;
$BASIC_RADIUS: 16px;
$SIGNATURE_COLOR: rgb(0, 176, 240);

.AnalyticsLandingPage .PostScriptArea{

    word-break: keep-all;
    padding-top: 150px;
    max-width: 824px;
    margin: auto;

    .PostScriptBody{
        max-width: 824px;
        box-sizing: border-box;
        margin: auto;

        @media(max-width: 720px){
            display: flex;
            flex-direction: column;
            justify-content: stretch;
            align-items: center;
        }

        .AverageSummary{
            display: flex;
            flex-direction: column;
            justify-content: stretch;
            align-items: stretch;
            padding-bottom: 80px;
            
            @media(max-width: 720px){
                width: calc(100% - 40px);
            }

            .Satisfaction{
                text-align: center;
                font-size: 2.5rem;
                font-weight: 700;
                padding-bottom: 20px;

                @media (max-width: 720px) {
                    font-size: 2rem;
                }
            }

            .ScoreBoard{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: flex-end;
    
                
                .Score{
                    text-align: center;
                    font-size: 2rem;
                    padding: 0px 0px 0px 10px;

                    @media (max-width: 720px) {
                        font-size: 1.5rem;
                    }
                }

                .FullScore{
                    text-align: center;
                    font-size: 1rem;
                    color: rgb(123,123,123);
                }
            }

        }

        @import 'AuthorReview.scss';

        .AdditionalMessage{
            word-break: keep-all;
            color: $SIGNATURE_COLOR;
            text-align: right;
            margin: 0px 20px;
        }

        .AdvantagesOfAnalysis{
            display: flex;
            flex-direction: column;
            justify-content: stretch;
            align-items: center;
            padding-bottom: 50px;

            .TitleText{
                padding-top: 80px;
                padding-bottom: 50px;

                
                @media(min-width: 720px){
                    display: flex;
                    flex-direction: row;
                    justify-content: stretch;
                    align-items: stretch;
                }

                .Space{
                    @media(min-width: 720px){
                        padding-right: 8px;
                    }
                }
            }

            .Advantages{
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: stretch;

                .DocumentBox{
                    max-width: 820px;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: stretch;
    
                    @media(max-width: 720px){
                        display: flex;
                        flex-direction: column;
                        justify-content: stretch;
                        align-items: center;
                    }

                    .Paper{
                        width: 220px;
                        min-height: 250px;
                        margin: 0px;
                        text-align: center;
                        padding: $BASIC_SPACE*4 $BASIC_SPACE*5 0px $BASIC_SPACE*5;
                        border-radius: $BASIC_RADIUS;

                        background-color: rgb(255, 255, 255);
                        backdrop-filter: blur(12px);
                        border: 1px solid rgba(255, 255, 255, 0.18);
                        box-shadow: 10px 10px 30px 5px rgba(12, 22, 39, 0.04), 0px 4px 4px 0px rgba(12, 22, 39, 0.02);
                        box-sizing: border-box;

                        @media(max-width: 720px){
                            margin-bottom: 40px;
                        }

                        .PaperIcons{
                            padding-bottom: 20px;
                        }
                        
                        .PaperText{
                            font-weight: bold;
                        }
                    }
                }
            }
        }
        
    }
    .TitleText{
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;
    }
    .TitleSubtext{
        font-size: 1rem;
        text-align: center;
        @media(max-width: 720px){
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
}

.PostScriptArea2 {

    .PostScriptBody {
        max-width: 824px;
        margin: auto;
        
        .ContractedWorks{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: stretch;

            .TitleBox{
                margin-bottom: 50px;

                .TitleText{
                    padding-bottom: 10px;
                }
            }
            
            .Webtoons{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                position: relative;

                max-width: 100%;
                max-height: 720px;

                .WebtoonSlide{
                    position: relative;

                    .WebtoonImg{
                        box-sizing: border-box;
                        padding:0px 20px;
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }

                    .SlideLabel{
                        position: absolute;
                        bottom: 10px;
                        left: calc(50% - 60px);
                        width: 120px;
                        text-align: center;
                        color: rgb(255,255,255);
                        border-radius: 20px;
                        text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
                    }
                }

                .LinkedSlide{
                    cursor: pointer;
                }

                .ArrowPrev{
                    position: absolute;
                    color: rgba(0,176,240, 0.5);
                    font-size: 4rem;
                    cursor: pointer;
                    transition: 0.4s;
                    left: 3%;
                    top: 50%;
                    margin-top: -6%;
                    
                    .MuiSvgIcon-root{
                        font-size: 4.5rem;
                    }
                }

                .ArrowPrev:hover{
                    color: rgba(0,176,240, 0.95);
                }

                .ArrowNext{
                    position: absolute;
                    color: rgba(0,176,240, 0.5);
                    font-size: 4rem;
                    cursor: pointer;
                    transition: 0.4s;
                    right: 3%;
                    top: 50%;
                    margin-top: -6%;
                    
                    .MuiSvgIcon-root{
                        font-size: 4.5rem;
                    }
                }

                .ArrowNext:hover{
                    color: rgba(0,176,240, 0.95);
                }
            }
        }
    }
    .TitleText{
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;
        word-break: keep-all;
    }
    .TitleSubtext{
        font-size: 1rem;
        text-align: center;
        word-break: keep-all;
        @media(max-width: 720px){
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
}
