
.TrendSearchResultArea{
    width: 100%; height: 100%; min-height: calc( 100vh - 66px );
    background-color: rgb(245, 245, 245);

    .ResultSearchContainer{
        background: linear-gradient(103.13deg, 
            rgba(166, 231, 255, 0.3) 0%, rgba(166, 231, 255, 0.7) 0.01%, 
            rgba(208, 242, 255, 0.7) 24.8%, rgba(207, 234, 255, 0.7) 52.48%, 
            rgba(206, 223, 255, 0.7) 74.05%, rgba(219, 227, 255, 0.7) 99.76%
        );
        position: relative;
        padding: 35px 0;

        .SearchBox{
            max-width: 620px;
            margin: 0 auto;

            @media(max-width: 700px) {
                width: calc(100% - 32px);
            }
        }

        .LogoBox{
            position: absolute;
            top: 50%; left: 5%;
            transform: translate(0, -50%);

            @media(max-width: 1000px) {
                display: none;
            }
        }

        .InvitingBox{
            position: absolute;
            display: flex;
            align-items: center;
            font-family: inherit;

            @media(min-width: 1001px) {
                top: 50%; right: 5%;
                transform: translate(0, -50%);
            }
            @media(max-width: 1000px) {
                bottom: 0; right: 16px; 
                transform: translate(0, 100%)
            }
        }
    }

    .StatisticsAndChartConatiner{
        max-width: 700px;
        width: 100%;
        margin: 0 auto;
        padding: 10px 0 30px;

        @media(max-width: 700px) {
            width: calc(100% - 32px);
            padding-bottom: 70px;
            padding: 30px 0 70px;
        }

        .SearchResultMessage{
            display: flex;
            align-items: center;
            padding: 24px 40px;
            background-color: rgb(233, 234, 237);
            margin: 30px 0;
            border-radius: 10px;

            @media(max-width: 700px) {
                padding: 16px;
                margin: 10px 0;
                border-radius: 10px;
            }

            .InfoIcon{
                display: flex;
                margin-right: 6px;
            }

            .TopicWord{
                font-weight: bold;
            }
        }

        .ChartContainer{
            padding: 30px 50px;
            background-color: rgb(255,255,255);
            margin: 30px 0;
            border-radius: 10px;

            @media(max-width: 700px) {
                width: calc(100% - 32px);
                padding: 16px;
                margin: 10px 0;
                border-radius: 10px;
            }
        }
    }
}