.AutoCompletableTextFieldContainer{
    position: relative;

    .CandidateList{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;

        .CandidateItem{
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            padding: 10px;

            background-color: rgb(255, 255, 255);
            
            cursor: pointer;

            &.Focused{
                background-color: rgb(233,233,233);
            }
        }
    }

    fieldset {
        &.MuiOutlinedInput-notchedOutline{
            border-color: #ecedf3 !important;
        }
    }
    .MuiInputBase-root:hover fieldset, .Mui-focused fieldset {
        border-color: rgba(61, 106, 255, 1) !important;
    }
    .MuiInputBase-root ::placeholder {
        color: rgba(121, 120, 130, 1);
        opacity: 1;
    }
}

