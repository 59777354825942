.TemplateEdit {
    width: 100%;
    padding-left: 0px;
    padding-right: 60px;

    font-size: 14px;
    line-height: 24px;
}

.ColorButtonContainer {
    width: 50px; height: 50px; border-radius: 25px;
    border: 1.5px rgba(255,255,255,0) solid;

    .ColorButton {
        width: 40px; height: 40px; border-radius: 20px;
        margin: 4px;
        border: 1px rgb(238,238,238) solid;
    }

    &.Selected {
        border: 1.5px rgb(217,217,217) solid;
    }
}