
$BASICRADIUS: 16px;
$SIGNATURE_COLOR: rgb(0,176,240);
@mixin customflex( $direction: row, $justify: stretch, $align: stretch ){
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: $align;
}


.ContestApplicationSubmitArea{
    @include customflex(column, center, space-evenly);
    max-width: 842px;
    margin: auto;
    vertical-align: top;
    
    @media (max-width: 960px) {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        align-items: center;
        padding: 0px;
    }
    @media (max-width: 701px) {
        width: 100%;
    }

    .InfomationForm{
        vertical-align: top;
        margin: 0px;
        padding: 30px;
        box-sizing: border-box;
        background-color: rgb(251,251,251);
        width: 100%;
        max-width: 820px;

        .FormGrid{
            padding: 0px;
            display: grid;
            grid-template-columns: 1fr 1fr;

            @media (min-width: 701px){
                // max-width: 100%;
            }
            @media (max-width: 700px) {
                grid-template-columns: 1fr;
            }

            .GridItem{
                width: 100%;

                .ItemBox{
                    margin-bottom: 30px;
                    width: 95%;
                    
                    @media (max-width: 700px) {
                        width: 100%;
                    }

                    .EmailReference{
                        font-size: 0.8rem;
                        margin-bottom: 3px;
                        word-break: break-all;

                        @media(max-width: 701px){
                            font-size: 0.9rem;
                        }
                    }

                    .EmailVerifying{
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        padding: 1px 1px 1px 10px;
                        border: 1px solid rgba(0, 0, 0, 0.3);
                        border-radius: 4px;
                        position: relative;
                        box-sizing: border-box;
                        height: 40px;
                        background-color: rgb(255,255,255);

                        .MuiInput-underline:before{
                            border-color: white;
                        };
                        .MuiInput-underline:after{
                            border-color: white;
                        };
                        .MuiInput-underline:hover:not(.Mui-disabled):before{
                            border-color: white;
                        };

                        &:hover{
                            border: 1px solid rgb(51,51,51);
                        };

                        &:focus-within {
                            padding: 0 0 0 9px;
                            border: 2px solid rgb(0,176,240);
                            box-sizing: border-box;
                        };

                        .VerifyingButton{
                            width: 15%;
                            border: 0px;
                            white-space: nowrap;
                            height: 40px;
                            box-sizing: border-box;
                        };
                        
                        .disabled{
                            background-color: #9e9e9e;
                        };
                    };

                    .ServiceSelector{
                        border-radius: 4px;
                        .MuiInputBase-input{
                            padding: 10.5px 14px;
                            border-radius: 0px;
                        };
                    };

                    .VerifiedOtp{
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        padding: 1px 1px 1px 10px;
                        border: 1px solid rgba(0, 0, 0, 0.3);
                        border-radius: 4px;
                        position: relative;
                        box-sizing: border-box;
                        height: 40px;
                        background-color: rgb(255,255,255);

                        .MuiInput-underline:before{
                            border-color: white;
                        }
                        .MuiInput-underline:after{
                            border-color: white;
                        }
                        .MuiInput-underline:hover:not(.Mui-disabled):before{
                            border-color: white;
                        };

                        &:hover{
                            border: 1px solid rgb(51,51,51);
                        };

                        &:focus-within {
                            padding: 0 0 0 9px;
                            border: 2px solid rgb(0,176,240);
                            box-sizing: border-box;
                        };

                        .VerifiedOtpButton{
                            width: 15%;
                            border: 0px;
                            white-space: nowrap;
                            height: 40px;
                            box-sizing: border-box;
                        };
                    };

                    .UnfilledField{
                        border-color: rgb(219,0,0);
                    };

                    .UnfilledField:hover{
                        border-color: rgb(219,0,0);
                    };

                    .UnfilledField:focus-within{
                        border-color: rgb(219,0,0);
                    };
                        
                    .Tags{
                        width: 100%;
                        font-size: 14px;
                        color: #b0b0b0;
                        box-sizing: border-box;
                        background-color: rgb(255,255,255);
                    };
                };

            };

            .GridItem:nth-child(even) {

                @media (min-width: 701px) {
                    display: flex;
                    justify-content: flex-end;
                };
            };
        };

        .TitleThumbnail{

            .ThumbnailBox{
                padding: 10px;
                border-radius: 4px;
                border: 1px solid rgba(0,0,0,0.23);
                position: relative;
                margin-top: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                background-color: rgb(255,255,255);

                &:hover{
                    border: 1px solid black;
                };

                .BoxInner{
                    pointer-events: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                };
            };

            .HelpText{
                padding: 0 10px;
                font-size: 0.9rem;
                color: rgb(117, 117, 117);
            };

            .UnfilledField{
                border-color: rgb(219,0,0);
            };

            .UnfilledField:hover{
                border-color: rgb(219,0,0);
            };
        };


        .UnfilledField{
            border-color: rgb(219,0,0);

            fieldset{
                border-color: rgb(219,0,0);
            };

            .ChallengeUrlField{
                fieldset{
                    border-color: rgba(0,0,0,0.23);
                };
            };
        };


        .ManuscriptsInfo{
            .Manuscripts{
                @include customflex(column, space-evenly, center);
                margin-bottom: 10px;
                margin-top: 20px;
                font-size: 1rem;
    
                .ManuscriptLabels{
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    width: 100%;
                    padding-top: 10px;
                    padding-bottom: 5px;
    
                    .Button{
                        padding: 3px 8px;
                        background-color: rgb(241,241,241);
                        color: rgb(51,51,51);
                        font-size: 0.8rem;
                    }
                };
    
                .ManuscriptUploadArea{
                    width: 100%;
    
                    .ManuscriptBox{
                        padding: 10px 0;
                        border-radius: 4px;
                        border: 1px solid rgba(0,0,0,0.23);
                        position: relative;
                        cursor: pointer;
                        width: 100%;
                        margin-bottom: 5px;
                        background-color: rgb(255,255,255);
                        
                        .ScriptBoxInner{
                            padding: 0 10px;
                            display: flex;
                            justify-content: stretch;
                            align-items: center;
                            flex-direction: column;    
                            
                            .Rows{
                                width: 100%;
                                height: 100%;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
    
                                .RowBox{
                                    display: flex;
    
                                    .Files{
                                        display: flex;
                                        flex-direction: column;
                                        width: 85%;
                                        justify-content: center;
    
                                        .ProductRow{
                                            width: 100%;
                                            display: flex;
                                            justify-content: space-between;
                        
                                            .NameCell{
                                                display: flex;
                                                width: 65%;
                        
                                                .FileName{
                                                    white-space: nowrap;
                                                    text-overflow: ellipsis;
    
                                                };
                                            };
                                        };
                                    };
    
                                    .PreviewButton{
                                        display: flex;
                                        justify-content: flex-end;
                                        align-items: center;
                                        flex: 1;
    
                                        Button{
                                            color: rgb(0,176,240);
                                            cursor: pointer;
                                        };
                                    };
                                };
    
                                .AddImage{
                                    text-align: center;
                                    margin-top: 10px;
                                };
                            };
                        }
        
                        .Center{
                            justify-content: center;
                        }
                    }
                }
    
                .UnfilledManuscript{
                    .ManuscriptBox{
                        border-color: red;
                    }
                }
            }
    
            .ChallengeLabel{
                margin-bottom: 10px;
            }
    
            .ChallengeWebtoonArea{
    
                @include customflex(row, center);
                width: 100%;
                margin-bottom: 15px;
                max-height: 40px;
    
                .ChallengeAddressField{
                    width: 70%;
                }
    
                .ColoredButton{
                    width: 15%;
                    white-space: nowrap;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    border:0;
                }
                
                .UnfilledChallenge{
                    fieldset{
                        border-color: red;
                    };
                };
            }
    
            .ExtraTitleInfomation{
                margin-left: -5px;
            }
    
            .UnfilledField{
                border: 1px solid rgb(219,0,0);
                border-radius: 4px;
            };
        };
    
        .AdsCutUl{
            .AdsCutli{
                margin-bottom: 25px;
    
                .Labels{
                    margin-bottom: 10px;
                };
    
                .RequestAndExample{
                    @include customflex(row, space-between, center);
                    margin-top: 5px;
    
                    .AdsCutRequest{
                        @include customflex(row, stretch, center);
                        font-size: 0.9rem;
    
                        .AdsCutRequestCheckbox{
                            padding: 0px;
                            margin-right: 3px;
                        };
                    };
    
                    .Explanation{
                        @include customflex(row, stretch, center);
                        color: rgb(0,176,240);
                        font-size: 0.9rem;
                        cursor: pointer;
    
                        .IconAndText{
                            @include customflex(row, stretch, center);
                        };
                    };
                };
            };
    
            .UnfilledField{
                border: 0px;
                .DragAndDropContainer{
                    border-color: rgb(219,0,0);
                };
            };
        };
    
        .Labels{
            font-weight: bold;
        };
        .redAsterisk{
            color: red;
        }
        .SubLabels{
            font-size: 0.875rem;
        }
    
        .AgreementsArea{
            @include customflex(row, center, stretch);
    
            .FlexBox{
                @include customflex(row, stretch, center);
                font-size: 14px;
                font-weight: 500;
                color: #9e9e9e;
    
                .CheckBox{
                    margin-right: 5px;
                }
    
                .CheckLabels{
                    color: rgb(0,0,0);
                    font-size: 0.9rem;
                    word-break: keep-all;
                    padding: 2px 0px;
                }
    
                .PopOver{
                    @include customflex(row, center, center);
                    border-radius: 15px;
                    background-color: $SIGNATURE_COLOR;
                    height: 15px;
                    width: 15px;
                    color: $SIGNATURE_COLOR;
                    cursor: pointer;
                }
            }
    
            .ConsentArea{
            
                .CheckRow{
                    @include customflex(row, stretch, flex-start);
                    font-size: 0.8rem;
    
                    .CheckPoints{
                        padding: 0px;
                        margin-right: 3px;
                    }
    
                    .Plane{
                        pointer-events: none;
                    }
                }
            }
        }
    
        .PopupArea{
            @include customflex(column, center, center);
            margin-top: 30px;
            font-size: 1rem;
    
            .SubmitButton{
                padding: 6px 20px;
                font-size: 16px;
                font-weight: 500;
                border: 0;
                width: 100%;
                margin-bottom: 20px;
            }
    
            .disabled{
                background-color: #9e9e9e;
            }

            .AppliedEmailVerifying{
                
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                padding: 1px 1px 1px 10px;
                border: 1px solid rgba(0, 0, 0, 0.3);
                border-radius: 4px;
                position: relative;
                box-sizing: border-box;
                height: 40px;
                width: 100%;
                margin-top: 5px;
                background-color: rgb(255,255,255);

                .MuiInput-underline:before{
                    border-color: white;
                };
                .MuiInput-underline:after{
                    border-color: white;
                };
                .MuiInput-underline:hover:not(.Mui-disabled):before{
                    border-color: white;
                };

                &:hover{
                    border: 1px solid rgb(51,51,51);
                };

                &:focus-within {
                    padding: 0 0 0 9px;
                    border: 2px solid rgb(0,176,240);
                    box-sizing: border-box;
                };

                .VerifyingButton{
                    width: 15%;
                    border: 0px;
                    white-space: nowrap;
                    height: 40px;
                    box-sizing: border-box;
                };
                
                .disabled{
                    background-color: #9e9e9e;
                };
            };
            
            .VerifiedOtp{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                padding: 1px 1px 1px 10px;
                border: 1px solid rgba(0, 0, 0, 0.3);
                border-radius: 4px;
                position: relative;
                box-sizing: border-box;
                height: 40px;
                margin-top: 5px;
                background-color: rgb(255,255,255);

                .MuiInput-underline:before{
                    border-color: white;
                }
                .MuiInput-underline:after{
                    border-color: white;
                }
                .MuiInput-underline:hover:not(.Mui-disabled):before{
                    border-color: white;
                };

                &:hover{
                    border: 1px solid rgb(51,51,51);
                };

                &:focus-within {
                    padding: 0 0 0 9px;
                    border: 2px solid rgb(0,176,240);
                    box-sizing: border-box;
                };

                .VerifiedOtpButton{
                    width: 15%;
                    border: 0px;
                    white-space: nowrap;
                    height: 40px;
                    box-sizing: border-box;
                };
            };

            .FloatingBar{
                position: fixed;
                z-index: 9;
                bottom: 88px;
                right: 100px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                pointer-events: none;

                .AddNewTitleButton{
                    position: relative;
                    cursor: pointer;
                    pointer-events: all;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: center;

                    width: 63px; height: 63px;
                    border-radius: 50%;
                    background-color: rgb(0,176,240);
                    padding: 5px;
                    box-shadow: 4px 4px 20px 5px rgba(1,1,1,0.1);
                    color: rgb(255,255,255);
                    box-sizing: border-box;
                    font-size: 0.9rem;

                    @media (max-width: 700px) {
                        display: none;
                    };
                };
            };
        };
    };
};