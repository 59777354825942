
.SearchBarContainer{
    border: 2px solid rgb(0,176,240);
    border-radius: 40px;
    background-color: white;

    display: grid;
    grid-template-columns: 1fr auto;
    padding: 0 10px 0 20px;
    position: relative;

    .MuiInput-underline:before{
        border-color: transparent;
    }
    .MuiInput-underline:after{
        border-color: transparent;
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before{
        border-color: transparent;
    }

    input::placeholder{
        color: rgb(163, 163, 163);
        font-family: inherit;
    }

    .SearchIconBox{
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgb(0,176,240); 
        
        .SearchIcon{
            cursor: pointer;
        }  
    }

    .KeywordList{
        position: absolute;
        top: 0; left: 0;
        transform: translate(0, 54px);
        width: calc( 100% - 40px );
        border-radius: 4px;
        margin: 0 20px;
        padding: 8px 0;
        background-color: white;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
        z-index: 1;

        .KeywordRow{
            padding: 12px 0 12px 32px;
            display: flex;
            align-items: center;
            background-color: unset;
            cursor: pointer;

            .SearchIconBox{
                display: flex;
                align-items: center;
                color: rgb(163, 163, 163);
            }

            .Keyword{
                padding-left: 10px;
            }
        }

        .KeywordRow.SelectedRow{
            background-color: #f5f5f5;
            color: rgb(0,176,240);

            .SearchIconBox{
                color: rgb(0,176,240);
            }
        }
    }

    .KeywordList.Fullsize{
        height: 200px;
        overflow-y: scroll;
    }
}