

.ShareArea{

    .PCShare{
        margin: 0;
        padding: 0;

        @media(min-width: 701px) {
            display: flex;
            align-items: center;
        };

        @media(max-width: 700px) {
            display: none;
        };
    };

    .MobileShare{
        margin: 0;
        padding: 0;
        
        @media(min-width: 701px) {
            display: none;
        };

        @media(max-width: 700px) {
            display: flex;
            align-items: center;
        };
    };
};