$SIGNATURE_COLOR: rgb(0,176,240);

.ProfileEdit {
    
    @media (max-width: 700px) {
        width: 100%;
        min-height: calc(100vh - 45px);
        overflow-y: hidden;
        padding-bottom: 60px;
    }

    @media (min-width: 701px) {
        width: 50%;
        max-width: 400px;
        background-color: white;
        box-shadow: 4px 4px 20px 5px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        margin-left:20px; margin-right:20px;
        margin-top:0;
        padding-top: 40px;
        margin-bottom: 80px;
        margin-left: auto; margin-right: auto;
    }

    flex-shrink: 0;
    font-size: 14px;
    padding-top:30px;

    .Guide, .Edit, .Preview, .View {
        position: relative;
        width: calc(100% - 60px);
        margin-left: 30px; margin-right: 30px;
        height: 100%;
        padding-bottom: 100px;
    }

    &.PortfolioExists {
        .Guide {
            display: none;
        }
    }

    &.NoPortfolioExists {
        .Guide {
            //
        }
        .View {
            display: none;
        }
    }

    &.EditingMode {
        .Guide {
            display: none;
        }
        .Edit {
            //
        }
        .View {
            display: none;
        }
    }

    &.NoEditingMode {
        .Edit {
            display: none;
        }
    }

    &.PreviewMode {
        .Guide {
            display: none;
        }
        .Preview {
            //
        }
        .Edit {
            display: none;
        }
        .View {
            display: none;
        }
    }

    &.NoPreviewMode {
        .Preview {
            display: none;
        }
    }


    .ProfileImage {
        position: relative;
        width: 110px;
        margin-left: calc(50% - 55px);
        margin-right: calc(50% - 55px);
        margin-bottom: 30px;

        .AddButton {
            position: absolute;
            bottom: 0; right: 0;
            padding: 0;
            background-color: rgba(51,51,51,1);
            color: rgba(255,255,255,1)
        }
    }

    .Image {
        width: 110px; height: 110px;
        border-radius: 60px;
        overflow: hidden;
        &.Noimage {
            background-color: lightgray;
        }

        input[type="file"] {
            display: none;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .AddContent {
        user-select: none;
        transition: 200ms;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: calc(100% - 2px);
        margin-bottom: 20px;
        font-weight: bolder;

        border-radius: 15px;
        border: 1px $SIGNATURE_COLOR solid;

        color: $SIGNATURE_COLOR;

        &:active {
            background-color: rgba(196,196,196, 1);
        }
    }

    .FlexLeftTop {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;

        .RectButton {
            position: relative;
            flex-shrink: 0;
            width: calc(25% - 10px - 20px / 4);
            padding-bottom: 20%;
            margin: 5px;

            border: 1px rgba(196,196,196, 1) solid;
            border-radius: 15px;

            user-select: none;
            transition: 200ms;

            &:active {
                background-color: rgba(196,196,196, 1);
            }

            .RectButtonChild {
                position: absolute;
                top:0; left:0;
                width: 100%;
                height: 100%;

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
    }
    
    .CardTypeSelector {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: 60px;

        .Selection {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            border-radius: 20px;
            width: calc(50% - 20px - 2px);
            height: 30px;

            user-select: none;
            transition: 200ms;

            border: 1px rgba(196,196,196,1) solid;
            color:rgba(196,196,196, 1);

            &:active {
                background-color: rgba(51,51,51,0.5);
            }

            font-weight: bold;
            font-size: 14px;

            &.Selected {
                background-color: rgba(51,51,51,1);

                color: white;
            }
        }
    }

    .FloatingButtonBox {
        position: fixed;
        padding-top: 20px; padding-bottom: 20px;
        bottom: 60px;
        height: 40px;
        width: 100vw;
        transition: 200ms;

        background: linear-gradient(0deg, rgba(0,0,0,0.1), transparent);
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;

        @media (min-width: 701px) {
            display: none;
        }

        .OutlineButton, .FilledButton {
            user-select: none;
            transition: 200ms;
            border-radius: 20px;
            border: 1px $SIGNATURE_COLOR solid;
            
            height: 40px;
            width: 150px;

            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
        }

        .OutlineButton {
            background-color: white;
            color: $SIGNATURE_COLOR;
            
            &:active {
                background-color: rgba(196,196,196, 1);
            }
        }

        .FilledButton {
            background-color: $SIGNATURE_COLOR;
            color: white;

            
            &:active {
                background-color: rgba(0,88,120, 1);
            }
        }
    }

    .CreateProfileButton {
        user-select: none;
        transition: 200ms;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 240px;
        margin-left:auto; margin-right: auto;
        cursor: pointer;

        border-radius: 20px;
        border: 1px $SIGNATURE_COLOR solid;

        color: $SIGNATURE_COLOR;

        &:active {
            background-color: rgba(196,196,196, 1);
        }
    }

    .Explanation {
        margin-top: 30px;
        ul {
            padding-left: 20px;
            li {
                margin-bottom: 20px;
            }
        }
    }
}