$MARGINUNIT: 16px;

.Applying2024UniversityMainArea{
    display: flex;
    justify-content: center;
    background-color: rgb(242, 243, 247);

    .Applying2024UniversityInner{
        max-width: 720px;
        width: 100%;
        padding: 0px 0 60px;
        box-sizing: border-box;
        user-select: none;
        min-height: calc(100vh - 60px);

        @media (max-width: 720px) {
            max-width: 430px;
            width: 100%;
            min-height: 0;

            padding: 0px 16px 40px 20px;
        }

        .ApplyingAnalysisForm{
            display: flex;
            flex-direction: column;
            gap: 24px;
            position: relative;

            padding-top: 20px;

            .Section{
                background-color: rgb(255,255,255);
                padding: 40px 30px;
                display: flex;
                flex-direction: column;
                gap: 32px;
                border-radius: 4px;
                box-shadow: 10px 10px 30px 0px rgba(12, 22, 39, 0.04);
                
                .SectionHeader{
                    display: flex;
                    flex-direction: column;
                    gap: 12px;
                }
                .ParticipantHeader{
                    display: flex;
                    justify-content: space-between;
                    border-radius: 5px;
                }
                .ParticipantInfo{
                    display: flex;
                    flex-direction: column;
                    gap: 32px;

                    .WithLabel {
                        .Label {
                            line-height: 48px;
                        }
                    }
                }

                .SectionTitle{
                    color: rgb(23,23,23);
                    font-size: 1.25rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 28px; /* 140% */
                    letter-spacing: 0.2px;
                }
                .SectionSubtitle{
                    color:  rgba(121, 120, 130, 1);
                    font-size: 0.9375rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px; /* 160% */
                    white-space: pre-line;

                    .ClickableWord{
                        color: rgba(61, 106, 255, 1);
                        font-weight: 700;
                        text-decoration-line: underline;
                    }
                    .ImpressedWord{
                        color: rgba(121, 120, 130, 1);
                        font-weight: 700;
                    }
                }
                .RequiredSectionLabel{
                    padding: 6px 10px;
                    background-color: rgba(137, 85, 246, 0.1);
                    border-radius: 5px;
                    color: #8955F6;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 14px; /* 116.667% */
                    letter-spacing: 0.12px
                }
                .WebtoonInfo{
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                }
                .Divider{
                    margin: 0; padding:0;
                    width: 100%;
                    border: 1px solid rgba(213, 213, 216, 1);
                }
                .DisclaimerBody{
                    display: flex;
                    flex-direction: column;
                    gap: 24px;
                    .SurviceAllAgreementsBox{
                        display: flex;
                        justify-content: flex-end;
        
                        .TermsOfService{
                            font-size: 0.875rem;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px; /* 142.857% */
                            letter-spacing: 0.14px;
                            color: rgba(25, 77, 229, 1);
                            text-decoration: none;
                        }
                    }
                    .AgreementBox{
                        display: flex;
                        flex-direction: column;
                        gap: 16px;
        
                        .AgreementRow{
                            display: grid;
                            grid-template-columns: auto 1fr;
                            align-items: center;
                            gap: 4px;
                        
                            .MandatoryNotice{
                                color: rgba(121,120,130,1);
                                font-size: 0.875rem;
                                font-size: normal;
                                font-weight: 600;
                                line-height: 1.25rem;
                                letter-spacing: 0.00875rem;
                            }
                        }
                    }
                }
                .WithStateAndLabel{
                    display: grid;
                    grid-template-columns: auto 1fr;
                    
                    box-sizing: border-box;
                    width: 100%;
                    border-top: 1px solid rgba(234, 236, 240, 1);
                    border-bottom: 1px solid rgba(234, 236, 240, 1);

                    .Label{
                        width: 156px;
                        background-color: rgba(252, 252, 253, 1);

                        color: rgba(25, 24, 27, 1);
                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 24px;
                        letter-spacing: 0.16px;
                        padding: 12px 8px;


                        @media(max-width: 720px){
                            font-size: 0.9375rem;
                            font-weight: 400;
                            letter-spacing: 0.15px;

                            padding-left: 4px;
                            padding-bottom: 4px;
                        }

                        .LabelInner{
                            display: flex;
                            align-items: center;
                        }
                    }
                    .Content{
                        color: rgba(60, 70, 81, 1);
                        font-size: 0.875rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px; /* 160% */
                        letter-spacing: 0.15px;
                        padding: 12px 8px;
                        
                        &.Disabled{
                            color: rgba(138, 138, 138, 1);
                            background-color: rgba(138, 138, 138, 0.25);
                            user-select: none;
                        }
                    }

                    @media(max-width: 720px){
                        display: flex;
                        flex-direction: column;
                        gap: 4px;

                        padding-left: 0px;
                        padding-right: 0px;
                    }
                }
                
                .SchoolInputBox{
                    display: flex;
                    flex-direction: column;
                    gap: 8px;

                    .CheckOutWithGuide{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .TextCheckBox {
                            padding: 0 8px;
                            height: 24px;
                            background-color: unset !important;
                            border: none;
                            span {
                                margin: 0 4px;
                            }
                        }

                        .Required{
                            color: rgba(250, 149, 56, 1);

                            line-height: 16px;
                            font-size: 0.8125rem;
                            font-weight: 500;
                        }
                    }
                }
            }
    
            .NoticeSection{
                background-color: rgba(224, 226, 235, 1);
                display: flex;
                padding: 12px 16px;
                align-items: center;
                gap: 16px;
                align-self: stretch;
                border-radius: 4px;
            }
    
            .ButtonSection{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding-top: $MARGINUNIT ;
                margin-bottom: $MARGINUNIT * 4;
        
                .SectionButton{
                    border: 1px solid rgb(0,176,240);
                    display: flex;
                    justify-content: stretch;
                    align-items: center;
                    margin-bottom: $MARGINUNIT * 1.5;
                    width: 200px;
                    height: 30px;
                };
            };
        
            .ApplyingAnalysisFooter{
                display: flex;
                justify-content: center ;

                @media(max-width: 720px) {
                    width: calc(100% - 64px);
                }
                
                .ApplyingAnalysisButton{
                    display: flex;
                    min-width: 120px;
                    max-width: 200px;
                    padding: 12px 0;
                    justify-content: center;
                    align-items: center;
                    flex: 1 0 0;
                    align-self: stretch;
                    border-radius: 10px;
                    background: #3D6AFF;

                    color: var(--Withe, rgba(255,255,255,1));
                    text-align: center;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px; /* 150% */
                    letter-spacing: 0.16px;
                }

                .ApplyingAnalysisButton.Mui-disabled{
                    color: rgba(220, 220, 220, 1);
                    background: linear-gradient(0deg, rgba(220, 220, 220, 0.32) 0%, rgba(220, 220, 220, 0.32) 100%), #3D6AFF;
                }
            };
        };
    };
};