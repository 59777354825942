

.TrendSearchArea {
    width: 100%; height: 100%; min-height: calc( 100vh - 66px );
    
    @media(max-width: 700px){
        padding-bottom: 70px;
    }

    .TrendSearchContainer {
        background: linear-gradient(103.13deg, 
            rgba(166, 231, 255, 0.3) 0%, rgba(166, 231, 255, 0.7) 0.01%, 
            rgba(208, 242, 255, 0.7) 24.8%, rgba(207, 234, 255, 0.7) 52.48%, 
            rgba(206, 223, 255, 0.7) 74.05%, rgba(219, 227, 255, 0.7) 99.76%
        );

        .ContainerInner{
            max-width: 650px;
            width: 100%;
            margin: 0 auto;
            padding: 60px 0 0;

            .ResultLogoBox{
                display: flex;
                justify-content: center;
                margin-bottom: 20px;
            }

            .Title{
                font-size: 2.25rem;
                text-align: center;
                font-weight: bold;
                margin-bottom: 30px;
            }

            .SearchBarBox{
                padding: 0 15px;
                margin-bottom: 50px;

                @media(max-width: 700px) {
                    margin-bottom: 16px;
                }
            }

            .Tabs{
                display: flex;
                
                .Tab{
                    cursor: pointer;
                    padding: 30px 0;
                    text-align: center;

                    @media(max-width: 700px) {
                        padding: 20px 0;
                    }
                }

                .Tab.Selected{
                    font-weight: bold;

                    .TabText{
                        height: 100%;
                        display: table;
                        border-bottom: 2px solid black;
                        border-width: 80%;
                    }
                }
                .Tab.Deselected{
                    font-weight: 400;
                    color: rgb(163, 163, 163);
                }
            }
        }

        .InvitingBox{
            position: absolute;
            display: flex;
            align-items: center;
            font-family: inherit;

            @media(min-width: 1001px) {
                top: 36px; right: 57px;
            }
            @media(max-width: 1000px) {
                top: 16px; right: 16px;
            }
        }
    }

    .RecommendedTopicContainer {
        
        .ContainerInner{
            max-width: 700px;
            width: 100%;
            margin: 0 auto;

            display: flex;
        }
    }
}