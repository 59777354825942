.WTInteractionStyledDialog{
    
    .MuiDialog-container{
        .MuiPaper-root{
            border-radius: 16px;
            
            box-sizing: border-box;
            margin: 10px;
            padding: 40px 32px;
            max-width: 480px;
            width: 100%;
            
            display: flex;
            flex-direction: column;
            gap: 32px;
            
            @media (max-width: 719px){
                padding: 32px 16px;
            }
            
            .MuiTypography-root{
                display: flex;
                flex-direction: column;
                gap: 16px;
        
                .Title{
                    font-size: 1.5rem;
                    white-space: pre-line;
                    color: rgba(25, 24, 27, 1);
                    text-align: center;
                    font-family: Pretendard;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 36px; /* 150% */
                    letter-spacing: 0.24px;
                }
                .SubTitle{
                    color: rgba(121, 120, 130, 1);
                    font-size: 0.875rem;
                    font-weight: 500;
                    line-height: 20px;
                    letter-spacing: 0.14px;
                    font-family: pretendard;
                    white-space: pre-line;
                    text-align: center;
                    font-style: normal;
                }
            }
            .MuiDialogActions-root{
                display: flex;
                flex-direction: column;
        
                Button{
                    padding: 12px 46px;
                    width: 100%;
                }
            }
            .Footer-root{
                color: rgba(71, 71, 71, 1);
                font-family: Pretendard;
                font-size: 0.75rem;
                text-align: center;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 166.667% */
                letter-spacing: 0.12px;
            }
        }
    }
}