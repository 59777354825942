

.MultiLineChartArea{
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    position: relative;

    .LegendBox{
        width: 100%;

        .visx-legend-item{
            display: flex;
            align-items: center;
            position: relative;

            .visx-legend-shape{
                width: 15px; height: 15px;
                @media(max-width: 700px) {
                    width: 10px;
                    height: 10px;
                }

                .visx-legend-shape-square{
                    width: 15px; height: 15px;

                    @media(max-width: 700px) {
                        width: 10px ; 
                        height: 10px ; 
                    }
                }
            }

            .visx-legend-label{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100px;
                font-size: 1rem;
                margin-left: 2px;
                margin-bottom: 2px;

                @media(max-width: 700px) {
                    font-size: 0.75rem;
                    max-width: 60px;
                }
            }
        }
    }
}