
.PlatformSerializationTrendArea{
    width: calc(100% - 100px);
    height: 310px;
    padding: 30px 50px;
    background-color: rgb(255,255,255);
    margin: 30px 0;
    border-radius: 10px;

    @media(max-width: 700px) {
        width: calc(100% - 32px);
        padding: 16px;
        margin: 10px 0;
    }

    .Title{
        font-size: 1.25rem;
        font-weight: bold;
        margin-bottom: 15px;

        .SearchText{
            color: rgb(0,176,240);
        };
    };

    .ChartBox{
        width: 100%;
        height: 100%;
        max-height: 220px;
    };
};