
@import url('https://fonts.googleapis.com/css?family=Noto+Emoji');

.CommentBox{
 
    .CommentStateLine{
        display: flex;
        justify-content: space-between;
        font-size: 1rem;
        margin-top: 16px;

        .AuthorAndTitle{

            .Title{
                color: rgb(255, 255, 255);
                border-radius: 2px;
                font-size: 0.9rem;
                padding: 2px 18px;
                margin-right: 11px;
                font-weight: 500;
            }
    
            .Author{
                font-weight: bold;
            }
        }

        .CreatedDate{
            font-size: 0.9rem;
            color: rgb(196,196,196);
        }
    }

    .ContentLine{
        font-size: 0.9rem;
        margin: 10px 0 10px 0;
    };

    .PreviewContentLine{
        font-size: 0.9rem;
        margin: 10px 0 10px 0;
        overflow: hidden;
        text-overflow: ellipsis;   
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .ReactionButtonLine{
        margin-bottom: 12px;
        font-size: 0.9rem;
        position: relative;

        .Reaction{
            color: rgb(117,117,117);
            font-weight: 500;
            cursor: pointer;

            .ReactionIcon{
                font-family: Noto Emoji;
                font-size: 0.9rem;
                color: rgb(117,117,117);
            }
        };

        .FocusedReaction{
            color: rgb(0,176,240);
        };

        .DeleteButton{
            position: absolute;
            right: 0;
            bottom: 0;
            font-size: 0.75rem;
            color: rgb(255,0,0);
            cursor: pointer;
        };

        .CommentReportButton{
            position: absolute;
            right: 0;
            bottom: 0;
            font-size: 0.75rem;
            cursor: pointer;
        }
    };

    .CommentEndLine{
        border-bottom: 1px solid rgb(241,241,241);
    };
};