$MARGINUNIT: 16px;
$FULLSCREENMAXWIDTH: 600px;

.UserPageArea{
    max-width: $FULLSCREENMAXWIDTH;
    margin: 0 auto;
    padding-bottom: 60px;

    .BodyContainer{
        padding: 110px $MARGINUNIT 30px;
        width: calc(100% - 32px);
        max-width: $FULLSCREENMAXWIDTH;

        @media (max-width: 700px){
            padding-top: 50px;
        };

        .UserRow{
            display: flex;

            .AttributeCell{
                display: flex;
                align-items: flex-end;
                background-color: rgb(250,250,250);
                padding: 16px 0 16px 16px;
                font-size: 0.9rem;
                width: 30%;
                color: rgb(117, 117, 117);

                .AttributeCell{
                    background: 'red';

                    .MuiTooltip-tooltip{
                        background-color: rgb(0,176,240);
                    }
                }
            }
    
            .ValueCell{
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 70%;
                padding: 0 16px;
                font-size: 0.9rem;
                word-break: break-all;

                .ModifyButton{
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    .Icon{
                        font-size: 1.3rem;
                    }
                };
            };
        };

        .ButtonBox{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .MoveToInquiryButton{
                padding: 8px 24px;
                margin-top: 30px;
                border: 1px solid rgb(196,196,196);          
            };

            .UserPageButtonGroup{
                display: flex;
                justify-content: space-evenly;
                width: calc(100% - 32px);

                .UserPageButton{
                    background-color: white;
                    color: black;
                    padding: 8px 24px;
                }
            }

            .SaveButton{
                width: calc(100% - 64px);
                padding: 10px 16px;
            }
        };

        .InnerInputBox{
            padding: 0 $MARGINUNIT;

            .BoxLabel{
                color: rgb(150,150,150);
                font-size: 0.75rem;
            }

            .GenderGroup{
                margin-top: $MARGINUNIT;

                .FocusedButton{
                    background-color: rgb(0,176,240);
                    color: white;
                }
            }

            .InformSpace{
                margin-top: 40px;
                display: flex;
                flex-direction: column;

                .InformTitle{
                    margin-bottom: 20px;
                    font-weight: bold;
                }
                .InformText{
                    white-space: pre-line;
                    font-size: 0.9rem;
                    margin-bottom: 25px;
                }
            }
        }

        .ImgCenterBox{
            width: 100%;
            display: flex;
            justify-content: center;
            margin: 80px 0 30px 0;
        }

        .TextCenterBox{
            text-align: center;

            .ProcessStateText{
                margin-bottom: 12px;
                font-weight: bold;
            }

            .UserDataInformText{
                margin-bottom: 12px;
                font-size: 0.9rem;
            }

            .GreetingText{
                white-space: pre-line;
                font-size: 0.9rem;
            }
        }
    }

    .Footer {
        bottom: $MARGINUNIT * 4;
        padding: 16px;
        width: 100%;
        max-width: 600px;
        box-sizing: border-box;

        .SaveButton{
            padding: 10px 0;
        }
    }
}