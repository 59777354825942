.Univ2023Mypage{
    box-sizing: border-box;
    padding-bottom: 88px;
    min-height: calc(100vh - 127px);
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: rgba(242, 243, 247, 1);

    &.End.Empty{
        justify-content: center;

        .MypageHeader{
            display: none;
        }
    }
    
    .MypageHeader{
        padding-top: 20px;
        font-size: 1.625rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .MyTitleList{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;

        box-sizing: border-box;
        padding: 32px 32px 0px 32px;
        max-width: 720px;
        width: 100%;
        
        @media (max-width: 430px){
            padding: 20px 16px 0px 16px;
        }

        > span{
            color: rgba(60, 70, 81, 1);
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 600;
            line-height: 32px; /* 133.333% */
        }

        .TitleCard{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            gap: 16px;

            box-sizing: border-box;
            border-radius: 16px;
            padding: 16px;
            width: 100%;
            background-color: rgba(255, 255, 255, 1);

            cursor: pointer;

            @media (max-width: 430px){
                max-width: 320px;
                flex-direction: column;
                align-items: center;
                text-align: center;
            }

            > img{
                border-radius: 8px;
                border: 1px solid rgba(237, 236, 238, 1);
            }
        
            .TitleCardInfo{
                display: flex;
                flex-direction: column;
                gap: 12px;

                padding: 0px 10px;

                .TitleCardStatus{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 6px;

                    color: rgba(60, 70, 81, 1);
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px; /* 142.857% */

                    @media (max-width: 430px){
                        justify-content: center;
                    }
    
                    .StatusCircle{
                        width: 12px;
                        height: 12px;
                        border-radius: 50px;
                        
                        &.UnderReview{
                            background-color: rgba(37, 89, 244, 1);
                        }

                        &.Complete{
                            background-color: rgba(61, 192, 97, 1);
                        }
    
                        &.Rejected{
                            background-color: rgba(235, 81, 71, 1);
                        }
                    }
                }

                .TitleCardDetail{
                    display: flex;
                    flex-direction: column;

                    padding-bottom: 12px;

                    color: rgba(60, 70, 81, 1);
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24px; /* 150% */
                    letter-spacing: 0.16px;

                    :last-child{
                        font-weight: 400;
                    }
                }

                .TitleCardDate{
                    color: rgba(158, 157, 164, 1);
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px; /* 142.857% */
                }
            }
        }

        .AddMoreTitle{
            border-radius: 21px;
            width: 100%;
            height: 156px;

            display: flex;
            justify-content: center;
            align-items: center;

            background-color: rgba(224, 226, 235, 1);
        }
    }

    .OtherTitles{
        padding: 20px 0px;
        position: fixed;
        bottom: 0;
        background-color: rgba(242, 243, 247, 1);

        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;

        @media (max-width: 430px){
            position: static;
        }

        .OtherTitlesButton{
            box-sizing: border-box;
            border: 1px solid rgba(60, 70, 81, 1);
            border-radius: 10px;
            width: 200px;
            height: 48px;
    
            display: flex;
            justify-content: center;
            align-items: center;
    
            color: rgba(60, 70, 81, 1);
            font-family: inherit;
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 150% */
            letter-spacing: 0.16px;
        }

        &.End{    
            &.Empty{
                position: static;
            }

            .OtherTitlesButton{
                background-color: rgba(137, 85, 246, 1);
                color: rgba(255, 255, 255, 1);
            }
        }
    }
}
