.Univ2023LandingPage{
    min-height: 100vh;
    background-color: rgba(242, 243, 247, 1);

    .Univ2023LandingContent{
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 56px;
        padding-top: 30px;

        @media (max-width: 1080px){
            flex-direction: column;
            align-items: center;
            gap: 0;

            padding-bottom: 60px;
        }

        .ContestContent{
            max-width: 680px;
            box-sizing: border-box;
            width: 100%;
            padding: 0px 16px;

            display: flex;
            flex-direction: column;
            gap: 32px;
            
            img{
                width: 100%;
                max-width: 680px;
            }

            .PosterArea{
                display: flex;
                flex-direction: column;

                .Univ2022Result{
                    display: flex;
                    padding: 24px 0px 24px;
                    justify-content: center;
                    align-items: center;
                    flex: 1 0 0;
                    background: rgba(39, 158, 255, 1);

                    .Univ2022Winners{
                        display: flex;
                        border-radius: 8px;
                        min-width: 120px;
                        max-width: 200px;
                        padding: 10px 16px;
                        justify-content: center;
                        align-items: center;
                        flex: 1 0 0;
                        font-family: inherit;

                        color: rgb(255,255,255);
                        border: 1px solid rgb(255,255,255);
                    }
                }

                .PrizeAnnouncement{
                    display: flex;
                    flex-direction: column;
                    .PrizeAnnouncementTitle{
                        background-color: rgba(39, 158, 255, 1);
                        padding-bottom: 50px;
                    }

                    .PrizeBackground{
                        display: flex;
                        flex-direction: column;
                    }

                    .ButtonBackground{
                        display: flex;
                        justify-content: space-around;
                        padding: 75px 0 95px;

                        background-color: rgba(39, 158, 255, 1);

                        @media (max-width: 700px) {
                            padding: 25px 0 50px;
                        }
                        
                        .VisitButton{
                            padding: 13px 5%;
                            
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            color: rgb(39, 158, 255);
                            font-feature-settings: 'clig' off, 'liga' off;
                            font-family: Pretendard;
                            font-size: 1.5rem;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            background-color: rgb(255,255,255);
                        
                            &.Wide{
                                padding: 13px 16%;
                            }

                            @media (max-width: 700px) {
                                padding: 7px 10px;
                                font-size: 1rem;

                                &.Wide{
                                    padding: 10px 15px;
                                }
                            }
                        }
                    }

                    .SliderBackground{
                        padding-bottom: 135px;
                        display: flex;
                        justify-items: center;

                        background-color: rgba(39, 158, 255, 1);

                        @media (max-width: 700px) {
                            padding-bottom: 60px;
                        }
                    }

                    .Divider{
                        background-color: rgba(39, 158, 255, 1);

                        &.Prize{
                            padding-bottom: 95px;
                            @media ( max-width: 700px ) {
                                padding-bottom: 50px;
                            }
                        }
                        &.Standard{
                            padding-bottom: 95px;
                            @media ( max-width: 700px ) {
                                padding-bottom: 50px;
                            }
                        }
                        &.Statistics{
                            padding-bottom: 135px;
                            @media ( max-width: 700px ) {
                                padding-bottom: 60px;
                            }
                        }
                        .DidiveStoke{
                            margin: 0 auto;
                            width: calc(100% - 60px);
                            border-radius: 20px;
                            border-top: 2px solid rgb(255,255,255);
                        }
                    }
                }
            }

            .ButtonArea{
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 24px 0px;
                border-top: 1px solid rgba(213, 213, 216, 1);

                span{
                    color: rgba(255, 255, 255, 1);
                    font-size: 0.9375rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px; /* 160% */
                    letter-spacing: 0.15px;
                    white-space: nowrap;
                }

                img{
                    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7488%) hue-rotate(97deg) brightness(98%) contrast(86%);
                }
            }
        }
    
        .ContestPromotion{
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding-top: 8px;

            @media (max-width: 1080px){
                position: fixed;
                bottom: 0;
                z-index: 1;

                flex-direction: row-reverse;
                justify-content: center;
                align-items: center;
                gap: 8px;
                
                box-sizing: border-box;
                padding: 8px 16px;
                width: 100%;

                background-color: rgba(255, 255, 255, 1);
            }
    
            .ContestPromotionPhrase{
                display: flex;
                flex-direction: column;
                gap: 8px;
    
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 400;
                line-height: 28px;
                letter-spacing: 0.24px;
    
                :last-child{
                    font-size: 1.5rem;
                    font-weight: 600;
                    line-height: 32px;
                }

                @media (max-width: 1080px){
                    display: none;
                }
            }
    
            .PromotionButton{
                box-sizing: border-box;
                width: 360px;
                padding: 13px 16px;
                display: flex;
                justify-content: center;
                align-items: center;
    
                border: none;
                border-radius: 8px;
                
                @media (max-width: 1080px){
                    min-width: 120px;
                    max-width: 200px;
                    padding: 10px 16px;

                    font-size: 0.9375rem;
                }

                span{
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px; /* 160% */
                    letter-spacing: 0.15px;
                }
            }
        }

        .NoticeBox{
            display: flex;
            flex-direction: column;
            gap: 12px;

            &.pc{
                max-width: 360px;
                @media ( max-width: 1080px ) {
                    display: none;
                }
            }
            &.mobile{
                padding: 20px 10px 0;
                @media ( min-width: 1080px ) {
                    display: none;
                }
            }

            .NoticeArticle{
                display: flex;
                flex-direction: column;
                gap: 4px;

                .NoticeTitle{
                    color: rgba(121, 120, 130, 1);
                    font-family: Pretendard;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 18px;

                    .HyperLink{
                        color: rgba(25, 77, 229, 1);
                    }
                }

                .NoticeDescription{
                    word-break: keep-all;
                    color: rgba(158, 157, 164, 1);
                    font-family: Pretendard;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px; /* 150% */
                }
                .NoticeContent{
                    word-break: keep-all;
                    color: rgba(158, 157, 164, 1);
                    font-family: Pretendard;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px;

                    li, ul{
                        padding: 0;
                        margin: 0;
                    }
                    li{
                        margin-left: 15px;
                    }
                }
                .ExampleImages{
                    display: flex;
                    padding: 0 15px;
                }
            }
        }
    }
}