.WTConfirmationStyledDialog {
    .MuiBackdrop-root{
        background-color: rgba(246, 246, 249, 0.80);
    }
    
    .MuiDialog-container{
        .MuiPaper-root{
            border-radius: 16px;
            border: 1px solid rgb(237, 236, 238);
            box-shadow: 0px 14px 50px 15px rgba(12, 22, 39, 0.12);
        
            .MuiDialogTitle-root, .MuiDialogContent-root, .MuiDialogActions-root{
                width: 360px;
                box-sizing: border-box;
            }
            
            .MuiDialogTitle-root{
                padding: 36px 24px 24px;
        
                .MuiTypography-root{
                    display: flex;
                    flex-direction: column;
                    gap: 12px;
        
                    .Title{
                        font-size: 1.125rem;
                        white-space: pre-line;
                        color: rgba(0, 0, 0, 1);
                        text-align: center;
                        font-family: Pretendard;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 24px; /* 133.333% */
                        letter-spacing: 0.18px;
                    }
                    .SubTitle{
                        color: rgba(121, 120, 130, 1);
                        font-size: 0.875rem;
                        font-weight: 500;
                        line-height: 20px;
                        letter-spacing: 0.14px;
                        font-family: pretendard;
                        white-space: pre-line;
                        text-align: center;
                        font-style: normal;
                    }
                }
        
            }
            .MuiDialogActions-root{
                padding: 0 24px 24px;
        
                display: flex;
                flex-direction: column;
                gap: 8px;
        
                button{
                    padding: 12px;
                    width: 100%;
                }
        
            }
        }
    }
}
