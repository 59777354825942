
.UnivAlarmBarArea{
    display: flex;
    flex-direction: column;
    gap: 8px;

    .NoticeBox{
        display: flex;
        padding: 12px 16px;
        align-items: center;
        align-self: stretch;
        border-radius: 4px;
        background: rgba(224, 226, 235, 1);

        color: rgba(60, 70, 81, 1);
        font-family: Pretendard;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 150% */
        letter-spacing: 0.12px;
        white-space: pre;
        img{
            width: 18px;
        }
        .ImpressedText{
            font-weight: 700;
            cursor: pointer;
        }
    }
    
    .ReApplyBox{
        display: flex;
        padding: 12px 16px;
        align-items: center;
        align-self: stretch;
        border-radius: 4px;
        background: rgba(255, 224, 222, 1);

        color: rgba(60, 70, 81, 1);
        font-family: Pretendard;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 150% */
        letter-spacing: 0.12px;
        white-space: pre;
        img{
            width: 18px;
        }
        .ImpressedText{
            font-weight: 700;
        }
    }
}