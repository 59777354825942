$MARGINUNIT: 16px;

.AnalysisCompleteMainArea{
    max-width: 600px;
    margin: 0 auto;

    .AnalysisCompleteBody{
        padding-top: 130px;

        .BodyTitle{
            font-weight: bold;
            padding: 0 $MARGINUNIT * 2;
            text-align: center;
        };

        .ImageBox{
            padding: 0 $MARGINUNIT * 2;
            display: flex;
            justify-content: center;
            margin: $MARGINUNIT 0 $MARGINUNIT * 2;
        };

        .TextBox{
            text-align: center;
            padding: 0 $MARGINUNIT * 2;
            white-space: pre-line;
            word-break: keep-all;
            font-size: 0.9rem;
            margin-bottom: $MARGINUNIT * 1.5;
        };
    };

    .AnalysisCompleteFooter{
        position: fixed;
        bottom: $MARGINUNIT;
        max-width: 600px - $MARGINUNIT * 2;
        width: calc(100% - 64px);
        margin: 0 auto;
        left: 0;
        right: 0;
    };
};