.ErrorMessageContainer{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    padding: 0 16px 6px 16px;

    .Title{
        display: flex;
        gap: 10px;
        align-items: center;
        color: #00B0F0;
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: 600;
    }

    .description {
        margin: 5px 0;
        line-height: 24px;
        letter-spacing: 0.4px;
    }
}