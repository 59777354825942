.MultiUploadImageDisplayedArea{
    display: flex;
    flex-direction: column;
    position: relative;

    .MultiUploadControlBox{
        display: flex;
        justify-content: space-between;

        button{
            padding: 8px 10px;
            border-radius: 5px;
        }
        
        .MultiUploadeButton{
            background-color: rgb(255,255,255);
            border: 1px solid rgb(237, 236, 238);
            cursor: pointer;
    
            color: rgb(25, 24, 27);;
            font-size: 0.813rem;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0.13px;
            &.Disabled{
                border: 1px solid rgb(237, 236, 238);
                color: rgba(138, 138, 138, 1);
                background-color: rgb(246, 246, 249);
                cursor: auto;
            }
        }
        .OnEditingButtons{
            display: flex;
            gap: 8px;

            .DeleteAllButton{
                background-color: rgb(237, 236, 238);
                border: 0;
                cursor: pointer;
            }
            .FinishEditingButton{
                color: rgb(255,255,255);
                background-color: rgb(51, 51, 53);
                border: 0;
                cursor: pointer;
            }
        }
    }
    .MultiUploadImageContainer{
        border: 1px dashed rgb(213,213,213);
        display: grid;
        position: relative;
        border-radius: 5px;

        .UploadImageContainer{
            width: 100%;
            aspect-ratio: 1/1;
            display: flex;
            align-items: center;
            position: relative;
            box-sizing: border-box;
            
            border-radius: inherit;
            overflow: hidden;
            
            img {
                touch-action: none;
                -webkit-touch-callout: none;
            }

            .Ripple {
                display: none;

                position: absolute;
                width: 100%; height: 100%;
                background-color: rgba(0,0,0,0.5);
                border-radius: inherit;

                &.Grab {
                    display: block;
                }

                &.Grab.Animation{
                    @keyframes fadeInFromNone {
                        0% {
                            display: none;
                            opacity: 0;
                            scale: 0;
                            border-radius: 50%;
                        }
                        
                        1% {
                            display: block;
                            opacity: 0;
                            scale: 0;
                            border-radius: 50%;
                        }
                        
                        99% {
                            display: block;
                            opacity: 1;
                            scale: 1.5;
                            border-radius: 50%;
                        }
                        100% {
                            display: block;
                            opacity: 1;
                            scale: 1;
                        }
                    }

                    -webkit-animation: fadeInFromNone 1s cubic-bezier(0.23, 1, 0.32, 1) forwards;
                    animation:         fadeInFromNone 1s cubic-bezier(0.23, 1, 0.32, 1) forwards;
                }
            }

            &:hover{
                cursor: pointer;
            }

            .DeleteDimFilter{
                position: absolute;
                width: 100%; height: 100%;
                background-color: rgba(0,0,0,0.5);
                border-radius: inherit;
                cursor: pointer;

                .DeleteFileIcon{
                    position: absolute;
                    top: 4px; left: 4px;
                    width: 20px; height: 20px;
                    border-radius: 50%;
                    background-color: rgb(30, 30, 31);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
    .NumberOfFiles{
        display: flex;
        justify-content: flex-end;
        color: rgb(121, 120, 130);
        font-size: 	0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.13px;
    }
}