.SubmitDoneArea{
    display: flex;

    padding: 0 16px;

    height: calc(100vh - 60px);

    @media (max-width: 700px){
        height: calc(100vh - 60px - 47px);
    }

    overflow-y: auto;

    background-color: rgba(237, 236, 238, 1);
    .SubmitDoneInner{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        margin: auto;

        font-family: Pretendard;
        .SubmitDoneHeader{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;

            margin-bottom: 40px;
            
            @media (max-width: 720px) {
                margin-bottom: 16px;
            }

            .CheckCircle{
                width: 52px;
                height: 52px;
            }
            .HeaderText{
                color:rgb(25, 24, 27);
                text-align: center;
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 700;
                line-height: 36px; /* 150% */
                letter-spacing: 0.24px;
            }
        }
        .TitleCard{
            width: 100%;
            
            @media (min-width:360px) {
                width: 328px;
            }

            @media (min-width:430px) {
                width: 398px;
            }

            @media (min-width:720px) {
                width: 400px;
            }

            box-sizing: border-box;

            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;

            padding: 28px 24px 24px 24px;
            border-radius: 21px;

            background-color: rgb(255,255,255);

            word-wrap: break-word;
            word-break: keep-all;
            
            .TitleThumbnail{
                width: 154px;
                height: 154px;

                box-sizing: border-box;

                border-radius: 8px;
                border: 1px solid rgb(237, 236, 238);
                object-fit: cover;
            }
            .TitleInfo{
                width: 100%;
                
                padding: 0 10px;

                text-align: center;

                .Title{
                    color: rgb(60, 70, 81);
                    font-size: 1.25rem;
                    font-weight: 700;
                    line-height: 30px;
                    letter-spacing: 0.20px;
                }
                .Author{
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0.16px;            
                }
            }
            .GotoTitleButton{
                width: 100%;

                padding: 12px 46px;
                border-radius: 8px;
                background-color: rgb(51, 51, 53);

                cursor: pointer;

                color: rgb(255,255,255);
                font-size: 0.875rem;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: 0.14px;

                .RightArrow{
                    width: 24px;
                    height: 24px;
                    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(234deg) brightness(104%) contrast(104%);
                }
            }
        }
        .LaterButtonWrapper{
            margin-top: 4px;
            text-align: center;

            .LaterButton{
                min-width: 280px;
                padding: 12px;
                
                cursor: pointer;
                
                color: rgb(60, 70, 81);
                font-size: 0.875rem;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0.14px;
                word-wrap: break-word;
                word-break: keep-all;
            }
        }
        .SubmitDoneFooter{
            margin-top: 40px;
            padding: 10px;

            @media (max-width: 720px) {
                margin-top: 16px;
            }

            .FooterTextWrapper{
                text-align: center;
                span{
                    color: rgb(60, 70, 81);
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0.15px;
                    word-wrap: break-word;
                    word-break: keep-all;

                    &.Bold{
                        font-weight: 700;
                    }
                }
            }
        }
    }
}
