



.NoticeWrite{

    .WriteContainer{
        display: flex;
        justify-content: center;

        .WriteInner{
            width: 100%;
            max-width: 700px;
            margin: 60px 0;

            .Title{
                font-size: 2rem;
                padding-bottom: 40px;
                border-bottom: 3px solid black;
                font-weight: bold;
                position: relative;
                
            }

            .WriteTitle{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: 20px 15px;
                border-bottom: 1px solid lightgray;
            }


            .Form{
                padding: 15px 10px;

                .FormRow{
                    display: flex;
                    flex-direction: row;
                    justify-content: stretch;
                    align-items: center;
                    padding-bottom: 15px; 

                    .Attribution{
                        margin-right: 10px;
                        display: block;
                        font-size: 1rem;
                        width: 70px;
                    }

                    .SignificantChecker{
                        padding: 0;
                    }

                    .TextField{
                        padding: 0 5px;
                        border: 1px solid lightgray;

                        ::before{
                            border-color: rgba(255,255,255,0);
                            transition: 0;
                        }
                        ::after{
                            border-color: rgba(255,255,255,0);
                            transition: 0;
                        }
                    }

                    .SelectField{
                        width: 100px;
                        .MuiSelect-select{
                            padding: 7.5px 14px;
                        }
                    }
                }

                .DescriptionContainer{
                    .quill{
                        position: relative;

                        .ql-toolbar{
                            position: sticky;
                            background: white;
                            top: -1px;
                            z-index: 2;
                        }

                        .ql-container{
                            min-height: 300px;
                        }
                    }
                }
            }

            .ButtonArea{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                Button{
                    margin: 0 10px;
                }
            }
        }
    }
    .InsertImages{
        vertical-align: bottom;
    }
}