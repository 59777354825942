.ArtCard {
    border-radius: 20px;
    border: 1px rgba(196,196,196, 1) solid;
    margin-bottom: 30px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;

    font-size: 14px;

    padding: 14px;

    .ButtonDetail {
        color: rgba(0,176,240,1);
        text-align: center;
        height: 90px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &.Button:active {
        background-color: rgba(196,196,196, 1);

    }
    
    &.New{
        background-color: rgba(0,0,0,0);

        &.Button:active {
            background-color: rgba(233,233,233, 1);
        }
    }

    .Thumbnail {
        border-radius: 20px;
        background-color: rgba(51,51,51,1);
        width: 90px;
        height: 90px;
        flex-shrink: 0;
        margin-right: 14px;
        overflow: hidden;
    }

    .Detail {
        width: 100%;
        height: 90px;
        flex-shrink: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        .Title {

        }

        .Description {

        }

        .Manage {
            width: 100%;
            height: 32px;
            border-radius: 20px;
            background-color: rgba(241,241,241,1);
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            
            &.Button:active {
                background-color: rgba(192,192,192, 1);
            }
        }
    }
}