

.SearchTrendBarChartArea{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;

    .ChartSubTitle{
        position: absolute;
        left: 0; top: 0;
        color: rgb(163, 163, 163);
        font-size: 0.75rem;
    }
}