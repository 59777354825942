$BASIC_SPACE: 8px;
$BASIC_RADIUS: 16px;
$SIGNATURE_COLOR: rgb(0, 176, 240);

.LandingPage .PostScriptArea{
    word-break: keep-all;
    padding-top: 150px;

    background-image: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 40%, rgba(255,255,255,0) 80%, rgba(255,255,255,1) 100%) ,url('https://static.webtoon.today/application/PostscriptBackground.png');
    background-repeat: no-repeat;
    background-position: 50% 0%;

    background-size: cover;

    .PostScriptBody{
        max-width: 824px;
        box-sizing: border-box;
        margin: auto;

        @media(max-width: 540px){
            display: flex;
            flex-direction: column;
            justify-content: stretch;
            align-items: center;
        }


        .AverageSummary{
            display: flex;
            flex-direction: column;
            justify-content: stretch;
            align-items: stretch;
            padding-bottom: 80px;
            
            @media(max-width: 540px){
                width: calc(100% - 40px);
            }

            .Satisfaction{
                text-align: center;
                font-size: 2.5rem;
                font-weight: 700;
                padding-bottom: 20px;

                @media (max-width: 720px) {
                    font-size: 2rem;
                }
            }

            .ScoreBoard{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: flex-end;
    
                
                .Score{
                    text-align: center;
                    font-size: 2rem;
                    padding: 0px 0px 0px 10px;

                    @media (max-width: 720px) {
                        font-size: 1.5rem;
                    }
                }

                .FullScore{
                    text-align: center;
                    font-size: 1rem;
                    color: rgb(123,123,123);
                }
            }

        }

        .AuthorReviews{
            display: flex;
            flex-direction: column;
            justify-content: stretch;
            align-items: stretch;
            
            .TitleText{
                padding-bottom: 50px;
            }

            .ReviewContainer{
                display: flex;
                flex-direction: row;
                align-items: stretch;

                &.Left {
                    justify-content: left;
                }

                &.Right {
                    justify-content: flex-end;
                }

                .Review {
                    display: flex;
                    flex-direction: column;
                    align-items: stretch;
                    position: relative;

                    background-color: rgb(255,255,255);
                    border-radius: 5px;
                    box-shadow: 2px 2px 3px rgb(80, 173, 234);
                    margin: 0px 0px 50px 0px;
                    box-sizing: border-box;
                    
                    @media (min-width: 720px){
                        max-width: 500px;
                    }
                    

                    .AuthorColumn{
                        display: flex;
                        flex-direction: row;
                        justify-content: stretch;
                        align-items: center;

                        
                        &.RightAlignment{
                            justify-content: flex-end;
                            align-items: center;
                            padding-left: 25px;

                        }

                        .Writer{
                            color: $SIGNATURE_COLOR;
                            font-size: 0.9rem;
                            
                            display: flex;
                            flex-direction: column;
                            justify-content: stretch;
                            align-items: left;

                            .MainText{
                                font-size: 1rem;
                            }
                            &.Right{
                                align-items: flex-end;
                            }
                        }
                    }

                    .CommentColumn{
                        padding-left: 20px; padding-right: 20px;
                        display: flex;
                        flex-direction: column;
                        justify-content: stretch;
                        align-items: stretch;

                        .StarBox{
                            display: flex;
                            flex-direction: row;
                            justify-content: stretch;
                            align-items: center;
                            padding: 7px 0px;

                            .Stars{
                                height: 20px;
                            }
                        }

                        .Content{
                            word-break: break-all;

                            .EmphasizeText{
                                font-weight: bold;
                            }
                        }

                        &.RightAlignment{
                            align-items: flex-end;
                            padding-left: 25px;
                            padding-right: 0px;

                            .Content{
                                text-align: left;
                            }
                        }
                        
                        &.LeftAlignment{
                            padding-left: 0px;
                            padding-right: 25px;
                        }
                    }


                    &.Left {
                        padding: 20px 0px 20px 20px;
                        justify-content: stretch;
                        margin: 0px 0px 40px 20px;

                        @media(max-width: 720px){
                            padding: 20px 0px 20px 20px;
                            margin-right: 20px;
                        }

                    }

                    &.Right {
                        padding: 20px 20px 20px 0px;
                        justify-content: flex-end;
                        margin: 0px 20px 40px 0px;
                        
                        @media(max-width: 720px){
                            padding: 20px 20px 20px 0px;
                            margin-left: 20px;
                        }

                    }

                    &.Left::after, &.Right::before {
                        background-color: rgb(255,255,255);
                        box-shadow: 3px 3px 2px 0 rgba( 80, 173, 234, 0.6 );
                        content: "\00a0";
                        display: block;
                        position: absolute;
                        transform:             rotate( 45deg );
                            -moz-transform:    rotate( 45deg );
                            -ms-transform:     rotate( 45deg );
                            -o-transform:      rotate( 45deg );
                            -webkit-transform: rotate( 45deg );
                            bottom: -9%;
    
                    }

                    &.Left::after {
                        bottom: -6%;
                        height: 26px;
                        left: 10%;
                        width:  19px;
                        transform:             rotate( 53deg );
                            -moz-transform:    rotate( 53deg );
                            -ms-transform:     rotate( 53deg );
                            -o-transform:      rotate( 53deg );
                            -webkit-transform: rotate( 53deg );

                        @media(max-width: 720px){
                            bottom: -7%;
                            height: 33px;
                            width: 25px;
                        }
                    }

                    &.Right::before {
                        bottom: -4%;
                        right: 10%;
                        height: 20px;
                        width: 25px;
                        transform:             rotate( 38deg );
                            -moz-transform:    rotate( 38deg );
                            -ms-transform:     rotate( 38deg );
                            -o-transform:      rotate( 38deg );
                            -webkit-transform: rotate( 38deg );

                    }
                }
            }
        }

        .AdditionalMessage{
            word-break: keep-all;
            color: $SIGNATURE_COLOR;
            text-align: right;
            margin: 0px 20px;
        }

        .AdvantagesOfAnalysis{
            display: flex;
            flex-direction: column;
            justify-content: stretch;
            align-items: center;
            padding-bottom: 50px;

            .TitleText{
                padding-top: 80px;
                padding-bottom: 50px;

                
                @media(min-width: 720px){
                    display: flex;
                    flex-direction: row;
                    justify-content: stretch;
                    align-items: stretch;
                }

                .Space{
                    @media(min-width: 720px){
                        padding-right: 8px;
                    }
                }
            }

            .Advantages{
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: stretch;

                .DocumentBox{
                    max-width: 820px;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: stretch;
    
                    @media(max-width: 720px){
                        display: flex;
                        flex-direction: column;
                        justify-content: stretch;
                        align-items: center;
                    }

                    .PaperCover{
                        @media(min-width: 721px){
                            min-height: 250px;
                        }

                        .Paper{
                            width: 220px;
                            min-height: 250px;
                            margin: 0px;
                            text-align: center;
                            padding: $BASIC_SPACE*4 $BASIC_SPACE*5 0px $BASIC_SPACE*5;
                            border-radius: $BASIC_RADIUS;

                            background-color: rgb(255, 255, 255);
                            backdrop-filter: blur(12px);
                            border: 1px solid rgba(255, 255, 255, 0.18);
                            box-shadow: rgba(142, 142, 142, 0.7) 4px 4px 4px 0px;
                            box-sizing: border-box;

                            @media(max-width: 720px){
                                margin-bottom: 40px;
                            }

                            .PaperIcons{
                                padding-bottom: 20px;
                            }
                            
                            .PaperText{
                                font-weight: bold;
                            }
                        }
                    }
                }
            }
        }
        
    }
    .TitleText{
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;
    }
    .TitleSubtext{
        font-size: 1rem;
        text-align: center;
        @media(max-width: 540px){
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
}

.PostScriptArea2 {

    .PostScriptBody {
        max-width: 824px;
        margin: auto;
        
        .ContractedWorks{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: stretch;

            .TitleBox{
                margin-top: 80px;
                margin-bottom: 50px;

                .TitleText{
                    padding-bottom: 10px;
                }
            }
            
            .Webtoons{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                position: relative;

                max-width: 100%;
                max-height: 720px;

                .WebtoonSlide{
                    position: relative;

                    .WebtoonImg{
                        box-sizing: border-box;
                        padding:0px 20px;
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }

                    .SlideLabel{
                        position: absolute;
                        bottom: 10px;
                        left: calc(50% - 60px);
                        width: 120px;
                        text-align: center;
                        color: rgb(255,255,255);
                        border-radius: 20px;
                        text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
                    }
                }

                .LinkedSlide{
                    cursor: pointer;
                }

                .ArrowPrev{
                    position: absolute;
                    color: rgba(0,176,240, 0.5);
                    font-size: 4rem;
                    z-index: 1;
                    cursor: pointer;
                    transition: 0.4s;
                    left: 3%;
                    top: 50%;
                    margin-top: -6%;
                    
                    .MuiSvgIcon-root{
                        font-size: 4.5rem;
                    }
                }

                .ArrowPrev:hover{
                    color: rgba(0,176,240, 0.95);
                }

                .ArrowNext{
                    position: absolute;
                    color: rgba(0,176,240, 0.5);
                    font-size: 4rem;
                    z-index: 1;
                    cursor: pointer;
                    transition: 0.4s;
                    right: 3%;
                    top: 50%;
                    margin-top: -6%;
                    
                    .MuiSvgIcon-root{
                        font-size: 4.5rem;
                    }
                }

                .ArrowNext:hover{
                    color: rgba(0,176,240, 0.95);
                }
            }
        }
    }
    .TitleText{
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;
        word-break: keep-all;
    }
    .TitleSubtext{
        font-size: 1rem;
        text-align: center;
        word-break: keep-all;
        @media(max-width: 540px){
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
}
