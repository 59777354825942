.Tabs {
    position: relative;
    display: flex;
    gap: 12px;
    width: 100%;
    padding: 32px 0px 24px 0;
    align-items: center;
    justify-content: center;
    z-index: 3;
    transition: 500ms ease-out;

    .Tab {
        padding: 12px;
        align-items: center;
        border-radius: 8px;
        color: rgba(110, 109, 122, 1);
        cursor: pointer;
        transition: 200ms;

        &:hover {
            background-color: rgba(229, 233, 241, 1);
        }
    }

    .Selected {
        background-color: rgba(51,51,51,1);
        color: rgba(255,255,255,1);

        &:hover {
            background-color: rgba(85,85,85,1);
        }
    }
    
    .RefreshButton {
        transition: 200ms;
        display: flex;
        width: 28px;
        height: 28px;
        border-radius: 5px;
        margin-left: 12px;
        border: 1px solid rgba(225, 226, 228, 1);
        cursor: pointer;

        &:hover {
            background-color: rgba(225, 226, 228, 1);
        }
    }
}

body[data-scroll-have-passed-threshold="yes"] .Tabs {
    position: sticky;
    top: -100px;
    
    &.Show {
        top: 80px;
        padding: 16px 0 16px 0;
        background-color: rgba(255,255,255,1);
        border-top: 1px solid rgba(244, 244, 245, 1);
    }
}