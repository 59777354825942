$MARGINUNIT: 16px;
$FULLSCREENMAXWIDTH: 600px;

.MainArea{
    max-width: $FULLSCREENMAXWIDTH;
    margin: 0 auto;
    position: relative;
    
    .BodyContainer{
        padding: 20% $MARGINUNIT*2 100px;

        .TitleBox{

            .LogoImage{
                display: flex;
                justify-content: center;
            }

            .TitleText{
                text-align: center;
                font-size: 1.3rem;
                font-weight: bold;
                color: rgb(0,176,240);
                margin-top: $MARGINUNIT;
                white-space: pre-line;
            }

            .SubText{
                margin-top: $MARGINUNIT;
                text-align: center;
            }
        }

        .EmailContainer{
            margin-top: $MARGINUNIT*4;
            padding: 0 10px;
            position: relative;

            .Label{
                font-size: 0.9rem;
                color: lightgray;
            }

            .EmailList{
                position: absolute;
                top: 50px; right: 10px;
                width: calc(100% - 20px);
                box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.3);
                background-color: rgb(255, 251, 254);
                z-index: 1;
            }
        }

        .ButtonArea{
            padding: 0 10px;

            .Button{
                width: 300px;
                margin-top: 10px;
                padding: 10px 0;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                width: 100%;
                position: relative;
                background-color: rgb(0,176,240);
                color: white;
                text-transform: none;
            }

            .GoogleContainer{
                
                iframe{
                    margin: 0 !important;
                    width: 100% !important;
    
                    body{
                        padding: 10px;
                    };

                    div#container{
                        padding: 0 !important;
                        width: 100% ;
    
                        #container>div{
                            width: 100% !important;
                            max-width: none !important;
                            min-width: none !important;
                        };
                    };

                    .haAclf{
                        padding: 0 !important;
                        width: 100% ;
                    }

                    .haAclf>div{
                        width: 100% !important;
                        max-width: none !important;
                        min-width: none !important;
                    };
                };
                
                div#container{
                    padding: 20px;
                }
            };
        };

        .InnerInputBox{
            padding-bottom: $MARGINUNIT*2;

            .BoxLabel{
                color: rgb(150,150,150);
                font-size: 0.75rem;
            }


            .EmailStateButton{
                font-size: 0.75rem;
                color: rgb(0,176,240);
                border: 1px solid rgb(0,176,240);
                margin-left: 10px;
                width: 40%;
                padding: 0;
                border-radius: 0;

                display: flex;
                justify-content: center;
                align-items: center;

                .MuiButton-label{
                    font-size: 0.75rem;
                }
            }

            .DisabledEmailStateButton{
                color: lightgray;
                border: 1px solid lightgray;
            }

            .BelowField{
                margin-top: 5px;
                display: flex;
                justify-content: space-between;
                display: flex;
                justify-content: space-between;

                .PasswordRuleCheck{
                    display: flex;

                    .CheckText{
                        color: lightgray;
                        display: flex;
                        align-items: center;
                        font-size: 0.75rem;
                        margin-right: 5px;

                        .CheckIcon{
                            font-size: 0.8rem;
                            padding-left: 1px;
                        }
                    }
        
                    .Checked{
                        color: rgb(0,176,240);
                    }
                }

                .ShowingPassword{
                    display: flex;
                    justify-content: flex-end;

                    .VisibleCheckBox{
                        width: 15px;
                        height: 15px;
                        padding: 0 2px 0 0;
                    }

                    svg{
                        width: 15px;
                        height: 15px;
                    }

                    Label{
                        margin: 0;

                        .MuiFormControlLabel-label{
                            font-size: 0.75rem;
                        }
                    }
                }

                .NeedOfCertification{
                    display: flex;
                    font-size: 0.75rem;
                    align-items: center;
                }


                .UnknownPassword{
                    display: flex;
                    font-size: 0.75rem;
                    align-items: flex-end;
                    color: rgb(0,176,240);
                    cursor: pointer;
                }

                .CorrespondState{
                    font-size: 0.75rem;
                    color: red;
                }
            }

            .FindingPasswordGuide{
                margin-top: 30px;
                .GuideTitle{
                    display: flex;
                    align-items: center;
                    font-size: 0.75rem;
                };

                .GuideItems{
                    margin-top: 10px;
                    line-height: 30px;
                    font-size: 0.75rem;

                    .GuideItem{
                        display: list-item;
                        margin-left: 30px;
                    }
                };
            };

            .Identification{
                color: black;
                border: 1px solid rgba(0,0,0,0.5);
                font-size: 0.75rem;
                padding: 2px 10px;
                margin-top: 10px;
                height: 35px;
            }

            .IdentifiedButton{
                color: rgb(0,176,240);
                border: 1px solid rgba(0,0,0,0.5);
                font-size: 0.75rem;
                padding: 2px 10px;
                margin-top: 10px;
                height: 35px;
            }

            .GenderGroup{
                margin-top: $MARGINUNIT;

                .FocusedButton{
                    background-color: rgb(0,176,240);
                    color: white;
                }
            }
            
            .AgeButtonGroup{
                display: flex;
                flex-direction: row;
                margin-top: $MARGINUNIT;

                .ButtonsWrapper{
                    width: calc(20% - 4px);
                    display: flex;
                    justify-content: center;

                    .AgeButton{
                        color: lightgray;
                        border-radius: 50%;
                        border: 1px solid lightgray;
                        width: 45px;
                        height: 45px;
                        min-width: 0;
                        padding: 3px;
                        line-height: 1.12;
                        font-size: 0.75rem;
                    }
                    
                    .Focused{
                        color: rgb(0,176,240);
                        border: 2px solid rgb(0,176,240);
                    }
                }
            }

        }

        .CompleteTitle{
            margin-top: $MARGINUNIT*3.5;
            font-size: 1.25rem;
            font-weight: bold;
            color: rgb(0,176,240);
        }
        .CompleteText{
            margin-top: 12px;
            white-space: pre-line;
        }

        
        .ResetMailTitle{
            padding-bottom: $MARGINUNIT*2;
        }

        .ResetMailDescription{
            padding-bottom: $MARGINUNIT*2;
            font-size: 0.9rem;
        }
    }

    .BottomGroup {
        position: fixed;
        width: calc(100% - 64px);
        max-width: $FULLSCREENMAXWIDTH - ($MARGINUNIT * 4);
        bottom: 80px;
        display: flex;
        flex-direction: column-reverse;
    }

    .MailAlarm{
        width: 100%;
        align-items: center;
        max-width: $FULLSCREENMAXWIDTH - ($MARGINUNIT * 4);
        box-sizing: border-box;
        margin: 30px auto;
        bottom: 140px;

        @media (max-width: 700px) {
            position: fixed;
            left: 0;
            right: 0;
            margin: 0 auto;
            bottom: 75px;
            width: calc(100% - 64px);
        }

        .MuiAlert-message{
            font-size: 0.9rem;
        }
    }

    .NextModeButton{
        width:  calc(100% - 64px);
        height: 45px;
        border-radius: 5px;
        position: fixed;
        bottom: $MARGINUNIT + 60px;
        max-width: $FULLSCREENMAXWIDTH - ($MARGINUNIT * 4);
    }

    .CompleteModeButtonGroup{
        height: 150px;
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: white;
        bottom: $MARGINUNIT + 60px;
        max-width: $FULLSCREENMAXWIDTH - ($MARGINUNIT * 4);
        width: calc(100% - 64px);

        .Button{
            border-radius: 5px;
            height: 45px;
            margin-bottom: $MARGINUNIT;
        }

        .ClosePage{
            border: 1px solid rgb(0,176,240);
            height: 45px;
        }
    }
}
.SignInTermsOfServiceConsentDialog{
    .MuiDialog-scrollPaper{
        align-items: flex-end;
    }
}