
.DisclaimerArea{
    display: flex;
    flex-direction: column;
    max-width: 720px;
    width: 100%;
    padding-bottom: 60px;
    box-sizing: border-box;
    user-select: none;
    padding-top: 40px;

    gap: 36px;

    @media (max-width: 720px) {
        max-width: 375px;
        width: 100%;
    }

    .DisclaimerInfo{
        background-color: rgb(255,255,255);
        padding: 40px 30px;
        display: flex;
        flex-direction: column;
        gap: 32px;
        border-radius: 5px;

        .DisclaimerTitle{
            color: rgb(23,23,23);
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px; /* 140% */
            letter-spacing: 0.2px;
        }
        .DisclaimerBody{
            display: flex;
            flex-direction: column;
            gap: 24px;
            .SurviceAllAgreementsBox{
                display: flex;
                justify-content: space-between;

                .TermsOfService{
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 142.857% */
                    letter-spacing: 0.14px;
                    color: rgba(25, 77, 229, 1);
                    text-decoration: none;
                }
            }
            .Divider{
                margin: 0; padding:0;
                width: 100%;
                border: 1px solid rgba(213, 213, 216, 1);
            }
            .AgreementBox{
                display: flex;
                flex-direction: column;
                gap: 16px;

                .AgreementRow{
                    display: grid;
                    grid-template-columns: auto 1fr;
                    align-items: center;
                    gap: 4px;
                
                    .MandatoryNotice{
                        color: rgba(121,120,130,1);
                        font-size: 0.875rem;
                        font-size: normal;
                        font-weight: 600;
                        line-height: 1.25rem;
                        letter-spacing: 0.00875rem;
                    }
                }
            }
        }
    }
    
    .HorizontalLine{
        width: 100%; margin: 0;
        border: 1px solid rgba(213, 213, 216, 1);
    }

    .ButtonInfo{
        display: flex;
        justify-content: space-between;

        .Button{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 200px; height: 48px;
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 150% */
            letter-spacing: 0.16px;
            cursor: pointer;
            border-radius: 10px;

            @media ( max-width: 720px ){
                width: 125px;
            }
        }
        .Previous{
            color: rgba(25, 24, 27, 1);
            background-color: rgb(255, 255, 255);
            position: relative;

            &:active::after{
                content: '';
                position: absolute; top: 0; left: 0;
                width: 100%; height: 100%;
                background-color: rgba(25, 24, 27, 0.12);
                border-radius: 10px;
            }
        }
        .ApplyComplete{
            color: rgba(255, 255, 255, 1);
            background-color: rgba(61, 106, 255, 1);
            position: relative;
            
            &:active::after{
                content: '';
                position: absolute; top: 0; left: 0;
                width: 100%; height: 100%;
                background-color: rgba(255, 255, 255, 0.12);
                border-radius: 10px;
            }

            &.disabled{
                background-color: rgba(61, 106, 255, 0.5);
                cursor: not-allowed;
            }
        }
    }

}