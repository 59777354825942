.SearchArea{
    max-width: 700px;
    margin: 0 auto;
    box-sizing: border-box;

    .SearchHeader{
        display: flex;
        justify-content: center;
        align-items: center;
        
        max-width: 600px;
        margin: 0 auto;
        margin-top: 16px;
        padding: 0 20px;

        .MuiInput-underline:before{
            border-color: white;
        }
        .MuiInput-underline:after{
            border-color: white;
        }
        .MuiInput-underline:hover:not(.Mui-disabled):before{
            border-color: white;
        }

        .HeaderBox{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .MoveToHompage{
                cursor: pointer;
                display: flex;
                align-items: center;
                padding:0;
            }

            .SearchField{
                border: 1px solid rgb(196,196,196);
                width: 65%;
                min-width: 270px;
                height: 33px;
                background-color: rgb(250,250,250);
                border-radius: 20px;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .TextField{
                    width: 85%;
                    border-color: white;
                }

                .TextClearButton{
                    width: 10%;
                    transform: rotate(45deg);
                    cursor: pointer;
                }
            }

            .SearchStartButton{
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                padding: 0;
            }
        }


    }
    .SearchBody{
        background-color: white;

        .MuiTabs-flexContainer{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;

            .MuiButtonBase-root.MuiTab-root{
                display: flex;
                align-items: flex-end;
                padding: 0
            }

            .FocusedTab{
                color: rgb(0,176,240);
                box-shadow: inset 0 -2px 0 rgb(0,176,240);
            }
        }

        .SliderContainer{
            margin: 0 auto;
            max-width: 450px;

            @media (min-width: 1001px){
                max-width: 1200px;
            }
            @media (min-width: 701px) and (max-width: 999px) {
                max-width: 700px;
            }

            .slick-track .slick-slide:not(.slick-current) .CardMainArea{
                opacity: 0.5;
            }

            .CardSliderBox{
                position: relative;
                transition: all .3s ease;
                border-radius: 0px;
            }
            .HiddenCardSliderBox{
                display: none;
            }
        }

        .TitleBox{
            font-size: 0.9rem;
            padding-bottom: 10px;
            padding-top: 50px;
            margin: 0 auto;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;

            @media (min-width: 700px) {
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }
        }

        .GenreGridBox{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            position: relative;
            margin: 0 auto;
            margin-top: 20px;

            @media (min-width: 700px) {
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }

            .GenreCell{
                display: flex;
                justify-content: center;
                align-items: center;

                .Genre{
                    border: 1px solid rgb(217,217,217);
                    border-radius: 20px;
                    padding: 5px 20px;
                    font-size: 0.75rem;
                    cursor: pointer;
                }

                &:nth-child(10){

                    .Genre{
                        padding: 5px 12px;
                    }
                }

                .FocusedGenre{
                    border-color: rgb(67, 153, 255);
                    color: rgb(67, 153, 255);
                }

            }

            .LeftSideCell{
                justify-content: left;
            }

            .RightSideCell{
                justify-content: right;
            }
        }
    }
}