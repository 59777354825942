
$SIGNATURE_COLOR: rgb(0,176,240);

.LandingPage .WebtoonScoreTemplate{
    padding-top: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    word-break: keep-all;

    .TemplateMainBody{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 824px;
    
        @media (max-width: 720px) {
            width: calc(100% - 40px);
        }



        .TitleBox{
            width: 100%;
            padding-bottom: 80px;


            .Title{
                margin-bottom: 10px;
                font-size: 2.5rem;
                font-weight: 700;
                text-align: center;

                @media (max-width: 720px){
                    font-size: 2rem;
                }

                span.SignatureText{
                    color: $SIGNATURE_COLOR;
                }
            }

            .TextLine{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: stretch;
                font-size: 1.2rem;
                text-align: center;

                @media (max-width: 540px){
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
            }

            ul{
                list-style-type: square;
                font-size: 1.2rem;
                padding-left: 0px;

                @media(max-width: 960px){
                    font-size: 1rem;
                }

                li{
                    list-style-type: none;
                    text-align: center;
                }
            }

        }

        .Explanation{
            width: 100%;

            .PaperBox{
                display:flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: stretch;
                margin-bottom: 50px;

                @media(max-width: 960px){
                    display:flex;
                    flex-direction: column;
                    justify-content: stretch;
                    align-items: center;
                    margin-bottom: 100px;
                }

                .PaperCover{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;
                    align-items: stretch;
                    width: 31%;
                    margin-bottom: 40px;

                    @media(min-width: 721px){
                        min-height: 300px;
                    }

                    .Paper{
                        width: 100%;
                        min-width: 250px;
                        max-width: 250px;
                        min-height: 320px;
                        margin: 0px;
                        text-align: center; 
                        padding: 40px 30px 10px 30px;
                        border-radius: 16px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: stretch;

                        background-color: rgb(255, 255, 255);
                        backdrop-filter: blur(12px);
                        border: 1px solid rgba(255, 255, 255, 0.18);
                        box-shadow: rgba(142, 142, 142, 0.7) 2px 4px 8px 0px;
                        box-sizing: border-box;

                        .PaperIcons{
                            margin: 10px 0px;
                        }

                        .PaperTitle{
                            height: 50px;
                            font-size: 1.2rem;
                            font-weight: 700;

                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                        }
                        .PaperText{
                            font-size: 1rem;
                        }

                        span{
                            color: $SIGNATURE_COLOR;
                        }
                    }

                    .Union{
                        border-radius: 16px 16px 0px 0px;
                    }

                    .ExtraPaper{
                        min-width: 250px;
                        max-width: 250px;
                        width: 100%;
                        border-radius: 0px 0px 16px 16px;
                        padding: 0px 40px 20px 40px;
                        
                        background-color: rgb(255, 255, 255);
                        backdrop-filter: blur(12px);
                        border: 1px solid rgba(255, 255, 255, 0.18);
                        box-shadow: rgba(142, 142, 142, 0.5)2px 7px 8px 0px;
                        box-sizing: border-box;

                        @media (max-width: 720px){
                            bottom: 1%;
                            position: relative;
                        }
                        
                        .ExtraText{
                            color: $SIGNATURE_COLOR;
                            font-size: 0.9rem;
                        }

                        .ExtraGraph{
                            margin-top: 10px;
                            width: 100%;
                        }

                        .TextCenter{
                            text-align: center;
                        }
                        
                    }
                }
            }
        }

        .IntroducingReport{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-width: 824px;

            @media(max-width: 960px){
                min-width: 0px;
            }

            .Introductions{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-top: 20px;
                cursor: pointer;
                .IntroductionText{
                    word-break: keep-all;
                }
            }
        }
    }
}