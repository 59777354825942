
.SquareTemplateContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-weight: 500;

    @media (min-width: 701px) {
        background-color: rgb(250,250,250);
        display: flex;
        justify-content: flex-start;
    }

    .TitleName{
        font-size: 1.5rem;
        margin: 35px 0 20px 0;
        max-width: 296px;
        width: 100%;
        font-weight: bold;
    };

    .TitleThumbnail{
        position: relative;
        display: flex;

        .ThumbnailSideBar{
            width: 32px;
            height: 296px;
            display: flex;
            flex-direction: column;

            .HorizentalLine{
                flex: 1;
                display: flex;
                justify-content: center;
            }

            .Text{
                font-size: 0.8rem;
                writing-mode: vertical-rl;
                text-orientation: upright;
                display: flex;
                align-items: center;
                line-height: 24px;
            }

            .Background{
                
                .Keyword{
                    font-size: 0.8rem;
                    writing-mode: vertical-rl;
                    text-orientation: upright;
                    letter-spacing: 3px;
                    display: flex;
                    align-items: center;
                    width: 100%;
                }
            }
        }

        .KeywordSide{
            justify-content: flex-end;

            .Text{
                margin-top: 10px;
            }
        }

        .AuthorSide{
            justify-content: flex-start;

            .Text{
                margin-bottom: 10px;
                letter-spacing: 3px;
            }
        }

    };

    .StatisticsOfTitle{
        display: flex;
        justify-content: center;
        margin-top: 16px;
        margin-bottom: 36px;
        font-size: 0.75rem;

        .StatisticsCell{
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 0.8rem;
        };

        .DevidingLine{
            display: flex;
            justify-content: center;
            align-items: flex-end;
            padding: 0 20px;
        };
    };
};

.WhiteSquareTemplate{
    
    @media (max-width: 700px) {
        background-color: white !important;
    };
};