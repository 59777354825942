
.FrequentlyUsedMaterialArea{
    width: calc(100% - 100px);
    padding: 30px 50px;
    background-color: rgb(255,255,255);
    margin: 30px 0;
    border-radius: 10px;
    
    @media(max-width: 700px) {
        width: calc(100% - 32px);
        padding: 16px;
        margin: 10px 0;
    }

    .Title{
        font-size: 1.25rem;
        font-weight: bold;
        margin-bottom: 24px;

        .SearchText{
            color: rgb(0,176,240);
        }
    }

    .FrequentlyUsedKeywords{
        display: flex;
        flex-wrap: wrap;
        font-size: 0.81rem;

        .Keyword{
            padding: 8px 12px;
            border-radius: 32px;
            background-color: rgb(248, 248, 248);
            margin: 0 6px 0 0;
            cursor: pointer;
        }
    }
}