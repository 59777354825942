.HeaderArea{
    .PCHeaderContainer{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px 0;
        height: 80px;
        
        border-bottom: 1px solid rgb(241,241,241);

        max-width: 1680px;
        margin: auto;
        box-sizing: border-box;

        @media (max-width: 700px) {
            display: none;
        };

        .PageMoveBox{
            display: flex;
            padding-left: 24px;

            .WebtoonTodayLogo{
                width: 38px;
                height: 32px;
                box-sizing: border-box;
                margin-right: 16px;
            }

            .MovePageMenus{
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0 16px;
                line-height: 24px;
                font-weight: 600;
                font-size: 15px;
                line-height: 24px;

                a{
                    text-decoration: none;
                    color: inherit;
                    display: flex;
                    word-break: keep-all;
                    vertical-align: top;
                    height: 100%;

                    .MoveButton{
                        margin-right: 16px;
                        cursor: pointer;
                        text-decoration: none;
                        color: inherit;
                    }
                }

                @media (max-width: 930px) {
                    a:nth-child(2), a:nth-child(4) {
                        display: none;
                    }
                }

                a:nth-last-child(2){
                    .MoveButton{
                        color: rgb(14,169,246);
                    }
                }

                .DividerLine{
                    margin: 0 16px;
                    border-right: 1px solid black;
                    height: 50%;
                }
                
                a:nth-last-child(2){
                    color: rgb(0,176,240);

                    .MoveButton{
                        margin-right: 0px;
                    }
                }

                a:nth-last-child(2)::before{
                    cursor: default !important;
                    content: '|';
                    display: flex;
                    padding-right: 16px;
                    color: rgb(51,51,51);
                }

                a:last-child{
                    .MoveButton{
                        color: rgba(255, 125, 30, 1);
                    }

                    &::before{
                        content: '';
                        margin-left: 16px;
                    }
                }
            }
        }


        .SearchAndLoginBox{
            display: flex;
            align-items: center;
            padding: 0 24px;

            .SearchButton{
                display: flex;
                cursor: pointer;
                color: rgb(117,117,117);
                padding: 4px 8px;
                margin-right: 8px;
            }

            .MenuIcon{
                margin: 4px 8px;
                display: flex;
            }

            .LoginButton{
                width: 24px; height: 24px;
                box-sizing: border-box;
                font-size: 0.9rem;
                color: rgb(225, 130, 50);
                box-sizing: border-box;
            }

            .DevideLine{
                width: 1px; height: 12px;
                background: rgb(0,0,0);
            }
        }
    }

    .MobileHeaderContainer{
        @media (min-width: 701px){
            display: none;
        };

        width: 100%;
        height: 60px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        
        .Mobile{
            width: 100%;
            padding: 0 16px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            height: 60px;
            box-sizing: border-box;
            background-color: rgb(26, 27, 29);

            @media (min-width: 701px){
                display: none;
            }

            .ArrowBox{
                width: 96px;
                display: flex;
                justify-content: flex-start;
                flex-shrink: 0;
                color: inherit;

                .IconBackground{
                    display: flex;
                    align-items: center;
                    color: inherit;
                };

                .WebtoonTodayLogo{
                    cursor: pointer;
                    padding: 0;
                    color: rgb(228,229,231);
                }
            };
        
            .PageBox{
                font-size: 1rem;
                color: rgb(33,33,33);
                font-weight: bold;
                justify-content: center;
                align-items: center;
                box-sizing: border-box;
                white-space: nowrap;
                overflow-x: hidden;
                text-overflow: ellipsis;
                max-width: calc(100% - 96px * 2);
        
                span{
                    display: flex;
                    align-items: flex-end;
                }
            }

            .ShortcutBox {
                height: 100%;
                font-size: 1rem;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                padding-left: 16px;

                .SearchIcon{
                    display: flex;
                    padding: 4px;
                    color: rgba(122, 124, 132, 1);
                    margin-right: 16px;
                };
                .LoginButton{
                    display: flex;
                    padding: 4px;
                    overflow: hidden;
                    border-radius: 50%;
                };
            };
        };
    };


    .PCHeaderContainer, .MobileHeaderContainer{
        position: relative;
        z-index: 9;

        .PopupMenuArea.mobile{
            width: 100%; height: 100vh;
            position: absolute;
            top: 0;
            background: rgba(20, 20, 21, 0.8);

            .PopupCloseButton{
                position: absolute;
                top: 18px; right: 20px;
                color: rgb(255,255,255);
                cursor: pointer;
            };
        };

        .PopupMenuArea{
            .PopupContainer{
                position: absolute;
                display: flex;
                flex-direction: column;
                z-index: 1;
    
                .SettingIcon{
                    position: absolute;
                    top: 20px; right: 16px;
                }
    
                .Author{
                    .AuthorImage{
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        margin-bottom: 12px;
                    };
    
                    .AuthorName, .UnregisterInfo{
                        text-align: center;
                        margin-bottom: 25px;
                    };
                    .UnregisterInfo{
                        color: rgba(241, 152, 46, 1);
                    };
                    .AddWebtoonBox{
                        display: flex;
                        justify-content: center;
    
                        .AddWebtoonButton{
                            background-color: rgb(30, 158, 255);
                            padding: 6px 16px;
                            border-radius: 40px;
                        };
                    };
                };
    
                .MenuList{
                    margin-top: 32px;
                    .MenuRow{
                        padding-bottom: 24px;
    
                        .Label{
                            font-weight: 600;
                            line-height: 22px;
                            cursor: pointer;
                        };
                    };
                    .DevideMenuLine{
                        border-bottom: 1px solid rgba(225, 226, 228, 1);
                        margin-bottom: 24px;
                    };
                    .LogoutMenu, .InquiryMenu{
                        font-weight: 600;
                        line-height: 22px;
                        cursor: pointer;
                    };
                    .LogoutMenu{
                        color: rgba(120, 124, 132, 1);
                        margin-bottom: 24px;
                    };
                };
            };
            .PopupContainer.pc{
                min-width: 320px;
                top: 90px; right: 24px; padding: 32px;
                border: 1px solid rgba(225, 226, 228, 1); 
                border-radius: 10px;
                background-color: rgb(255,255,255);
                color: rgba(13, 12, 33, 1);
            };
            .PopupContainer.mobile{
                width: calc(100% - 16px);
                top: 0px; 
                padding: 32px;
                border-radius: 20px;
                box-sizing: border-box;
                transform: translate(8px, 65px);
                background-color: rgba(43, 44, 48, 1);
                color: rgb(228, 229, 231);
                
                .DevideMenuLine{
                    border-bottom: 1px solid rgba(225, 226, 228, 1);
                    margin-bottom: 24px;
                };
            };
        };

        .PageMovingMenuArea{
            width: 100%; height: 100%; min-height: 100vh;
            position: absolute;
            top: 0; z-index: 9;
            background: rgba(20, 20, 21, 0.8);

            .PageMovingMenuCloseButton{
                position: absolute;
                top: 24px; right: 20px;
                color: rgb(255,255,255);
                cursor: pointer;
            };

            .PageMovingMenuContainer{
                width: calc(100% - 16px);
                position: absolute;
                display: flex; flex-direction: column;
                z-index: 1;
                border-radius: 20px;
                top: 0px; 
                padding: 32px;
                transform: translate(8px, 65px);
                background-color: rgba(43, 44, 48, 1);
                color: rgb(228, 229, 231);
                box-sizing: border-box;
    
                .ButtonBox{
                    margin-bottom: 24px;
                }
    
                .ButtonBox:last-child{
                    color: rgb(30, 158, 255);
                }
                .ButtonBox:nth-last-child(1){
                    padding-top: 24px;
                    margin-bottom: 0px;
                    border-top: 1px solid rgba(225, 226, 228, 1);
                }
            }
        }
    }
};