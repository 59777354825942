

.CopyrightSubRoot{
    text-align: center;
    padding: 50px 0px;
    position: relative;
    background: rgb(255, 255, 255);



    .Copyrights{
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        align-items: stretch;

        .Address{
            word-break: keep-all;
        }
    }


}