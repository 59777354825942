.ThemeDecorationArea{
    line-height: 24px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    width: 100%;

    @media (min-width: 701px) {
        flex-direction: row;
        align-items: flex-start;
        height: calc(100vh - 126px);
    };

    .HalfCol {
        width: 100%;
        margin: 0px 5px; border: 1px rgb(241,241,241) solid;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .TypeSelection{
        text-align: center;

        .TypeBox{
            margin:auto;
            justify-content: center;
            margin-top: 40px;
            cursor: pointer;

            .TemplateExample{
                width: 144px;
                height: 164px;
            };

            .TemplatePreview{
                width: 153px;
                object-fit: contain;
            }
        }
    }

    .ColorSelection{
        display: grid;
        grid-template-columns: 1fr;
        max-width: 200px;
        margin: 0 auto;
        padding: 20px 0px;
        padding-bottom: 120px;

        .FocusedOutline, .Outline{
            width: 64px;
            height: 64px;
            cursor: pointer;
            margin: 0 20px;
            box-sizing: border-box;

            .Color{
                width: 54px;
                height: 54px;
                border-radius: 50%;
            }
        }

        .FocusedOutline{
            border: 1px solid rgb(15,15,15);
            padding: 4px;
            border-radius: 50%;
        };

        .Outline{
            padding: 5px;
            border-radius: 50%;
        };
    }
}