$AppbarHeight: 47px;

.AppBar {
    position: fixed;
    height: $AppbarHeight;
    width: 100%;
    padding: 0 16px;
    box-shadow: 0 -1px 25px 1px rgba(0,0,0,0.15);
    background-color: rgba(26, 27, 30, 1);
    bottom: 0;
    border-top: 1px solid rgba(67, 63, 70, 1);
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 999;

    &:not(.AlwaysVisible){
        @media (min-width: 701px) {
            display: none;
        };
    };
    
  
    .AppBarInner{
        height: 100%;
        width: 100%;
        max-width: 470px;
        background-color: rgba(26, 27, 30, 1);

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;

        .AppButtonBox{
            width: auto; height: 100%;
            padding: 0;
            color: rgba(150, 139, 156, 1);
            font-size: 0.625rem;
            font-weight: 600;
            line-height: 10px;
            letter-spacing: 0.10px;
            word-wrap: break-word;
    
            .AppButton {
                height: 100%;
                padding: 8px 0 12px;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;
                white-space: nowrap;
                font-size: 0.625rem;
                gap: 2px;
                color: rgba(150, 139, 156, 1);

                .Icons{
                    width: 20px;
                    height: 20px;
                }
            };
    
            &.Activated{
                color: rgba(247, 247, 248, 1);
                
                .AppButton{
                    color: rgba(247, 247, 248, 1);
                };
            };
        };
    
        .ViewMore{
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: calc(100% - #{$AppbarHeight});
            max-height: calc(100% - #{$AppbarHeight});
            overflow-y: auto;
            
            display: flex;
            flex-direction: column;
            gap: 12px;
            
            background-color: rgba(51, 51, 53, 1);
            color: rgba(228, 229, 231, 1);
            
            & > div{
                background-color: rgba(30, 30, 31, 1);
                padding: 40px 0;
            }
    
            .AuthorInfo{
    
                &.SignedIn{
                    padding-bottom: 24px;
    
                    div{
                        display: flex;
                        justify-content: center;
                    }
                }
    
                &:not(.SignedIn){
                    padding-left: 20px;
                    padding-right: 20px;
                }
    
                .SubLabel{
                    color: rgba(241, 241, 241, 1);
                    font-size: 0.75rem;
                    font-weight: 300;
                    line-height: 16px;
                    letter-spacing: 0.16px;
                    word-wrap: break-word;
                }
    
                .Label{
                    margin: 4px auto 24px auto;
                    color: rgba(255, 255, 255, 1);
                    font-size: 1rem;
                    font-weight: 500;
                    line-height: 22px;
                    letter-spacing: 0.40px;
                    word-wrap: break-word;
                }
    
                .SigninButton{
                    color: rgba(24, 25, 27, 1);
    
                    .Signin{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
    
                .SettingIcon{
                    position: absolute;
                    top: 24px;
                    right: 24px;
                }
    
                .AuthorName{
                    margin : 12px auto 26px auto;
                    font-size: 0.875rem;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 0.28px;
                    word-wrap: break-word;
                }
    
                .AddWebtoonBox{
                    color: rgba(255, 255, 255, 1);
                    font-size: 0.875rem;
                    font-weight: 700;
                    line-height: 18px;
                    letter-spacing: 0.14px;
                    word-wrap: break-word;
                }
            }
    
            & > :not(.AuthorInfo):not(.CorpInfo){
                display: flex;
                flex-direction: column;
                gap: 24px;
    
                padding-left: 26px;
                color: rgba(228, 229, 231, 1);
                font-size: 0.9375rem;
                font-style: normal;
                font-weight: 700;
                line-height: 1.5rem;
                letter-spacing: 0.01875rem;
    
                .Analysis{
                    color: rgba(30, 158, 255, 1);
                }
            }
    
            .CorpInfo{
                display: flex;
                flex-direction: column;
                gap: 24px;
                height: 100%;
    
                padding-left: 16px;
                padding-right: 16px;
                box-sizing: border-box;
    
                color: rgba(150, 139, 156, 1);
                font-size: 0.75rem;
                font-weight: 500;
                line-height: 18px;
                letter-spacing: 0.17px;
                word-break: keep-all;

                .CorpInfomations{
                    display: flex;
                    flex-wrap: wrap;

                    .Infomation:not(:last-child){
                    
                        &::after{
                            content: '|';
                            margin: 10px;
                        }
                    }
                }
            }
        }
    
        .NewTitleButton{
            pointer-events: 'none'; 
            position: 'relative'; 
            height: 64px;
    
            .CreateButtonBackround{
                display: flex;
                cursor: pointer;
                border-radius: 50%;
                position: absolute;
                top: -40%;
                pointer-events: all;
                width: 55px;
                box-sizing: border-box;
    
                .CreateButton{
                    position: relative;
                    width: 100%; height: 100%;
                    color: rgb(255,255,255);
                    font-size: 2.5rem;
                    border-radius: 50%;
                    background-color: rgb(0,176,240);
                    padding: 15px;
                    box-shadow: 4px 4px 20px 5px rgba(1,1,1,0.1);
                    box-sizing: border-box;
                };
    
                .ButtonLabel{
                    position: absolute;
                    bottom: -27px;
                    white-space: nowrap;
                    font-size: 14px;
                    color: rgb(0,176,240);
                    width: 66px;
                    left: calc(50% - 33px);
                };
            };
        };
    };
};