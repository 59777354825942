

.PopularMaterialsTopicBox{
    width: 100%;

    .Topic{
        padding: 16px 0;
        display: flex;
        cursor: pointer;

        .TopicIndex{
            font-weight: bold;
            width: 40px;
        }
    }
}