



.MyPageArea{

    .MyPageHomeBody{
        display: flex;
        justify-content: center;

        .BodyInner{
            width: 100%;
            max-width: 400px;
            padding: 0 16px;
            box-sizing: border-box;

            @media (min-width: 701px) {
                max-width: 700px
            };

            .TabPageTitle{
                margin: 30px 0;
                font-size: 1rem;
                font-weight: bold;
            }

            .EmptyBoxMessage{
                display: flex;
                flex-direction: column;
                align-items: center;

                img{
                    width: 80px;
                    height: 68px;
                    margin-bottom: 22px;
                };

                .TextBox{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .FlatTextLine{
                        display: flex;
                        
                        .FavoriteIcon{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-left: 5px;
    
                            .Icon{
                                font-size: 1rem;
                            };
                        };
                    };
                };
            };
            
            .MenuBox{

                .Menu{
                    min-width: 0;
                    width: 25%;
                    box-sizing: border-box;

                    .TabBox{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        font-size: 0.8rem;
                        
                        .Label{
                            margin-top: 5px;
                        };
                    };

                    .Basic{
                        color: rgb(33,33,33);
                    }
                };
            };

            .TitleRow{
                padding: 10px 0;
                display: flex;
                
                .TitleThumbnail{
                    width: 90px;
                    height: 90px;
                    object-fit: cover;
                    border: 1px solid rgb(241, 241, 241);
                    box-sizing: border-box;
                }

                .TextCell{
                    margin-left: 16px;
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    position: relative;
                    overflow: hidden;
                    
                    .TitleName{
                        white-space: nowrap;
                        font-size: 0.9rem;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin-top: 1px;
                    };

                    .Description{
                        font-size: 0.75rem;
                        color: rgb(117, 117, 117);
                        overflow: hidden;
                        text-overflow: ellipsis;   
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        margin-top: 5px;
                        font-size: 0.75rem;
                    }

                    .TitleInformations{
                        display: flex;
                        align-items: center;
                        color: rgb(180,180,180);
                        font-size: 0.8rem;
                        position: absolute;
                        bottom: 1px;
                        
                        .FavoriteCount{
                            display: flex;
                            align-items: center;

                            .FavoriteIcon{
                                color: rgba(1,1,1,0.54);
                                font-size: 0.7rem;
                                margin-right: 5px;
                                margin-top: 2px;
                            };
                        };

                        .DividingLine{
                            margin: 0 8px;
                        };

                        .CreatedTime{
                            color: rgb(180,180,180);
                            font-size: 0.8rem;
                        };
                    };

                };
            };

            .SubscribedAuthorContainer{
                display: grid;
                grid-template-columns: 1fr 1fr;
                justify-content: center;

                @media (min-width: 701px) {
                    grid-template-columns: 1fr 1fr 1fr;                   
                }

                .AuthorBox{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 50px;
                    cursor: pointer;

                    .Image{
                        width: 110px;
                        height: 110px;
                        border-radius: 50%;
                        margin-bottom: 10px;
                    }
                };
            };

            .BookmarkDeleteButton{
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                color: red;
                font-size: 0.75rem;
                height: 40px;
                box-sizing: border-box;
                cursor: pointer;
            }
            
            .EpisodeRow{
                padding: 10px 0;
                display: flex;

                .TextCell{
                    margin-left: 10px;
                    margin-right: 10px;
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    position: relative;
                    overflow: hidden;
                    
                    .EpisodeTitle{
                        white-space: nowrap;
                        font-size: 0.9rem;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin-top: 1px;
                    };

                    .Description{
                        font-size: 0.75rem;
                        color: rgb(117, 117, 117);
                        overflow: hidden;
                        text-overflow: ellipsis;   
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        margin-top: 5px;
                        font-size: 0.75rem;
                    }

                    .Episodeformations{
                        display: flex;
                        align-items: center;
                        color: rgb(180,180,180);
                        font-size: 0.8rem;
                        position: absolute;
                        bottom: 1px;

                        .FavoriteCount{
                            display: flex;
                            align-items: center;

                            .FavoriteIcon{
                                color: rgba(1,1,1,0.54);
                                font-size: 0.7rem;
                                margin-right: 5px;
                                margin-top: 2px;
                            }
                        }
                        .CreatedTime{
                            color: rgb(180,180,180);
                            font-size: 0.8rem;
                        };
                    };

                    .DividingLine{
                        margin: 0 8px;
                    };
                };
                
                .DeleteAddButtonOfBookmark{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                };
            };

            .DesignationContainer:first-child{
                margin-top: 40px;
            }

            .DesignationContainer{
                display: flex;
                justify-content: center;

                .OpenedBox{
                    padding: 10px;
                    display: flex;
                    border: 1px solid rgb(241, 241, 241);
                    margin-bottom: 18px;
                    border-radius: 5px;   
                    min-width: 360px;

                    .Icon{
                        width: 50px;
                        height: 50px;
                    };

                    .TextBox{
                        display: flex;
                        flex-direction: column;
                        line-height: 24px;
                        margin-left: 20px;

                        .Description{
                            font-size: 0.8rem
                        };

                        .Name{
                            font-size: 0.9rem;
                            font-weight: bold;
                            color: rgb(0,176,240);
                        };
                    };
                };

                .ClosedBox{
                    padding: 10px;
                    display: flex;
                    border: 1px solid rgb(241, 241, 241);
                    margin-bottom: 18px;
                    border-radius: 5px;
                    background-color: rgb(247,247,247);
                    min-width: 360px;

                    .IconBox{
                        width: 50px;
                        height: 50px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .Icon{
                            width: 30px;
                            height: 30px;
                            color: rgb(75,75,75);
                        };
                    };

                    .TextBox{
                        display: flex;
                        flex-direction: column;
                        line-height: 24px;
                        margin-left: 20px;

                        .Description{
                            font-size: 0.8rem
                        };
                        
                        .Name{
                            font-size: 0.9rem;
                            font-weight: bold;
                            color: rgb(33,33,33);
                        };
                    };
                };

            };
        };
    };
};