.MoreButton {
    display: flex;
    margin: 64px auto 24px auto;
    justify-content: center;
    background-color: rgba(229, 233, 241, 1);
    border-radius: 10px;
    width: 180px;
    height: 44px;
    cursor: pointer;

    &:hover {
        background-color: rgba(217, 226, 244, 1);

    }
}