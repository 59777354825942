
.UploadScreenBackground{
    position: absolute;
    width: 100%; height: 100%;
    left: 0; top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(255,255,255);
    border-radius: 5px;

    .ScreenTitle{
        font-size: 0.938rem;
        margin: 4px 0 12px;
    }
    .ScreenSubTitle{
        font-size: 0.75rem;
        color: rgba(121, 120, 130, 1);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
    }

    .UploadScreen{
        position: absolute;
        width: 100%; height: 100%;
        left: 0; right: 0;
        background-color: transparent;
    }
}