
.EpisodeLikeArea{
    
    .SwiperArea{
        margin-top: 50px;
        position: relative;

        .swiper-wrapper{
            @media (max-width: 700px) {
                max-width: 150px;
            };
        };

        .swiper-slide-active{

            span:first-child{
                transform: scale(1.2);
            }
        };

        .swiper-slide:not(.swiper-slide-active){
            opacity: 0.3;
            transform: scale(0.8);
        };
    };

    .LoadingSwiper{
        .swiper-slide {
            opacity: 0.3;
            transform: scale(0.8);
        };
    };

    .RecommendBox{
        display: flex;
        justify-content: center;
        max-width: 100%;
        flex-wrap: wrap;

        .TitleBox{
            display: flex;
            flex-direction: column;
            color: rgb(51,51,51);
            margin-right: 16px;
            cursor: pointer;
            flex-wrap: wrap;

            &:last-child{
                margin-right: 0px;
            };

            .Thumbnail{
                width: 115px;
                height: 115px;
                border: 1px solid rgb(240,240,240);
                box-sizing: border-box;
            };

            .Title{
                margin-top: 10px;
                text-align: center;
                font-size: 0.8rem;

                width: 115px;
                overflow-x: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            };
        };
    };
};