
.PrizeSlideArea{
    width: 100%; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .PosterSlideContainer{
        width: 100%;
        box-sizing: border-box;
    }
    
    .SlideContainer{
        width: calc(100% - 20%);
        box-sizing: border-box;
        max-width: 639px;
        aspect-ratio: 1;

        display: flex;
        justify-content: center;
        background-color: rgba(0,128,233,1);
        
        .PrizeSlideInner{
            display: flex;
            flex-direction: column;
            max-width: 70%;
            border-radius: 15px;
            overflow: hidden;
            background-color: rgb(255,255,255);

            .ButtonBox{
                display: flex;
                justify-content: center;
                padding-bottom: 7%;

                .SlideButton{
                    background-color: rgb(39, 158, 255);
                    color: rgba(255, 255, 255, 1);
                    display: flex;
                    padding: 10px 5%;
                    justify-content: center;
                    align-items: center;
                    
                    font-feature-settings: 'clig' off, 'liga' off;
                    font-family: Pretendard;
                    font-size: 1.5rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;

                    @media (max-width: 700px) {
                        font-size: 1rem;
                        padding: 6px 10px;
                    }
                }
            }
        }
    }

    .Pagination{
        padding-top: 30px;
        .swiper-pagination-bullet{
            background: rgb(255, 255, 255);
            opacity: 1;
        }
        .swiper-pagination-bullet-active{
            background: rgba(65, 244, 255, 1);
        }
    }
}