.TabMenu{
    padding: 16px 40px 0px 40px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    gap: 32px;
    position: relative;
    border-bottom: 1px solid rgba(237, 236, 238, 1);
    background-color: rgba(242, 243, 247, 1);

    @media (max-width: 430px){
        padding-top: 8px;
    }

    .TabItem{
        box-sizing: border-box;
        padding: 10px 4px 16px 4px;
        font-family: inherit;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        color: rgba(158, 157, 164, 1);
        cursor: pointer;
        white-space: nowrap;

        &.Active {
            color: rgb(25,24,27);
        }
    }

    .BottomIndicator {
        position: absolute;
        height: 2px;
        background-color: black;
        bottom: 0px;

        transition: 200ms ease-in-out;
    }
}