.ArtsEdit {
    position: relative;
    @media (max-width: 700px) {
        width: calc(100% - 60px);
        .PCHeader {
            display: none;
        }
    }

    @media (min-width: 701px) {
        width: calc(50% - 60px);
        margin-top: 0;
        font-size: 14px;
        .PCHeader {
            //
        }
    }


    min-height: 60vh;
    flex-shrink: 0;

    padding-left: 30px; padding-right: 30px;
    margin-top: 30px;
    padding-bottom: 80px;

    .ArtsContainer {
        width: 100%;
        max-width: 400px;

        margin-left: auto; margin-right: auto;
    }

}