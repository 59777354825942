


.EnrollEpisodeArea{
    display: flex;
    justify-content: center;
    background-color: rgb(242, 243, 247);

    .EnrollInfoContainer{
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;

        height: 42px;
        padding: 12px 16px;
        box-sizing: border-box;

        border-radius: 4px;

        color: rgb(255,255,255);
        background-color: rgb(224, 226, 235);

        .IconWrapper{
            height: 100%;
            
            display: flex;
            align-items: center;

            padding-right: 8px;

            .InfoIcon{
                width: 18px;
                height: 18px;

                // color: rgb(60, 70, 81);
                filter: brightness(0) saturate(100%) invert(24%) sepia(10%) saturate(966%) hue-rotate(171deg) brightness(95%) contrast(88%);
            }
        }
        .InfoText{
            color: rgb(61, 71, 82);
            font-size: 0.75rem;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0.12px;
            word-wrap: break-word;
            white-space:pre;
            
            &.Strong{
                font-weight: 700;
                color: rgb(61, 106, 255);
                cursor: pointer;
            }            
        }
    }

    .BodyContainer{
        max-width: 720px;
        width: 100%;
        padding-bottom: 60px;
        box-sizing: border-box;
        -webkit-user-select: none;
        user-select: none;
        min-height: calc(100vh - 80px);
        
        .EnrollEpisodeForm{
            margin-bottom: 20px;
            padding-top: 20px;
            display: flex;
            flex-direction: column;
            gap: 24px;

            &.Disabled{
                .FormCard{
                    pointer-events: none;
                }
            }

            .EpisodeIdField{
                padding-top: 20px;
            }

            .EpisodeTitle{
                padding-top: 20px;
                margin-top: 20px;

                .CharCounter{
                    color: lightgray;
                    text-align: end;
                    font-size: 0.9rem;
                }
            }

            .ThumbnailBox{
                border-radius: 4px;
                border: 1px solid rgba(0,0,0,0.23);
                position: relative;
                margin-top: 20px;
                padding: 10px;
            }

            .FormCard{
                background-color: rgb(255,255,255);
                padding: 40px 30px;
                display: flex;
                flex-direction: column;
                gap: 32px;
                border-radius: 5px;

                @media (max-width: 720px) {
                    padding: 32px 10px;
                }

                .ManuscriptButtonWrapper{
                    margin: 30px 0 5px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-end;
    
                    gap: 10px;
                    
                    .Button{
                        padding: 3px 8px;
                        background-color: rgb(241,241,241);
                        color: rgb(51,51,51);
                        font-size: 0.8rem;
                    }
                }
            }

            .HorizontalLine{
                width: 100%;
                height: 1px;
                background-color: rgb(213, 213, 216);
                border: 0;
            }
        }

        .Footer{
            position: sticky;
            bottom: 0px;

            display: flex;
            justify-content: center;

            width: 100%;

            margin-top: 14px;
            padding: 10px 0;
            
            background-color: rgb(242, 243, 247);

            &.Editing{
                justify-content: space-around;
            }

            @media (max-width: 720px) {
                position: relative;
                margin-bottom: 47px;
            }

            .Button{
                display: inline-flex;
                gap: 4px;
                justify-content: center;
                align-items: center;
                border-radius: 40px;
                padding: 12px 10%;
                cursor: pointer;
                position: relative;

                font-size: 1rem;

                &.SubmitButton{
                    padding: 12px 46px;
                    background-color: rgb(61, 106, 255);
                    color: rgb(255, 255, 255);
                    
                    &:disabled{
                        opacity: 0.32;
                    }
                }
                &:active::after{
                    border-radius: 10px;
                    content: '';
                    position: absolute; top: 0; left: 0;
                    width: 100%; height: 100%;
                    background-color: rgba(255,255,255, 0.12);
                }
                &.Delete{
                    color: red;
                    border: 1px solid red;
                }
                &.Cancel{
                    background-color: white;
                    border: 1px solid rgb(0,176,240);
                    color: rgb(0,176,240);
                }
                &.EditCancel{
                    border: 1px solid rgba(0,0,0,0.54);
                    background-color: white;
                }
                &.Modify{
                    background-color: rgba(61, 106, 255, 1);
                    color: rgb(255,255,255);
                }
            }
        }

        .NotFoundArea{
            padding-top: 126px;
        }
    }
}

.EnrollDialog{
    .ThumbnailTip{
        font-size: 0.9rem;
        padding: 16px 0 10px;

        .StrongText {
            color: rgb(0,176,240);
            font-weight: bold;
            margin-right: 16px;
        }
    }
    .ActionButtonWrapper {
        display: flex;
        justify-content: center;
        
        .SecondaryActionButton{
            width: 80px;
            padding: 6px 12px;
        }
        .BlackColor{
            color: rgb(0, 0, 0);
        }
    }
}