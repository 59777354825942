$SIGNATURE_COLOR : rgb(0,176,240);

.QnATemplate{
    padding-bottom: 150px;
    background: linear-gradient(to bottom, rgba(211,230,254,1) 0%, rgba(211,230,254,0.7) 51%, rgba(80, 176, 234, 0.3) 100%);
    padding-top: 150px;

    .QnABody{
        max-width: 842px;
        margin: auto;
        padding:0px 20px;
        vertical-align: top;
        
        .TitleTexts{
            display: flex;
            flex-direction: row;
            justify-content: stretch;
            align-items: flex-end;

            .MainTitle{
                font-size: 2.5rem;
            }

            .SubTitle:hover{
                color: $SIGNATURE_COLOR;
            }
        }

        .QuestionBoard{
            padding-top: 20px;

            .QuestionLine{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-end;
                height: 45px;
                padding-bottom: 20px;
                margin-top: 25px;
                border-bottom: 2px solid rgb(0,0,0);
                font-size: 1.2rem;
                font-weight: 700;
                cursor: pointer;

                .QuestionText{
                    word-break: keep-all;
                }
                
                .ArrowDownIcon{
                    transition: 200ms;


                    &.selected{
                        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(180deg) skew(0deg, 0deg);
                        transform-style: preserve-3d;
                    }
                }
            }

            .ContentText{
                padding: 20px 0px;
                font-size: 1rem;
                line-height: 1.5rem;
                font-weight: 400;
                color: rgb(0,0,0);
            }
        }       

        .Button {
            width: calc(100% - 20px);
            max-width: 390px;
            margin: auto;

            background-color: rgb(61,106,255);
            color: white;
            font-size: 1.5rem;

            &:active, &:hover {
                background-color: rgb(61,106,255);
            }

            &:active {
                filter: brightness(0.8);
            }
            
            &:hover {
                filter: brightness(0.9);
            }
        }
    }
}
