
.PlatformRepresentativesArea{
    width: calc(100% - 100px);
    padding: 30px 50px;
    background-color: rgb(255,255,255);
    margin: 30px 0;
    border-radius: 10px;

    @media(max-width: 700px) {
        width: calc(100% - 32px);
        padding: 16px;
        margin: 10px 0;
    }

    .Title{
        font-size: 1.25rem;
        font-weight: bold;
        margin-bottom: 32px;

        .SearchText{
            color: rgb(0,176,240);
        }
    }

    .RepresentativeWebtoons{
        display: flex;
        flex-direction: column;

        .WebtoonRow{
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid rgb(242, 242, 242);

            &:last-child{
                margin-bottom: 0;
                border-bottom: 0;
                padding-bottom: 0;
            }

            .VisibleInfomation{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 14px;
                text-decoration: none;
                color: inherit;
    
                .Thumbnail{
                    position: relative;
                    width: 100%; height: 100%;
                    max-width: 100px;
                    max-height: 100px;
                }
    
                .WebtoonInformations{
                    width: calc(100% - 100px);
                    padding-left: 20px;
    
                    .TitleInfo{
                        font-weight: bold;
                        margin-bottom: 8px;
                    }
    
                    .DescriptionInfo{
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        margin-bottom: 8px;
                        font-size: 0.875rem;
                        color: rgb(117, 117, 117);
                    }
                }
            }

            .KeywordsInfomation{
                display: flex;
                flex-wrap: wrap;
                font-size: 0.81rem;

                .Keyword {
                    padding: 8px 12px;
                    border-radius: 32px;
                    background-color: rgb(248, 248, 248);
                    margin: 0 6px 0 0;
                    cursor: pointer;
                    text-decoration: none;
                    color: inherit;
                }
            }
        }
    }
}