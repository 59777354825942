.Univ2024LandingPage{
    min-height: 100vh;
    background-color: rgba(242, 243, 247, 1);

    .Univ2024LandingContent{
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 56px;
        padding-top: 30px;

        @media (max-width: 1080px){
            flex-direction: column;
            align-items: center;
            gap: 0;

            padding-bottom: 60px;
        }

        .ContestContent{
            max-width: 680px;
            box-sizing: border-box;
            width: 100%;
            padding: 0px 16px;

            display: flex;
            flex-direction: column;
            gap: 32px;
            
            img{
                width: 100%;
                max-width: 680px;
            }

            .PosterArea{
                display: flex;
                flex-direction: column;


                .PosterBox{
                    padding-bottom: 50px;
                    display: flex;
                    justify-content: center;
                    background-color: rgba(0,128,233,1);
                }

                .Univ2022Result{
                    display: flex;
                    padding: 24px 0px 24px;
                    justify-content: center;
                    align-items: center;
                    flex: 1 0 0;
                    background-color: rgba(0,128,233,1);

                    .Univ2022Winners{
                        display: flex;
                        border-radius: 8px;
                        min-width: 120px;
                        max-width: 200px;
                        padding: 10px 16px;
                        justify-content: center;
                        align-items: center;
                        flex: 1 0 0;
                        font-family: inherit;

                        color: rgb(255,255,255);
                        border: 1px solid rgb(255,255,255);
                    }
                }

                .PrizeAnnouncement{
                    display: flex;
                    flex-direction: column;
                        
                    background-color: rgba(0,128,233,1);
                    padding: 0 32px 80px;
                    box-sizing: border-box;

                    img {
                        -webkit-user-select:none;
                        -moz-user-select:none;
                        -ms-user-select:none;
                        user-select:none;
                    }

                    .MainPrizeBox, .SpecialPrizeGroup>.SpecialPrizeBox{
                        display: flex;
                        flex-direction: column;

                        .ButtonBox{
                            padding-bottom: 170px;

                            display: flex;
                            justify-content: center;

                            .VisitButton{
                                width: calc(100% - 13.5%);;
                                
                                border-radius: 8px;
                                background: #FFF;
                                box-shadow: 0px 4px 64px 0px rgba(255, 255, 255, 0.25);

                                color: rgba(39,158,255,1);
                                font-feature-settings: 'liga' off, 'clig' off;
                                font-size: 1.875rem;
                                font-weight: 700;
                            }
                        }
                    }

                    .SpecialPrizeGroup{
                        padding-bottom: 170px;
                        display: flex;
                        flex-direction: column;
                        gap: 90px;

                        .SpecialPrizeBox{
                            display: flex;
                            flex-direction: column;
                            .ButtonBox{
                                padding: 0;
                            }
                        }
                        &.Last{
                            padding-bottom: 0;
                        }
                    }

                    .SliderBackground{
                        padding: 90px 0;
                        display: flex;
                        justify-items: center;

                        background-color: rgba(0,128,233,1);

                        @media (max-width: 700px) {
                            padding-bottom: 60px;
                        }
                    }

                    .GenderTab{
                        display: flex;
                        justify-content: center;

                        .GenderSwitchBox{
                            border-radius: 53px;
                            background-color: rgba(255, 255, 255, 0.48);

                            display: flex;
                            justify-content: space-between;

                            .SwitchButton{
                                padding: 13px 45px;
                                border-radius: 53px;

                                color: rgba(39, 158, 255, 1);
                                font-size: 30px;
                                font-weight: 500;

                                cursor: pointer;
    
                                &:first-child:not(.Selected){
                                    padding-right: 13px;
                                }
                                &:nth-child(2):not(.Selected){
                                    padding-left: 13px;
                                }
                                &.Selected{
                                    background-color: rgba(255, 255, 255, 1);
                                }
                            }
                        }
                    }

                    .GenderPickBoard{
                        padding: 45px 0px 100px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 62px;

                        .Row{
                            width: calc(100% - 13.5%);;
                            display: flex;
                            flex-direction: column;
                            gap: 20px;

                            .Title{
                                color: rgb(255,255,255);
                                font-size: 1.875rem;
                                font-weight: 600;
                            }
                            .WebtoonBox{
                                display: flex;
                                justify-content: space-evenly;
                                gap: 12px;

                                .Webtoon{
                                    flex: 1;

                                    display: flex;
                                    flex-direction: column;
                                    gap: 17px;

                                    cursor: pointer;

                                    &>img {
                                        width: 100%;
                                        border-radius: 7px;
                                    }

                                    .InfomationBox{
                                        display: flex;
                                        flex-direction: column;
                                        gap: 4px;

                                        .WebtoonTitle, .Author{
                                            letter-spacing: -1px;
                                            color: rgb(255,255,255);
                                            font-size: 1.25rem;
                                            
                                            overflow: hidden;
                                            text-overflow: ellipsis;   
                                            display: -webkit-box;
                                            -webkit-line-clamp: 1;
                                            -webkit-box-orient: vertical;
                                        }
                                        .WebtoonTitle{
                                            font-weight: 600;
                                        }
                                        .Author{
                                            font-weight: 400;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .ButtonArea{
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 24px 0px;
                border-top: 1px solid rgba(213, 213, 216, 1);

                span{
                    color: rgba(255, 255, 255, 1);
                    font-size: 0.9375rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px; /* 160% */
                    letter-spacing: 0.15px;
                    white-space: nowrap;
                }

                img{
                    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7488%) hue-rotate(97deg) brightness(98%) contrast(86%);
                }
            }
        }
    
        .ContestPromotion{
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding-top: 8px;

            @media (max-width: 1080px){
                position: fixed;
                bottom: 0;
                z-index: 1;

                flex-direction: row-reverse;
                justify-content: center;
                align-items: center;
                gap: 8px;
                
                box-sizing: border-box;
                padding: 8px 16px;
                width: 100%;

                background-color: rgba(255, 255, 255, 1);
            }
    
            .ContestPromotionPhrase{
                display: flex;
                flex-direction: column;
                gap: 8px;
    
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 400;
                line-height: 28px;
                letter-spacing: 0.24px;
    
                :last-child{
                    font-size: 1.5rem;
                    font-weight: 600;
                    line-height: 32px;
                }

                @media (max-width: 1080px){
                    display: none;
                }
            }
    
            .PromotionButton{
                box-sizing: border-box;
                width: 360px;
                padding: 13px 16px;
                display: flex;
                justify-content: center;
                align-items: center;
    
                border: none;
                border-radius: 8px;
                
                @media (max-width: 1080px){
                    min-width: 120px;
                    max-width: 200px;
                    padding: 10px 16px;

                    font-size: 0.9375rem;
                }

                span{
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px; /* 160% */
                    letter-spacing: 0.15px;
                }
            }
        }

        .NoticeBox{
            display: flex;
            flex-direction: column;
            gap: 12px;

            &.pc{
                max-width: 360px;
                @media ( max-width: 1080px ) {
                    display: none;
                }
            }
            &.mobile{
                padding: 20px 10px 0;
                @media ( min-width: 1080px ) {
                    display: none;
                }
            }

            .NoticeArticle{
                display: flex;
                flex-direction: column;
                gap: 4px;

                .NoticeTitle{
                    color: rgba(121, 120, 130, 1);
                    font-family: Pretendard;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 18px;

                    .HyperLink{
                        color: rgba(25, 77, 229, 1);
                    }
                }

                .NoticeDescription{
                    word-break: keep-all;
                    color: rgba(158, 157, 164, 1);
                    font-family: Pretendard;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px; /* 150% */
                }
                .NoticeContent{
                    word-break: keep-all;
                    color: rgba(158, 157, 164, 1);
                    font-family: Pretendard;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px;

                    li, ul{
                        padding: 0;
                        margin: 0;
                    }
                    li{
                        margin-left: 15px;
                    }
                }
                .ExampleImages{
                    display: flex;
                    padding: 0 15px;
                }
            }
        }
    }
}