
$SIGNATURE_COLOR: rgb(0,176,240);

.WebtoonScoreTemplate{
    padding-top: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    word-break: keep-all;
    padding-bottom: 100px;

    .TemplateMainBody{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 824px;
    
        @media (max-width: 720px) {
            width: calc(100% - 40px);
        }



        .TitleBox{
            width: 100%;
            padding-bottom: 80px;


            .Title{
                margin-bottom: 10px;
                font-size: 2.5rem;
                font-weight: 700;
                text-align: center;

                @media (max-width: 720px){
                    font-size: 2rem;
                }

                span.SignatureText{
                    color: $SIGNATURE_COLOR;
                }
            }

            .TextLine{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: stretch;
                font-size: 1.2rem;
                text-align: center;

                @media (max-width: 720px){
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
            }

            ul{
                list-style-type: square;
                font-size: 1.2rem;
                padding-left: 0px;

                @media(max-width: 720px){
                    font-size: 1rem;
                }

                li{
                    list-style-type: none;
                    text-align: center;
                }
            }

        }

        .Explanation{
            width: 100%;

            display:flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-start;
        
            .Paper{
                box-sizing: border-box;
                width: 220px;
                min-height: 320px;

                margin: 0 20px 40px 20px;
                padding: 4px;
                
                border-radius: 16px;

                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                
                text-align: center; 
                background-color: rgb(255, 255, 255);
                backdrop-filter: blur(12px);
                border: 1px solid rgba(255, 255, 255, 0.18);
                box-shadow: 10px 10px 30px 5px rgba(12, 22, 39, 0.04), 0px 4px 4px 0px rgba(12, 22, 39, 0.02);

                :not(:last-child) {
                    margin-bottom: 20px;
                }

                .PaperTitle{
                    font-size: 1.2rem;
                    line-height: 1.75rem;
                    font-weight: 600;
                    letter-spacing: 0.0125rem;
                    
                    height: 50px;
                    margin: 36px 0;
                    white-space: nowrap;
                    
                    text-align: center;
                }
                
                .PaperIcons{
                    width: 68px; height: 68px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    margin: 12px 0 28px 0;
                }
                .PaperText{
                    font-size: 1rem;
                    color: rgb(121, 120, 130);
                    min-height: 4.5rem;
                    margin: 12px 0;

                }

                .ExtraText {
                    font-size: 0.875rem;
                    font-weight: bold;
                    color: rgb(25, 24, 27);
                    margin-bottom: 0;
                     
                    img {
                        object-fit: contain;
                        margin: 10px 10px 0 10px;
                        width: 170px; height: 87px;
                    }
                }

                .ExtraTextIcon { 
                    font-size: 0.8rem;
                    padding: 3px;
                    width: 20px;
                    height: 20px;
                    transform-origin: top;
                }

                span{
                    color: $SIGNATURE_COLOR;
                }

                &.Union{
                    border-radius: 16px 16px 0px 0px;
                }
            
            }
        }

        .IntroducingReport{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-width: 824px;

            @media(max-width: 720px){
                min-width: 0px;
            }

            .Introductions{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-top: 20px;
                cursor: pointer;
                .IntroductionText{
                    word-break: keep-all;
                }
            }
        }
    }
}