.ApplicationForm{
    max-width: 720px;
    width: 100%;
    padding-bottom: 60px;
    box-sizing: border-box;
    user-select: none;
    min-height: calc(100vh - 60px);

    @media (max-width: 720px) {
        max-width: 375px;
        width: 100%;
        min-height: 0;

        padding: 0px 16px 40px 20px;
    }

    .ServiceEndDisclaimer {
        text-align: center;
        line-height: 2rem;

        height: 2rem;
        margin-top: 1rem;
        padding: 1rem 0;

        border-radius: 0.5rem;
        background-color: rgba(233, 155, 155, 0.2);

        font-weight: 500;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        svg {
            fill: rgba(233, 55, 55, 1);
            width: 1.5rem;
            height: 1.5rem;
        }
    }
    .Stepper{
        z-index: 1;
        position: sticky;
        top: 0;
        display: flex;
        flex-direction: row;
        gap: 16px;

        height: 76px;
        padding: 30px 0 20px 0;

        background-color: rgba(242, 243, 247, 1);

        .Step{
            display: flex;
            flex-direction: column;
            gap: 4px;

            box-sizing: border-box;
            width: 168px;
            padding: 12px 16px;
            border: 2px solid rgba(224, 226, 235, 1);
            border-radius: 15px;

            color: rgba(121, 120, 130, 1);
            background-color: rgba(224, 226, 235, 1);
            
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;

            cursor: pointer;

            &.Current{
                border: 2px solid rgba(61, 106, 255, 1);
                background: rgba(255, 255, 255, 1);
                color: rgba(25, 24, 27, 1);
            }
            
            .Content{
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                font-weight: 600;
            }

        }

        @media (max-width: 720px) {
            display: none;
        }
    }

    .Form{
        display: flex;
        flex-direction: column;
        gap: 24px;

        padding-top: 20px;
        
        .SectionHeader{
            display: none;

            color: rgba(25, 24, 27, 1);
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0.16px;

            @media (max-width: 720px){
                display: flex;
                flex-direction: column;
                gap: 16px;
            }
        }

        .BasicInfo, .WebtoonInfo, .ManuscriptInfo, .AdCutInfo{
            background-color: rgb(255,255,255);
            padding: 40px 30px;
            display: flex;
            flex-direction: column;
            gap: 32px;
            border-radius: 5px;
            
            .InfoLine{
                width: 100%;
                height: 1px;
                background-color: rgba(237, 236, 238, 1);
                border: 0;
                margin: 0;
            }

            @media (max-width: 720px) {
                padding: 32px 10px;
            }

            .AdsPreviewPaper{
                font-family: inherit;    
                background-color: white;
                border: 0;
                padding: 0;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
                letter-spacing: 0.13px;

                &.Disabled{
                    color: rgba(220,220,220,1);
                }
            }
        }

        .ManuscriptInfoContainer{
            display: flex;
            flex-direction: column;
            gap: 24px;
        }

        
        .AdditionalEpisodeButton{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
            position: relative;
            
            padding: 12px 0;
            border-radius: 5px;
            border: 1px solid rgba(137, 85, 246, 1);
            background: rgb(255,255,255);
            cursor: pointer;

            &.Cancel{
                background-color: rgba(224, 226, 235, 1);
                border: 0;
            }

            &:active::after{
                border-radius: 5px;
                content: '';
                position: absolute; top: 0; left: 0;
                width: 100%; height: 100%;
                background-color: rgba(137, 85, 246, 0.12);
            }
            &.Cancel:active::after{
                border-radius: 5px;
                background-color: rgba(121, 120, 130, 0.12);
            }

            .EpisodeMoreText{
                color: rgba(137, 85, 246, 1);
                text-align: center;
                font-size: 0.9375rem;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0.15px;
            }
            .CancelEpisodeMoreText{
                color: rgba(121, 120, 130, 1);
                text-align: center;
                font-size: 0.9375rem;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0.15px;
            }

            .SpeechBalloon{
                display: inline-flex;
                position: absolute;
                top: -4px; left: 50%;
                transform: translate(-50%, -100%);
            
                width: 283px;
                justify-content: center;
                .BalloonContent{
                    position: absolute;
                    top: 12px; left: 16px;
                    color: rgb(255,255,255);
                    
                    font-size: 0.813rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 16px; /* 123.077% */
                    letter-spacing: 0.13px;
                }

                animation-name: fadeIn, fadeOut;
                animation-duration: 0.5s, 0.5s;
                animation-delay: 0s, 3.5s;
                animation-fill-mode: forwards;
            }
        }

        .CheckBoxRow{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 8px;
            cursor: pointer;

            .CheckBoxLabel{
                color: rgba(189, 189, 194, 1);
                font-size: 0.875rem;
                cursor: pointer;
            }

            .Checkbox{
                width: 18px; height: 18px;
                border-radius: 3px;
            }
            .checked{    
                background: rgba(121, 120, 130, 1) url(https://static.webtoon.today/ddah/icon/icon_checkmark.svg) center/1rem no-repeat;
            }
            .notChecked{
                box-sizing: border-box;
                border: 1px solid rgba(121, 120, 130, 1);
                background-color: rgba(246, 246, 249, 1);
            }
        }
    }

    .HorizontalLine{
        width: 100%;
        height: 1px;
        background-color: rgba(213, 213, 216, 1);
        border: 0;
    }

    .ButtonArea{
        display: flex;
        justify-content: center;

        .ApplyButton{
            display: inline-flex;
            position: relative;
            gap: 4px;
            justify-content: center;
            align-items: center;
            background-color: rgba(25, 24, 27, 1);
            border-radius: 10px;
            padding: 12px 46px;
            cursor: pointer;

            &:active::after{
                border-radius: 10px;
                content: '';
                position: absolute; top: 0; left: 0;
                width: 100%; height: 100%;
                background-color: rgba(255,255,255, 0.12);
            }

            .ButtonName{
                color: rgb(255,255,255);
                font-size: 1rem;
                font-style: normal;
                font-weight: 600;
                line-height: 24px; /* 150% */
                letter-spacing: 0.16px;
            }
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        pointer-events: none;
    }
}