
.TextAreaComponent{
    display: flex;
    flex-direction: column;
    gap: 8px;
    
    .TextArea{
        outline: 1px solid rgb(236, 237, 243);
        border: 0;
        border-radius: 5px;
        box-sizing: border-box;
        padding: 10px;
        font-family: inherit;

        &:hover{
            outline: 1px solid rgba(61, 106, 255, 1);
        }
        &:focus{
            outline: 2px solid rgba(61, 106, 255, 1);
        }
        &.Disabled{
            border: 1px solid rgba(213, 213, 216, 1);
            background: linear-gradient(0deg, rgba(138, 138, 138, 0.25) 0%, rgba(138, 138, 138, 0.25) 100%), rgba(246, 246, 249, 1);
        }
    }
    .NumberOfTexts{
        color: #797882;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.15px;

        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}