$BASIC_SPACE: 8px;
$BASIC_RADIUS: 15px;
$SIGNATURE_COLOR: rgb(0, 176, 240);


.ApplySubRoot{
    padding: 150px 0;
    position: relative;
    background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(252, 252, 252,1) 5%, rgba(251, 252, 252,1) 80%, rgba(255,255,255,1) 100%);
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;

    .TeamTitle{
        position: relative;
        margin-bottom: 50px;
        height: 100%;
        text-align: left;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media (max-width: 720px) {
            width: calc(100% - 40px);
        }

        .ApplyTitle{
            font-size: 2.5rem;
            font-weight: bold;
            word-break: keep-all;
            padding: 0px;
            margin-bottom: 10px;
            
            @media(max-height: 720px){
                font-size: 2rem;
            }
        }

        .TitleFlex{
            
            text-align: center;
            font-size: 1.5rem;
            word-break: keep-all;
            
            span.Blue{
                margin: 0 0 0 0;
                color: $SIGNATURE_COLOR;
            }
        }
    }

    .Section {
        width: 100%;
        max-width: 884px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        .BenefitCell {
            display: flex;
            flex-direction: row;
            width: 100%;
            max-width: 380px;
            margin-bottom: 80px;
            word-break: keep-all;

            @media (max-width:720px) {
                width: 100%;
                margin: 0 auto 80px auto; 
            }

            .Icon {
                width: 36px; height: 36px; margin-right: 8px;
            }

            .Benefit {
                display: flex;
                flex-direction: column;

                .Name {
                    font-size: 1.75rem;
                    font-weight: 600;
                    line-height: 2.25rem;
                    margin-bottom: 16px;
                }

                .Summary {
                    font-size: 1.5rem;
                    font-weight: 500;
                    line-height: 2.25rem;
                    color: rgba(121, 120, 130, 1);
                }
            }
        }
    }

    .Button {
        width: calc(100% - 20px);
        height: 68px;
        max-width: 390px;
        margin: auto;

        background-color: rgb(61,106,255);
        color: white;
        font-size: 1.5rem;

        &:active, &:hover {
            background-color: rgb(61,106,255);
        }

        &:active {
            filter: brightness(0.8);
        }
        
        &:hover {
            filter: brightness(0.9);
        }
    }

}

