$BASIC_SPACE: 8px;
$BASIC_RADIUS: 15px;
$SIGNATURE_COLOR: rgb(0, 176, 240);


.MemberSubRoot{
    padding: 150px 0;
    position: relative;
    background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(252, 252, 252,1) 5%, rgba(251, 252, 252,1) 80%, rgba(255,255,255,1) 100%);
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;

    .MemberTitle{
        position: relative;
        margin-bottom: 50px;
        height: 100%;
        text-align: left;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media (max-width: 720px) {
            width: calc(100% - 40px);
        }

        .TeamTitle{
            font-size: 2.5rem;
            font-weight: bold;
            word-break: keep-all;
            padding: 0px;
            margin-bottom: 10px;
            
            @media(max-height: 720px){
                font-size: 2rem;
            }
        }

        .TitleFlex{
            
            text-align: center;
            font-size: 1.5rem;
            word-break: keep-all;
            
            span.Blue{
                margin: 0 0 0 0;
                color: $SIGNATURE_COLOR;
            }
        }

    }

    .MuiContainer-root.MuiContainer-maxWidthLg{
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .Section{
        text-align: center;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        width: 100%;
        max-width: calc(244px * 3);

        .CardBox{
            padding: 20px 20px 0px 20px;

            .Cards{
                width: 200px;
                background-color: inherit;

                .Characters{
                    height: 150px;
                    object-fit: contain;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: flex-end;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        object-position: center;
                    }
                }

                .Introduction{
                    max-width: 100%;

                    .JobPositions{
                        display: flex;
                        flex-direction: row;
                        align-items: flex-end;
                        justify-content: center;
                        margin-top: 10px;

                        .Position{
                            font-size: 0.9rem;
                            color:rgba(158, 158, 158, 1);
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;

                            &.Hidden {
                                color: rgba(0,0,0,0);
                            }
                        }
                        .Name{
                            font-size: 1.2rem;
                            font-weight: bold;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
            
        }
    
    }

}


.ModalCards{
    width: 300px;
        
    @media(max-width: 720px){
        width: auto;
    }
    
    .Characters{
        height: 260px;
        background-color: rgba(226, 226, 226, 0.3);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;

        img {
            height: 100%;
            object-fit: contain;
        }
    }

    .Introduction{
        max-width: 100%;

        .JobPositions{
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: center;
            margin-top: 10px;

            .Position{
                font-size: 0.9rem;
                color:rgba(158, 158, 158, 1);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &.Hidden {
                    color: rgba(0,0,0,0);
                }
            }
            .Name{
                font-size: 1.2rem;
                font-weight: bold;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .UlStyles{     
            padding-left: 10px;
            margin: 0px;
            list-style: none;

            li{
                display: grid;
                grid-template-columns: auto auto 1fr;
                padding: 10px 0px;
            };

            .SubText{
                font-style: italic;
                font-size: 0.5rem;
                text-align: left;
                padding-top: 3px;
                padding-right: 7px;
                min-width: 37px;
                width: 37px
            }

            .Context{
                white-space: nowrap;
                overflow-x: hidden;
                text-overflow: ellipsis;
                text-align: left;

                @media (max-width: 720px) {
                    font-size: 0.875rem;
                };

                .SubContext{
                    white-space: nowrap;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                    text-align: left;
                }

                &.Multiline{
                    line-height: 25px;
                }

                .Examples{
                    font-size: 0.9rem;
                }
            }
        }
    }
    
    .CardIcon{
        width: 250px;
        
        @media(max-width: 720px){
            width: calc(100% - 20px);
            object-fit: contain;
        }
    }

    
}
    
