
.BasicTrendChartArea{
    width: calc(100% - 100px);
    padding: 30px 50px;
    background-color: rgb(255,255,255);
    margin: 30px 0;
    border-radius: 10px;

    @media(max-width: 700px) {
        width: calc(100% - 32px);
        padding: 16px;
        margin: 10px 0;
    }
    
    .Title{
        font-size: 1.25rem;
        font-weight: bold;

        .SearchText{
            color: rgb(0,176,240);
        }
    }

    .SubTitle{
        margin: 5px 0 15px;
        color: rgb(163, 163, 163);
        font-size: 1rem;
    }

    .ChartSpace{
        width: 100%;
        height: 300px;
    }
}