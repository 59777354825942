$MARGINUNIT: 16px;


.SearchResultMainArea{
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    box-sizing: border-box;

    line-height: 24px;

    .SearchResult{
        margin-bottom: 16px;
        display: flex;
        flex-direction: row;
        cursor: pointer;

        .ThumbnailImageBox{
            width: 90px;
            height: 90px;
            margin-right: 20px;
            border: 1px solid rgba(1,1,1,0.06);
            box-sizing: border-box;
        }

        .ResultContainer {
            display: flex;
            flex-direction: column;
            flex: 1;
            overflow: hidden;

            .Body{
                flex: 1;
                display: flex;
                flex-direction: column;
                box-sizing: border-box;
    
                .Title{
                    height: 24px;
                    font-size: 0.9rem;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
    
                .Description{
                    font-size: 0.75rem;
                    color: rgb(117,117,117);
                    word-break: break-all;
                    overflow: hidden;
                    text-overflow: ellipsis;   
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }
    
            .SearchResultFooter{
                height: 24px;
                color: rgb(117,117,117);
                display: flex;
                align-items: center;
                font-size: 0.75rem;
    
                .Favorites{
                    display: flex;
                    align-items: center;
    
                    .Icon{
                        width: 11px;
                        height: 11px;
                        position: relative;
                        bottom: -1px;
                        margin-right: 7px;
                    }                    
                }
    
                .DividingLine{
                    color: rgb(196, 196, 196);
                    margin: 0 8px;
                }
    
            }
        }
    }

    .NoSearchResultBox{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        margin-top: 55px;

        .ResultText{
            margin-top: 20px;
            font-size: 0.9rem;
        }

        .SuggestionText{
            margin-top: 5px;
            font-size: 0.75rem;
            color: rgb(117,117,117);
        }
    }
}