$MARGINUNIT: 16px;

.QnAMainArea{
    max-width: 600px;
    margin: 0 auto;

    .QnABody{
        padding-top: 120px;
        padding-bottom: 120px;

        .BodyTitle{
            padding: 0 $MARGINUNIT * 2;
            font-weight: bold;
        }
            
        .QuestionBoard{
            padding: 0 $MARGINUNIT * 2;

            .QuestionLine{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 5px;
                margin-top: $MARGINUNIT * 2;
                border-bottom: 1px solid rgb(0,0,0);
                font-size: 0.9rem;
                cursor: pointer;

                .QuestionText{
                    word-break: keep-all;
                }
                
                .ArrowDownIcon{
                    transition: 200ms;


                    &.selected{
                        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(180deg) skew(0deg, 0deg);
                        transform-style: preserve-3d;
                    }
                }
            }

            .ContentText{
                padding: 20px 0px;
                font-size: 0.8rem;
                font-weight: 400;
                color: rgb(0,0,0);
            }
        }
    }

    .QnAFooter{
        position: fixed;
        bottom: $MARGINUNIT * 4;
        max-width: 600px;
        display: flex;
        justify-content: center;
        width: 100%;
        background-color: rgb(255,255,255);
        padding-bottom: 10px;
    }
}