.BasicTemplateContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media (min-width: 701px) {
        display: flex;
        justify-content: flex-start;
        width: 360px;
    }

    .AuthorName{
        text-align: center;
        font-size: 1rem;
        margin: 30px 0 9px 0;
    };

    .VerticalLine{
        display: flex;
        justify-content: center;
    };

    .TitleName{
        font-size: 1.5rem;
        margin: 17px 0;
        display: flex;
        justify-content: center;
    };

    .TitleThumbnail{
        position: relative;
        width: 328px;
        height: 328px;

        .KeywordBox{
            position: absolute;
            right: 8px;
            bottom: 10px;
            display: flex;
            justify-content: flex-end;

            .Background{
                padding: 3px 8px;
                background-color: rgba(1, 1, 1, 0.6);
                border-radius: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 8px;

                .Keyword{
                    font-size: 0.75rem;
                    color: rgb(255,255,255);
                    line-height: 20px;
                }
            }
        };
    };

    .StatisticsOfTitle{
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        margin-bottom: 36px;
        font-size: 0.75rem;

        .StatisticsRow{
            display: flex;
            align-items: center;
        };

        .DevidingLine{
            border-bottom: 1px solid rgb(241,241,241);
            margin: 4px 0;
        };
    };
};

.WhiteBasicTemplate{
    
    @media (max-width: 700px) {
        background-color: white !important;
    };
};