$SLIDE_WIDTH: 300px;
$SIGNATURE_COLOR: rgb(0, 176, 240);

.AdsContainer{
    border: 0px;
    display: flex;
    flex-direction: center;
    justify-content: center;
    align-content: center;
    width: 100%;
    box-shadow: 0px 0px 0px 0px rgba(255,255,255,0);

    .AdsPaper{
        white-space: nowrap;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: $SLIDE_WIDTH;
        border: 1px solid rgb(196, 196, 196);
        box-sizing: border-box;
    
        .Header{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            min-height: 40px;
            background-color: rgb(245, 245, 245);
            border-bottom: 1px solid rgb(225, 225, 225);
            border-radius: 3px 3px 0 0;
            overflow-x: hidden;
        }
        
        .CompanyArea{
            display: flex;
            flex-direction: row;
            justify-content: stretch;
            align-items: center;
            padding: 3%;
            width: 100%;
            box-sizing: border-box;
            position: relative;

            .CompanyImg{
                height: 26px;
                border-radius: 50%;
            }

            .Company{
                padding-left: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-content: stretch;

                .Brand{
                    font-size: 11px;
                }
                .Sponsored{
                    font-size: 10px;
                }
            }

            .CollapseIcon{
                position: absolute;
                transform: rotate(90deg);
                right: 2%;
                font-size: 1rem;
            }

        }

        .SliderContainer{
            overflow: hidden;
            position: relative;
            width: 100%;
            
            .Arrow {
                position:absolute;
                color: rgba(0, 176, 240, 0.4);
                transform: translateY(-50%);
                top:50%;
                font-size: 4rem;
                z-index: 1;
                cursor: pointer;
                transition: 0.3s;
                
                &:hover{
                    color: rgba(0, 176, 240, 0.8);
                }
                &.Next { right:0px; }
                &.Prev { left: 0px; }
            }

            .Slider{
                display: inline-block;
                transition-duration: .5s;

                .SlideCut{
                    width: $SLIDE_WIDTH;
                    height: $SLIDE_WIDTH;
                    object-fit: contain;
                    object-position: center;
                }
            }
        }
        
        .AccessMore{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: calc(100% - 20px);
            box-sizing: border-box;
            border-bottom: 1px solid rgb(233, 234, 235);
            font-size: 12px;
            font-weight: bold;
            height: 39px;

            .MoreText{
                font-size: 12px;
                font-weight: bold;
                text-decoration: none;
                color: rgb(0,0,0);
            }
        }

        .IconsArea{
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
            padding: 3%;
            box-sizing: border-box;
            
            .Icon{
                color: rgb(125, 125, 125);
                height: 18px;
            }

            .Pagination{
                display: flex;
                justify-content: stretch;
                align-content: center;
                width: 50%;

                .PaginationButton{
                    display: inline-block;
                    padding: 3px;
                    margin: 0 2px;
                    background-color: rgb(204, 204, 204);
                    border: 0px;
                    border-radius: 10px;
                    cursor: pointer;

                    &.Selected{
                        background-color: $SIGNATURE_COLOR;
                    }
                }

                .PaginationButton:hover{
                    background-color: rgb(146, 149, 152);
                }

                .PaginationButton:focus{
                    background-color: $SIGNATURE_COLOR;
                }
            }
        }

        .TextArea{
            width: 100%;
            padding: 0px 3% 3% 3%;
            box-sizing: border-box;
            
            .ViewContent{
                text-align: left;
                white-space: normal;
                font-size: 12px;

                .ViewMore{
                    cursor: pointer;
                    color: rgb(170, 170, 170);
                }

                .Description{
                    white-space: pre-wrap;
                }
            }
        }
    }
}