
.PlatformSurgingTopicsArea{
    width: calc(100% - 100px);
    padding: 30px 50px;
    background-color: rgb(255,255,255);
    margin: 30px 0;
    border-radius: 10px;

    @media(max-width: 700px) {
        width: calc(100% - 32px);
        padding: 16px;
        margin: 10px 0;
    }
    
    .Title{
        font-size: 1.25rem;
        font-weight: bold;

        .SearchText{
            color: rgb(0,176,240);
        }
    }

    .SubTitle{
        margin: 5px 0 15px;
        color: rgb(163, 163, 163);
        font-size: 1rem;
    }

    .TopicSurgingList{
        display: grid;
        grid-template-columns: 1fr 1fr;

        @media (max-width: 700px) {
            grid-template-columns: 1fr;
        }

        .TopicBox{
            
            @media(max-width: 700px) {
                margin: 15px 0;
            }

            .TopicRow{
                display: flex;
                align-items: center;
                padding: 30px 32px;
    
                @media (max-width: 700px) {
                    padding: 10px 32px;
                }
    
                .RankNumber{
                    width: 30px;
                    font-weight: bold;
                }
    
                .TopicText{
                    font-size: 0.81rem;
                    padding: 8px 12px;
                    border-radius: 32px;
                    background-color: #f8f8f8;
                    cursor: pointer;
                    text-decoration: none;
                    color: inherit;
                }
    
                .DirectionIcon{
                    display: flex;
                    align-items: center;
                    padding: 0 4px;
                }
    
                .PercentText{
                    font-size: 0.81rem;
                    font-weight: bold;
                }
            }
        }
    }
}