
.Router {
    width: 100%;
    position: relative;
    
    @media (max-width: 700px) {
        min-height: calc(100vh - 60px - 47px);
    }

    @media (min-width: 701px) {
        min-height: calc(100vh - 80px - 47px);
    }
}