.AnalysisApplyHeader{
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;

    background-color: rgba(255, 255, 255, 1);

    .HeaderContentContainer{
        display: flex;
        justify-content: space-between;
        align-items: center;

        .WebtoonTodayLogo{
            cursor: pointer;
            height: 24px;
        }
    }
}